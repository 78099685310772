/**
 * **Filter**
 *
 * Export the filter params that we need to use to implement case insensitive search
 *
 */

/** ignore this comment */

import { FilterSpec } from 'ra-postgraphile';

export const filterCaseInsensitive = {
  // converts the input to the filter
  parse: (value: string): FilterSpec => ({
    operator: 'includesInsensitive',
    value
  }),
  // converts the filter back to the input
  format: (obj: FilterSpec): string => obj && obj.value
};
