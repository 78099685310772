/**
 * **Component**
 *
 * Component based on React Admin **List** component to show existing Rims.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Fragment } from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField
} from 'react-admin';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { ReferenceImageField } from '../shared/ReferenceImageField';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

const RimsFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="q" alwaysOn={true} />
  </Filter>
);

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const RimList = (props: any) => (
  <List
    {...props}
    filters={<RimsFilter />}
    exporter={false}
    perPage={10}
    bulkActionButtons={
      checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
        <ListBulkActionButtons {...props} />
      ) : (
        false
      )
    }>
    <Datagrid>
      <TextField source="name" />
      <TextField source="optionCode" />
      <ReferenceField source="thumbnailFileId" reference="files">
        <ReferenceImageField source="file" maxWidth="100px" />
      </ReferenceField>
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
        <EditButton label="Edit" />
      )}
    </Datagrid>
  </List>
);
