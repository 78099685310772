/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new Languages
 *
 * Please note that in the CMS this components are found under the menu point **Translations**
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

export const LanguageCreate = (props: any): JSX.Element => {
  return (
    <Create title="Create a language" {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="code" validate={required()} />
        <TextInput source="internationalCode" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
