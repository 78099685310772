/**
 * **Component**
 *
 * Utility component used to show images. Used together with the upload mechanism
 *
 */

/** ignore this comment */
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';

import { ReferenceImageFieldProps } from '../../models/reference-image-field.model';

export const ReferenceImageField = (props: ReferenceImageFieldProps): JSX.Element => {
  try {
    const file = JSON.parse(props.record!.file);
    const filepath = file.filepath;
    const width = props.maxWidth || '300px';
    return <img src={filepath} width={width} alt={props.source} />;
  } catch (error) {
    return <Alert>Error parsing: {JSON.stringify(props.record)}</Alert>;
  }
};
