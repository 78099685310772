/**
 * **Component**
 *
 * Utility component used for uploads. It transforms the save button actions adding the needed
 * parameters for the backend upload task.
 *
 */

/** ignore this comment */

import { makeStyles } from '@material-ui/core/styles';
import React, { FC, PropsWithChildren } from 'react';
import { DeleteButton, Record, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

type UploadToolbarProps = PropsWithChildren<ToolbarProps<Record>> & {
  fileparams: { key: string; value: string | undefined }[];
  width?: any;
};

export const UploadToolbar: FC<UploadToolbarProps> = (props: UploadToolbarProps): JSX.Element => {
  const fileParamsObj: any = {};
  props.fileparams!.forEach((file: { key: string; value: string | undefined }) => {
    if (file.value) {
      fileParamsObj[file.key] = file.value;
    }
  });
  const transform = (values: any) => ({ ...values, ...fileParamsObj });

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton transform={transform} />
      {props.record && typeof props.record.id !== 'undefined' && <DeleteButton />}
    </Toolbar>
  );
};
