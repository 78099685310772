/**
 * **Component**
 *
 * This component is used to override some of the data from the derivative for this particular event.
 */

/** ignore this comment */
import { Box, Toolbar } from '@material-ui/core';
import ContentSave from '@material-ui/icons/Save';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import {
  Button,
  CardContentInner,
  ImageField,
  ImageInput,
  Loading,
  ReferenceField,
  TextInput
} from 'react-admin';
import { Form } from 'react-final-form';

import { useFileUpload } from '../../../hooks/useFileUpload';
import { IDerInfoEvent } from '../../../models/derivatives.model';
import { DerInfoEventEditProps } from '../../../models/event.model';
import { ReferenceImageField } from '../../shared/ReferenceImageField';

export const DerInfoEventEdit: FC<DerInfoEventEditProps> = (props: DerInfoEventEditProps) => {
  const { record, unitSystem } = props;
  const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();
  const isMetric = unitSystem === 'METRIC';
  const onSubmit = (record: IDerInfoEvent) => {
    const empty = {
      id: null,
      co2: null,
      derivateiveId: null,
      electric: null,
      eventId: null,
      fuel: null,
      thumbnailFileId: null,
      funFactFileId: null,
      wltpCo2: null,
      wltpElectric: null,
      wltpFuel: null,
      wltpDistance: null
    };
    const newRecord = { ...empty, ...record };

    if (thumbnailFileId) {
      record.thumbnailFileId = thumbnailFileId;
    }
    props.onSubmit(newRecord);
  };

  const defaultIcon = <ContentSave />;
  if (!record) {
    return <Loading />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={record}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <h6>NEFZ</h6>
          <CardContentInner>
            <TextInput
              source="co2"
              label={isMetric ? 'CO2 (g/km)' : 'CO2 (miles/ounce)'}
              fullWidth
            />
          </CardContentInner>
          <CardContentInner>
            <TextInput
              source="electric"
              label={isMetric ? 'Electric (kWh/100km)' : 'Electric (kWh/miles)'}
              fullWidth
            />
          </CardContentInner>
          <CardContentInner>
            <TextInput
              source="fuel"
              label={isMetric ? 'Fuel (l/100km)' : 'Fuel (miles/gallon)'}
              fullWidth
            />
          </CardContentInner>
          <h6>WLTP</h6>
          <CardContentInner>
            <TextInput
              source="wltpCo2"
              label={isMetric ? 'CO2 (g/km)' : 'CO2 (miles/ounce)'}
              fullWidth
            />
          </CardContentInner>
          <CardContentInner>
            <TextInput
              source="wltpElectric"
              label={isMetric ? 'Electric (kWh/100km)' : 'Electric (kWh/miles)'}
              fullWidth
            />
          </CardContentInner>
          <CardContentInner>
            <TextInput
              source="wltpFuel"
              label={isMetric ? 'Fuel (l/100km)' : 'Fuel (miles/gallon)'}
              fullWidth
            />
          </CardContentInner>
          <CardContentInner>
            <TextInput
              source="wltpDistance"
              label={isMetric ? 'Distance (km)' : 'Distance (miles)'}
              fullWidth
            />
          </CardContentInner>
          {record.thumbnailFileId && (
            <ReferenceField
              record={record}
              basePath="/derInfoEvents"
              source="thumbnailFileId"
              reference="files"
              link={false}>
              <ReferenceImageField source="file" maxWidth="250px" />
            </ReferenceField>
          )}

          {uploadError && <Alert severity="error">{uploadError}</Alert>}
          <ImageInput
            onChange={handleFileUpload}
            source="editModel"
            label="Thumbnail File Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button
                disabled={submitting || pristine}
                type="submit"
                label="save"
                variant="contained"
                color={submitting ? 'default' : 'primary'}>
                {defaultIcon}
              </Button>
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};
