/**
 * **Service**
 *
 * Keeps the state or wheather we are managing an event, a market or other.
 * This is needed since, in the backround both events and markets refer to the
 * same GraphQL resource and only differ on a boolan variable `isMarket`
 *
 */

/** ignore this comment */

import { BehaviorSubject, Observable } from 'rxjs';

export type Area = 'market' | 'event' | 'other';

const subject = new BehaviorSubject<Area>('other');

function changeArea(area: Area): void {
  subject.next(area);
}

function areaChanged(): Observable<Area> {
  return subject.asObservable();
}

const resetValue = 0;

export const areaService = {
  changeArea,
  areaChanged,
  resetValue
};
