/**
 * **Component**
 *
 * This component is the entry point for editors or admins to manage the attributes of a
 * particular market or event.
 *
 */

/** ignore this comment */
import { gql, useMutation } from '@apollo/client';
import { Tab, Tabs, Typography, useEventCallback } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  EditProps,
  Error,
  GetListParams,
  GetListResult,
  GetOneResult,
  Loading,
  sanitizeEmptyValues,
  Title,
  useDataProvider,
  useNotify
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { IEvent } from '../../models/event.model';
import { ILanguage } from '../../models/languages.model';
import { TabPanel } from '../shared/BaseTabPanel';
import { EventCarsForm } from './editor_events/EventCarsForm';
import { ImageRecognitionList } from './editor_events/image_recognition/List';
import { MetadataForm } from './editor_events/MetadataForm';
import * as QRCode from 'qrcode.react';
import { SVGIcon } from '../shared/SVGIcon';
import { checkRolePermission } from '../../utils/permissionHelper';
import authProvider from '../../AuthProvider';
import { UserRole, UserPermissions, EventUser } from '../../models/user-permissions.model';
import { areaService } from '../../areaService';

const LinkIcon = <SVGIcon name="link" width={16} />;

const eventLangsParams: GetListParams = {
  pagination: { page: 1, perPage: 200 },
  sort: { field: 'code', order: 'ASC' },
  filter: {}
};

const eventUserParams: GetListParams = {
  pagination: { page: 1, perPage: 1 },
  sort: { field: 'id', order: 'ASC' },
  filter: {}
};

const useStyles = makeStyles({
  eventToolbar: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    margin: '20px'
  },
  testButton: {
    margin: '0 20px',
    maxHeight: '2em'
  },
  responseLink: {
    padding: '0 10px'
  },
  statusMessage: {
    padding: '3px 50px 0 0'
  }
});

const PUBLISH_EVENT = gql`
  mutation Publish($eventId: String!, $status: String!) {
    publishEvent(input: { eventId: $eventId, initStatus: $status }) {
      error
      message
      success
      entryId
    }
  }
`;

const baseAppUrl = process.env.REACT_APP_APP_HOST;
const qrCodeBase = 'https://www.porsche.com/parv-configurator?eventSetup=';

const EditorEdit = (props: EditProps): JSX.Element => {
  const dataProvider = useDataProvider();
  const recordId = (props as any).location?.pathname.split('/').pop();
  let isCancelled = false;
  const [value, setValue] = useState(0);
  const [eventLanguages, setEventLanguages] = useState<ILanguage[]>([]);
  const [eventData, setEventData] = useState<IEvent | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [newEventId, setNewEventId] = useState<string | null>();
  const [wasPublished, setWasPublished] = useState<boolean>(false);
  const [showPublish, setShowPublish] = useState<boolean>(false);
  const [isMarket, setIsMarket] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const [permissions, setPermissions] = useState<UserPermissions | any>(null);
  const classes = useStyles();
  const notify = useNotify();

  const [publish] = useMutation(PUBLISH_EVENT, {
    async onCompleted({ publishEvent: { error, message, success, entryId } }) {
      if (error) {
        notify(error, 'error');
      } else {
        notify(message);
        setNewEventId(entryId);
        const event = await dataProvider.getOne('events', {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: recordId!
        });
        console.log('!!!event', event);
        const ev = _serializeEvent(event.data, event.data.metadata);
        setEventData(ev);
      }
    },
    onError(error) {
      console.error('error publishing', error);
      notify(error.message, 'error');
    }
  });
  const onTestClicked = (status: 'pending' | 'published') => {
    publish({ variables: { eventId: recordId, status } });
  };

  const onSubmitMetadata = (values: any): void => {
    // React-final-form removes empty values from the form state.
    // To allow users to *delete* values, this must be taken into account
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sanitizedValues = sanitizeEmptyValues(eventData!, values);
    const data = { ...sanitizedValues, metadata: JSON.stringify(sanitizedValues.metadata) };

    dataProvider
      .update('event', {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: recordId!,
        data,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        previousData: eventData!
      })
      .then(({ data }) => {
        const event = _serializeEvent(data, data.metadata);
        setEventData(event);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const loadIsPublished = async () => {
    const resourceURL = `${baseAppUrl}/resource-published/${recordId}`;
    try {
      const response = await fetch(resourceURL);
      const data = await response.json();
      setWasPublished(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadIsPublished();
  }, [eventData]);

  useEffect(() => {
    authProvider.getPermissions().then((permissions) => {
      setPermissions(permissions);
      if (checkRolePermission(permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN])) {
        setShowPublish(true);
      }
      if (checkRolePermission(permissions, [UserRole.SUPER_ADMIN])) {
        setIsSuperAdmin(true);
      }
    });
  }, []);

  useEffect(() => {
    const eventPromise: Promise<GetOneResult<IEvent>> = dataProvider.getOne('events', {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: recordId!
    });
    eventLangsParams.filter = { eventId: recordId };
    const eventLangsPromise: Promise<GetListResult<ILanguage>> = dataProvider.getList(
      'eventLanguage',
      eventLangsParams
    );
    const userId = authProvider.getCurrentUserId();
    eventUserParams.filter = { eventId: recordId, userId };
    const eventUserPromise: Promise<GetListResult<EventUser>> = dataProvider.getList(
      'eventUser',
      eventUserParams
    );

    (async function getEventAndLangs() {
      try {
        if (isCancelled) {
          return;
        }
        const [event, eventLangs, eventUser] = await Promise.all([
          eventPromise,
          eventLangsPromise,
          eventUserPromise
        ]);

        const ev = _serializeEvent(event.data, event.data.metadata);
        setEventData(ev);
        setEventLanguages(eventLangs.data);
        areaService.changeArea(ev.isMarket ? 'market' : 'event');
        setIsMarket(ev.isMarket);
        setLoading(false);
        if (eventUser.data.length > 0 && eventUser.data[0].resourceRole === 'PUBLISHER') {
          setShowPublish(true);
        }
      } catch (e) {
        if (isCancelled) {
          return;
        }
        setError(error);
        setLoading(false);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [props, recordId, dataProvider]);

  const handleChange = (event: ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const _serializeEvent = (respEvent: any, md: string): IEvent => {
    const metadata = JSON.parse(md);
    return { ...respEvent, metadata };
  };

  if (loading) return <Loading />;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (error) return <Error error={error!} />;
  let testQRCodeValue = '';
  if (newEventId) {
    testQRCodeValue = `${qrCodeBase}${baseAppUrl}/test/${newEventId}`;
  }

  let publishedQRCodeValue = '';
  if (wasPublished) {
    publishedQRCodeValue = `${qrCodeBase}${baseAppUrl}/initial-resource/${recordId}`;
  }
  return (
    <Card>
      <Title title="Editor Edit" />

      <CardContent>
        <div className={classes.eventToolbar}>
          <Typography className={classes.statusMessage}>
            {eventData?.isPublished ? 'Published' : 'Pending'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.testButton}
            onClick={() => onTestClicked('pending')}>
            TEST
          </Button>
          {newEventId ? (
            <div>
              <QRCode value={testQRCodeValue} size={128} />
              {isSuperAdmin && (
                <a href={`${baseAppUrl}/test/${newEventId}`} className={classes.responseLink}>
                  {LinkIcon}
                </a>
              )}
              <br></br>
              <Typography>Use this code for testing</Typography>
            </div>
          ) : (
            ''
          )}

          {showPublish && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.testButton}
                onClick={() => onTestClicked('published')}>
                PUBLISH
              </Button>
              {wasPublished && !isMarket ? (
                <div>
                  <QRCode value={publishedQRCodeValue} size={128} />
                  {isSuperAdmin && (
                    <a
                      href={`${baseAppUrl}/initial-resource/${recordId}`}
                      className={classes.responseLink}>
                      {LinkIcon}
                    </a>
                  )}
                  <Typography>Share this code for the event</Typography>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
        <Typography variant="subtitle1" gutterBottom>
          {eventData?.name}
        </Typography>

        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Metadata" />
          <Tab label="Cars" />
          <Tab label="Image Recognition" />
        </Tabs>

        <TabPanel value={value} index={0}>
          <MetadataForm record={eventData} onSubmit={onSubmitMetadata} languages={eventLanguages} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EventCarsForm record={eventData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ImageRecognitionList
            basePath={props.location?.pathname}
            eventId={recordId}></ImageRecognitionList>
        </TabPanel>
      </CardContent>
    </Card>
  );
};

export default EditorEdit;
