/**
 * **Component**
 *
 * Component based on React Admin **List** show existing Sub Models
 * and as an entry point for deleting and creating them.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Fragment } from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField
} from 'react-admin';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

const SubmodelFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="name" alwaysOn={true} />
    <ReferenceInput
      label="Model Line"
      source="modellineId"
      reference="modellines"
      sort={{ field: 'name', order: 'ASC' }}
      alwaysOn>
      <SelectInput emptyText="All" />
    </ReferenceInput>
  </Filter>
);

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const SubmodelList = (props: any) => (
  <List
    {...props}
    title="Sub Models"
    filters={<SubmodelFilter />}
    exporter={false}
    bulkActionButtons={
      checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
        <ListBulkActionButtons {...props} />
      ) : (
        false
      )
    }>
    <Datagrid>
      <ReferenceField reference="modellines" source="modellineId" label="Model Line">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Sub Model" />
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
        <EditButton label="Edit" />
      )}
    </Datagrid>
  </List>
);
