/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new Sub Models
 *
 */

/** ignore this comment */
import * as React from 'react';
import {
  Create,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';

import { fuelTypeChoices, FuelTypeModel } from '../../models/fuel-type.models';

export const SubmodelCreate = (props: any): JSX.Element => {
  return (
    <Create title="Create a submodel" {...props}>
      <SimpleForm>
        <ReferenceInput
          validate={required()}
          label="Modelline"
          source="modellineId"
          reference="modellines"
          sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <RadioButtonGroupInput
          source="fuelType"
          defaultValue={FuelTypeModel.Unknown}
          choices={fuelTypeChoices}
          style={{ textTransform: 'capitalize' }}
        />
        <TextInput source="submodelPorscheId" label="Submodel ID" validete={required()} />
      </SimpleForm>
    </Create>
  );
};
