/**
 * **Component**
 *
 * Component based on React Admin **List** component to manage  fun facts
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Fragment } from 'react';
import { BulkDeleteButton, Datagrid, EditButton, Filter, List, TextField } from 'react-admin';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

const FunFactFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="title" alwaysOn={true} />
  </Filter>
);

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const FunFactList = (props: any) => (
  <List
    {...props}
    title="Fun Facts"
    filters={<FunFactFilter />}
    exporter={false}
    bulkActionButtons={
      checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
        <ListBulkActionButtons {...props} />
      ) : (
        false
      )
    }>
    <Datagrid>
      <TextField source="funFactText" />
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
        <EditButton label="Edit" />
      )}
    </Datagrid>
  </List>
);
