/**
 * **Component**
 *
 * Component based on React Admin **List** component display existing phrase id's.
 *
 * Phrase ID's are used in the **Translations**. This area provides the possibility
 * of managing the keys as well as an example text. The actual content shown on the
 * app will be taken from the translation files.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Fragment } from 'react';
import { BulkDeleteButton, Datagrid, EditButton, Filter, List, TextField } from 'react-admin';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

const PhraseIdFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="name" alwaysOn={true} />
  </Filter>
);

export const PhraseList = (props: any) => (
  <List
    {...props}
    filters={<PhraseIdFilter />}
    title="Phrase IDs"
    exporter={false}
    bulkActionButtons={<ListBulkActionButtons {...props} />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="englishText" label="English Reference Text" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
