/**
 * **Component**
 *
 * Utility component used to filter some properties that React Admin autmatically passes down
 * but are not supported in the standard FormControlLabel component.
 *
 */

/** ignore this comment */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

export const SanitizedFormControlLabel = ({ basePath, ...props }: any): JSX.Element => {
  return <FormControlLabel {...props} />;
};
