/**
 * **Theme**
 *
 * Collection style overrides to conform with Porsche CI
 *
 */

/** ignore this comment */
import { createMuiTheme } from '@material-ui/core/styles';
import { color } from '@porsche-design-system/utilities';
import { font } from '@porsche-design-system/utilities';

export const CMSLightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: color.default
    },
    secondary: {
      main: color.brand
    },
    error: {
      main: color.notification.error
    },
    warning: {
      main: color.notification.warning
    },
    info: {
      main: color.notification.neutral
    },
    success: {
      main: color.notification.success
    },
    background: {
      default: color.background.default,
      paper: color.background.surface
    },
    text: {
      primary: color.default,
      secondary: color.default
    },
    action: {
      hover: color.state.hover,
      active: color.default, // if we put to state.active its red, and some icons are red then
      focus: color.state.focus,
      disabled: color.state.disabled
    }
  },
  typography: {
    fontFamily: font.family
  },
  overrides: {
    MuiTableRow: {
      hover: {
        color: '#000',
        '&:hover': {
          color: '#fff'
        }
      }
    },
    MuiTableCell: {
      body: {
        color: 'inherit',
        '&:hover': {
          color: 'inherit'
        }
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'inherit',
        '&:hover': {
          color: 'inherit'
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '36px',
        paddingRight: '36px'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        color: '#000',
        backgroundColor: 'transparent'
      }
    },
    MuiTabs: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        display: 'flex',
        overflow: 'hidden',
        minHeight: '48px',
        WebkitOverflowScrolling: 'touch',
        borderBottom: '1px solid #c9cacb'
      }
    },
    RaLink: {
      link: {
        color: 'inherit'
      }
    },
    RaReferenceField: {
      link: {
        color: 'inherit'
      }
    },
    RaMenuItemLink: {
      root: {
        color: '#000',
        '&:hover': {
          color: '#fff'
        }
      },
      active: {
        color: '#fff',
        backgroundColor: color.state.active
      }
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: color.brand
      }
    }
  }
} as any);

export const CMSDarkTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: color.darkTheme.default
    },
    secondary: {
      main: color.darkTheme.brand
    },
    error: {
      main: color.darkTheme.notification.error
    },
    warning: {
      main: color.darkTheme.notification.warning
    },
    info: {
      main: color.darkTheme.notification.neutral
    },
    success: {
      main: color.darkTheme.notification.success
    },
    background: {
      default: color.darkTheme.background.default,
      paper: color.darkTheme.background.surface
    },
    text: {
      primary: color.darkTheme.default,
      secondary: color.darkTheme.default
    },
    action: {
      hover: color.darkTheme.state.hover,
      active: color.darkTheme.default, // if we put to state.active its red, and some icons are red then
      focus: color.darkTheme.state.focus,
      disabled: color.darkTheme.state.disabled
    }
  },
  typography: {
    fontFamily: font.family
  }
});
