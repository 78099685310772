/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to edit existing colors
 * We are using the _react-admin-color-input_ package to handle the colors.
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { DateField, Edit, required, SimpleForm, TextInput, useNotify } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const ColorEdit = (props: any): JSX.Element => {
  const classes = useStyles();
  const notify = useNotify();

  const onFailure = (error: any) => {
    console.log('FAILURE');
    if (error?.message?.indexOf('unique_color_option_code')) {
      notify(`Option code of color have to be unique!`);
      return;
    }
    notify(error.message);
  };
  return (
    <Edit title="Edit Color" undoable={false} onFailure={onFailure} {...props}>
      <SimpleForm>
        <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
        <TextInput source="name" validate={required()} />
        <ColorInput source="hexadecimalValue" validate={required()} />
        <TextInput source="optionCode" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
