/**
 * **Component**
 *
 * Simple component to render the colors in a circle.
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

import { IColorCircleProps } from '../../models/color-circle.model';

const useStyles = makeStyles({
  colorCircle: {
    borderRadius: '50%',
    margin: '3px',
    borderStyle: 'solid',
    borderWidth: '1px'
  }
});

export function ColorCircle(props: IColorCircleProps): JSX.Element {
  const classes = useStyles();
  return (
    <div
      className={classes.colorCircle}
      style={{
        width: props.size + 'px',
        height: props.size + 'px',
        backgroundColor: props.colorString
      }}></div>
  );
}
