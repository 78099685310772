/**
 * **Component**
 *
 * This is the actual form in which the user enters their login information.
 * It's embeded within **components/auth/LoginPage**
 *
 */

/** ignore this comment */

import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { KeyboardArrowRight } from '@material-ui/icons';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useLogin, useNotify, useRedirect, useSafeSetState, useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';

interface Props {
  redirectTo?: string;
}

interface FormData {
  username: string;
  password: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em'
    },
    input: {
      marginTop: '1em'
    },
    button: {
      width: '100%',
      backgroundColor: '#D5001C',
      textTransform: 'none',
      borderRadius: '0px',
      fontSize: '20px',
      fontFamily: 'Porsche Next'
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    link: {
      margin: '10px'
    }
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginPage: FunctionComponent<Props> = (props) => {
  const { redirectTo } = props;
  const redirect = useRedirect();
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors: any = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const pwForgotRedirect = async () => {
    return redirect('/requestpwreset');
  };

  const submit = (values: any) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label="Email"
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}>
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              <KeyboardArrowRight />
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <Link
            component="button"
            variant="body2"
            onClick={pwForgotRedirect}
            className={classes.link}>
            Forgot password?
          </Link>
        </form>
      )}
    />
  );
};

LoginPage.propTypes = {
  redirectTo: PropTypes.string
};

export default LoginPage;
