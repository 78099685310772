/**
 * **Top Page Component**
 *
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the authProvider.login() method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * example:
 *```typescript
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 *```
 */

/** ignore this comment */
import { Card } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { TitleComponent, useCheckAuth } from 'ra-core';
import React, { HtmlHTMLAttributes, ReactNode, useEffect, useMemo, useRef } from 'react';
import { defaultTheme, Notification } from 'react-admin';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';

import logoPorsche from '../../logo-porsche.jpg';
import DefaultLoginForm from './LoginForm';

export interface LoginProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  backgroundImage?: string;
  children?: ReactNode;
  classes?: any;
  className?: string;
  staticContext?: StaticContext;
  theme?: any;
  title?: TitleComponent;
}

const useStyles = makeStyles(
  (theme: any) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: 'white'
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
      backgroundColor: 'white'
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    },
    welcomeMessage: {
      fontSize: '26px',
      fontWeight: 'bold',
      margin: '15px',
      textAlign: 'center',
      fontFamily: 'Porsche Next'
    }
  }),
  { name: 'RaLogin' }
);

const Login: React.FunctionComponent<LoginProps> = (props) => {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    staticContext,
    backgroundImage,
    ...rest
  } = props;
  const containerRef: any = useRef<HTMLDivElement>();
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const history = useHistory();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img
              src="./porsche-marque-trademark.medium.min.af8bb58f56a36a40768c43e1b6040d1f@1x.png"
              alt="Porsche"
              width="100px"
            />
          </div>
          <p className={classes.welcomeMessage}>Welcome to Porsche AR Visualizer CMS</p>
          {children}
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object
};

Login.defaultProps = {
  theme: defaultTheme,
  children: <DefaultLoginForm />
};

export default Login;
