/**
 * **Top Page Component**
 *
 * In this page the user sets a new password. This is changed using a direct mutation on the backend
 *
 */

/** ignore this comment */
import { gql, useMutation } from '@apollo/client';
import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import {
  Notification,
  PasswordInput,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { Form } from 'react-final-form';

const RESET_PW = gql`
  mutation resetPw($token: String!, $pw: String!) {
    resetPassword(input: { token: $token, pw: $pw }) {
      userAccount {
        email
      }
    }
  }
`;

const useStyles = makeStyles(
  (theme: any) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: 'white'
    },
    card: {
      minWidth: 300,
      marginTop: '6em'
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    },
    form: {
      padding: '0 1em 1em 1em'
    },
    input: {
      marginTop: '1em',
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      width: '100%',
      backgroundColor: '#D5001C',
      textTransform: 'none',
      borderRadius: '0px',
      fontSize: '20px',
      fontFamily: 'Porsche Next'
    }
  }),
  { name: 'RaLogin' }
);

const WrappedSimpleForm = ({ save, ...rest }: any) => {
  const [resetPw] = useMutation(RESET_PW);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles(rest);

  const mySave = async (formdata: any) => {
    try {
      if (formdata.password !== formdata.passwordConfirm) {
        throw Error('The 2 passwords does not match!');
      }
      const { data } = await resetPw({
        variables: { token: rest?.match?.params?.resetToken, pw: formdata.password }
      });
      redirect('/login');
      refresh();
      notify(`reseted password for user "${data?.resetPassword?.userAccount?.email}"!`);
    } catch (e) {
      notify(e.message, 'warning');
    }
  };
  return (
    <Form
      onSubmit={mySave}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <PasswordInput source="password" validate={required()} />
              <PasswordInput source="passwordConfirm" validate={required()} />
            </div>
            <CardActions>
              <Button variant="contained" type="submit" color="primary" className={classes.button}>
                <KeyboardArrowRight />
                Save
              </Button>
            </CardActions>
          </div>
        </form>
      )}
    />
  );
};

export const PwReset = (props: any) => {
  const { theme, className, ...rest } = props;
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(classes.main, className)} {...rest}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img
              src="./porsche-marque-trademark.medium.min.af8bb58f56a36a40768c43e1b6040d1f@1x.png"
              alt="Porsche"
              width="100px"
            />
          </div>
          <WrappedSimpleForm {...props}></WrappedSimpleForm>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};
