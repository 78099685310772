/**
 * **Component**
 *
 * Component based on React Admin **List** component to display a list of all
 * images used for image recognition.
 *
 * Please note that we are using the same component to manage events and markets. Both of this
 * correspond to the same entity in the database with the only distinction that the `is_market`
 * property is set to true for markets. The state management of what is being edited is
 * handled by the **areaService**.
 *
 * This component also offers the possibility of duplicating existing events/markets.
 *
 */

/** ignore this comment */
import Edit from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import {
  BulkDeleteButton,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  useNotify,
  useRedirect
} from 'react-admin';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';
import { areaService, Area } from '../../areaService';
import { gql, useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';

const EVENT_DUPLICATE = gql`
  mutation eventDuplicate($originalEventId: UUID!) {
    eventDuplicate(input: { originalEventId: $originalEventId }) {
      uuid
    }
  }
`;

const ListActions = (props: any) => {
  const { ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
        <CreateButton basePath={basePath} />
      ) : null}
    </TopToolbar>
  );
};

const EventFilter = (props: any) => {
  const { setFilters } = useListContext();
  if (props.resetValue > areaService.resetValue) {
    setFilters({}, []);
    areaService.resetValue = props.resetValue;
  }
  return (
    <Filter {...props}>
      <SearchInputCaseInsensitive source="name" alwaysOn={true} />
    </Filter>
  );
};

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const EventList = (props: any) => {
  const [area, setArea] = useState<Area>('other');
  const [title, setTitle] = useState<string>('');
  const [resetValue, setResetValue] = useState<number>(areaService.resetValue);

  useEffect(() => {
    const subscription = areaService.areaChanged().subscribe((_area) => {
      window.location.replace('/#/events?mode=' + _area);
      setResetValue(resetValue + 1);
      setArea(_area);

      if (_area === 'market') {
        setTitle('Markets');
      } else {
        setTitle('Events');
      }
    });
    setResetValue(areaService.resetValue);
    return () => {
      subscription.unsubscribe();
    };
  }, [areaService.resetValue]);

  const filter = {
    isMarket: area === 'market' ? true : false
  };
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [eventDuplicate] = useMutation(EVENT_DUPLICATE, {
    onCompleted: ({ eventDuplicate: { uuid } }) => {
      notify('Event successfully duplicated');
      redirectTo('edit', props.basePath, uuid);
    },
    onError: (e) => {
      notify(e.message, 'error');
    }
  });

  const onDuplicateEvent = (originalEventId: string): void => {
    if (originalEventId) {
      eventDuplicate({ variables: { originalEventId } });
    }
  };

  return (
    <List
      {...props}
      title={title}
      filters={<EventFilter resetValue={resetValue} />}
      filter={filter}
      filterDefaultValues={filter}
      exporter={false}
      actions={<ListActions area={area} {...props} />}
      bulkActionButtons={
        checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
          <ListBulkActionButtons {...props} />
        ) : (
          false
        )
      }>
      <Datagrid>
        <TextField source="name" label={area === 'market' ? 'Market name' : 'Event name'} />
        <DateField locales="de-DE" source="updatedDate" />
        <FunctionField
          label="Status"
          source="isPublished"
          sortable
          render={(record: any) => {
            if (record.isPublished) {
              return <Typography>Published</Typography>;
            } else {
              return <Typography>Pending</Typography>;
            }
          }}
        />
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) && (
          <EditButton label="Edit Basic Information" />
        )}

        <EditButton icon={<Edit />} basePath="/editor-event" label={`Edit ${title} Content`} />
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) && (
          <FunctionField
            label="Duplicate"
            render={(record: any) => (
              <Button
                color="primary"
                onClick={() => onDuplicateEvent(record.id)}
                label="Duplicate"
              />
            )}
          />
        )}
      </Datagrid>
    </List>
  );
};
