/**
 * **Component**
 *
 * This is the parent component in charge of hosting all functionality needed to override
 * the particular derivative configuration for this event.
 */

/** ignore this comment */
import { AppBar, Checkbox, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import {
  EditProps,
  Error,
  GetListParams,
  Loading,
  Title,
  useDataProvider,
  useNotify
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { areaService } from '../../../areaService';

import {
  IDerColor,
  IDerColorEvent,
  IDerFunFactEvent,
  IDerInfoEvent,
  IDerivative,
  IDerRim,
  IDerRimEvent,
  IEditEventDerServiceParams,
  IFunFact,
  IModelline,
  ISubmodel
} from '../../../models/derivatives.model';
import { IEvent } from '../../../models/event.model';
import { ColorCircle } from '../../colors/ColorCircle';
import { TabPanel } from '../../shared/BaseTabPanel';
import { SanitizedFormControlLabel } from '../../shared/SanitizedFormControlLabel';
import { DerFunFactsEventEdit } from './EditEventDerivativeFunFacts';
import { DerInfoEventEdit } from './EditEventDerivativeInfo';
import * as EditService from './EditEventDerivativeService';

const useStyles = makeStyles({
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexStart'
  }
});

export const EditorEventDerivative: FC<EditProps> = (props: EditProps): ReactElement => {
  const params = new URLSearchParams(props.location!.search);
  if (!params.has('derivative_id') || !params.has('event_id')) {
    return <Error error="missing url parameters" />;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const derivativeId = params.get('derivative_id')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const eventId = params.get('event_id')!;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [value, setValue] = useState(0);
  const [derivativeData, setDerivativeData] = useState<IDerivative | null>(null);
  const [eventData, setEventData] = useState<IEvent | null>();
  const [derColorChoices, setDerColorChoices] = useState<IDerColor[]>([]);
  const [derColorEvents, setDerColorEvents] = useState<IDerColorEvent[]>([]);

  const [derRimChoices, setDerRimChoices] = useState<IDerRim[]>([]);
  const [derRimEvents, setDerRimEvents] = useState<IDerRimEvent[]>([]);
  const [derInfoEvent, setDerInfoEvent] = useState<IDerInfoEvent | null>(null);

  const [funFactChoices, setFunFactChoices] = useState<IFunFact[]>([]);
  const [derFunFactEvents, setDerFunFactEvents] = useState<IDerFunFactEvent[]>([]);
  const [modelline, setModelline] = useState<IModelline | null>(null);
  const [submodel, setSubmodel] = useState<ISubmodel | null>(null);

  const dataProvider = useDataProvider();
  //TODO show error if there are no correct parameters
  const notify = useNotify();

  const serviceParams: IEditEventDerServiceParams = {
    derivativeId,
    eventId,
    dataProvider,
    derColorEvents,
    setDerColorEvents,
    derRimEvents,
    setDerRimEvents,
    setDerInfoEvent,
    derFunFactEvents,
    setDerFunFactEvents,
    notify
  };

  EditService.init(serviceParams);

  const history = useHistory();

  const classes = useStyles();
  let isCancelled = false;

  useEffect(() => {
    dataProvider
      .getOne('derivatives', { id: derivativeId! })
      .then(({ data }) => {
        const derivativeD = data as IDerivative;

        setDerivativeData(derivativeD);

        const derColorParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'derivativeId', order: 'ASC' },
          filter: { derivativeId: derivativeId }
        };

        const derColorEventParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { derivativeId: derivativeId, eventId: eventId }
        };

        const derRimParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { derivativeId: derivativeId }
        };

        const derRimEventParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { derivativeId: derivativeId, eventId: eventId }
        };

        const funFactsParams: GetListParams = {
          pagination: { page: 1, perPage: 300 },
          sort: { field: 'id', order: 'ASC' },
          filter: {}
        };

        const derFunFactEventParams: GetListParams = {
          pagination: { page: 1, perPage: 300 },
          sort: { field: 'id', order: 'ASC' },
          filter: { derivativeId: derivativeId, eventId: eventId }
        };

        //NOTE: we call a list but we are actually expecting just one result
        const derInfoEventParams: GetListParams = {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: { derivativeId: derivativeId, eventId: eventId }
        };
        const eventP = dataProvider.getOne('events', { id: eventId });
        const colorP = dataProvider.getList('derColors', derColorParams);
        const colorDP = dataProvider.getList('derColorEvents', derColorEventParams);
        const rimsP = dataProvider.getList('derRims', derRimParams);
        const rimsDP = dataProvider.getList('derRimEvents', derRimEventParams);
        const derInfoP = dataProvider.getList('derInfoEvents', derInfoEventParams);
        const funFactP = dataProvider.getList('funFact', funFactsParams);
        const funFactDP = dataProvider.getList('derFunFactEvents', derFunFactEventParams);
        const subModelP = dataProvider.getOne('submodels', { id: derivativeD.submodelId });
        const modellineP = dataProvider.getOne('modelline', { id: derivativeD.modellineId });

        Promise.all([
          eventP,
          colorP,
          colorDP,
          rimsP,
          rimsDP,
          derInfoP,
          funFactP,
          funFactDP,
          subModelP,
          modellineP
        ])
          .then(
            ([
              _event,
              _derColorChoices,
              _derEventColors,
              _derRimChoices,
              _derEventRims,
              _derInfo,
              _funFacts,
              _derFunFactEvents,
              _subModel,
              _modelline
            ]) => {
              if (isCancelled) {
                return;
              }
              const event = _event.data as IEvent;
              areaService.changeArea(event.isMarket ? 'market' : 'event');
              setEventData(event);
              setDerColorChoices(_derColorChoices.data as IDerColor[]);
              setDerColorEvents(_derEventColors.data as IDerColorEvent[]);
              setDerRimChoices(_derRimChoices.data as IDerRim[]);
              setDerRimEvents(_derEventRims.data as IDerRimEvent[]);

              setDerInfoEvent(_derInfo.data[0] as IDerInfoEvent);
              setFunFactChoices(_funFacts.data as IFunFact[]);
              setDerFunFactEvents(_derFunFactEvents.data as IDerFunFactEvent[]);
              setSubmodel(_subModel.data as ISubmodel);
              setModelline(_modelline.data as IModelline);
              setLoading(false);
            }
          )
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    return () => {
      isCancelled = true;
    };
  }, [derivativeId, eventId]);

  const handleChange = (event: ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const handleOnSubmit = (data: any) => {
    EditService.handleUpdateDerInfoEvent(data, derInfoEvent);
  };

  const handleOnFunFactChanged = (funFact: IFunFact, checked: boolean) => {
    EditService.handleUpdateDerFunFactEvent(funFact, checked);
  };

  const handleOnGoingBack = () => {
    //console.log('ong going back');
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error!} />;

  return (
    <Card>
      <Title title="Editor Edit" />
      <CardContent>
        <div className={classes.header}>
          <IconButton onClick={handleOnGoingBack}>
            <ArrowBackIcon />
          </IconButton>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {eventData?.name}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {`${modelline?.name} ${submodel?.name}  ${derivativeData?.name}`}
            </Typography>
          </div>
        </div>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Car Specs" key="0" />
            <Tab label="External Colors" key="1" />
            <Tab label="Rims" key="2" />
            <Tab label="Fun Facts" key="3" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <DerInfoEventEdit
            record={derInfoEvent}
            unitSystem={eventData?.unitSystem ?? 'METRIC'}
            onSubmit={handleOnSubmit}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.verticalContainer}>
            {derColorChoices.map((choice) => (
              <SanitizedFormControlLabel
                control={
                  <>
                    <ColorCircle colorString={choice.colorHexValue} size={30} />
                    <Checkbox
                      checked={
                        !derColorEvents
                          ?.map((mc: IDerColorEvent) => mc.derColorId)
                          .includes(choice.id) ?? true
                      }
                      onChange={(e) => EditService.handleColorChange(choice, e.target.checked)}
                      color="primary"
                    />
                  </>
                }
                key={choice.id}
                label={choice.colorName}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classes.verticalContainer}>
            {derRimChoices.map((choice) => (
              <SanitizedFormControlLabel
                control={
                  <Checkbox
                    checked={
                      !derRimEvents?.map((mc: IDerRimEvent) => mc.derRimId).includes(choice.id) ??
                      true
                    }
                    onChange={(e) => EditService.handleRimChange(choice, e.target.checked)}
                    color="primary"
                  />
                }
                key={choice.id}
                label={`${choice.rimName} : ${choice.rimOptionCode}`}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DerFunFactsEventEdit
            funFactChoices={funFactChoices}
            derFunFactEvent={derFunFactEvents}
            onFunFactChanged={handleOnFunFactChanged}
            record={derInfoEvent}
            onSubmit={handleOnSubmit}
          />
        </TabPanel>
      </CardContent>
    </Card>
  );
};
