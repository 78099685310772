/**
 * **Hook**
 *
 * React hook used to abstract the retrieval of languages
 *
 */

/** ignore this comment */
import { useEffect, useState } from 'react';
import { GetListParams, useDataProvider } from 'react-admin';

import { ILanguage, IUseLanguage } from '../models/languages.model';

const langsParams: GetListParams = {
  pagination: { page: 1, perPage: 200 },
  sort: { field: 'name', order: 'ASC' },
  filter: {}
};

export const useLanguages = (): IUseLanguage => {
  const dataProvider = useDataProvider();
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [error, setError] = useState<string | any>(null);

  useEffect(() => {
    (async function fetchLangs() {
      try {
        const { data }: { data: ILanguage[] } = await dataProvider.getList<ILanguage>(
          'languages',
          langsParams
        );
        setLanguages(data);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, []);

  return { languages, langError: error };
};
