/**
 * **Component**
 *
 * Component based on React Admin **Create** component to manage an existing users
 *
 * In case of translators here is also where the languages are assigned.
 *
 * The assignments for editors and publishers to events in handled in the **ManageEditorPublisher** component
 */

import { gql, useMutation } from '@apollo/client';
import TextFieldMatUi from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as R from 'ramda';
import { IUser } from '../../models/user.model';
import {
  choices,
  DateField,
  DeleteButton,
  Edit,
  Error,
  email,
  GetOneResult,
  GetListParams,
  ReferenceField,
  Record,
  required,
  Loading,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  TextField,
  Toolbar,
  useNotify,
  useDataProvider,
  useRedirect,
  useRefresh,
  usePermissions
} from 'react-admin';
import { convertStrToUserRole } from '../../utils/permissionHelper';

const EDIT_USER = gql`
  mutation editUser(
    $id: UUID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $description: String!
    $role: UserRole!
  ) {
    editUserAccount(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        description: $description
      }
    ) {
      userAccount {
        id
      }
    }
  }
`;

const CustomDeleteButton = (props: any) => {
  return (
    <DeleteButton
      undoable={false}
      confirmTitle={'Delete User'}
      confirmContent={'Are you sure you want to delete this user?'}
      {...props}
    />
  );
};

const CustomToolbar = (props: any) => {
  const { basePath, data, resource }: any = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <CustomDeleteButton basePath={basePath} data={data} resource={resource} />
    </Toolbar>
  );
};

const WrappedSimpleForm = ({ save, ...rest }: any) => {
  const [editUser] = useMutation(EDIT_USER);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const mySave = async (data: any) => {
    try {
      await editUser({ variables: data });
      notify(`Changed User "${data.email}"!`);
      redirect('/userAccount');
      refresh();
    } catch (e) {
      notify(e.message, 'warning');
    }
  };
  return <SimpleForm save={mySave} {...rest} />;
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export const UserAccountEdit = (props: any) => {
  const recordId = (props as any).location?.pathname.split('/').pop();

  const classes = useStyles();
  const [translatorSelected, setTranslatorSelected] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<any>();
  const [languages, setLanguages] = useState<any>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
  const [languagesValue, setLanguagesValue] = useState<any>([]);

  const { permissions } = usePermissions();

  const handleRoleChange = (event: any) => {
    if (event.target.value === 'PAG_TRANSLATOR') {
      setTranslatorSelected(true);
    } else {
      setTranslatorSelected(false);
    }
  };

  const comp = (x: Record, y: Record) => x.id === y.id;

  const notify = useNotify();
  const onLangSelect = (options: any, state: any, reason: string): void => {
    const diff = R.symmetricDifferenceWith(comp, state, languagesValue);

    const currentSelectedLanguages = state;

    if (reason === 'select-option') {
      dataProvider.create(
        'languageTranslators',
        {
          data: {
            userId: props.id,
            languageId: diff[0].id
          }
        },
        {
          onSuccess: ({ data }: { data: any }) => {
            setLanguagesValue((selLanguages: any) => {
              const d = selLanguages.slice();
              d.push(diff[0]);
              return d;
            });
            setSelectedLanguages(() => {
              const d = selectedLanguages.slice();
              d.push(data);
              return d;
            });
            notify('Language added');
          },
          onFailure: (error: any) => {
            console.error('error', error);
            notify('error adding the language', error);
          }
        }
      );
    } else {
      const toDelete = R.find(R.propEq('languageId', diff[0].id))(selectedLanguages) as any;
      if (currentSelectedLanguages.length < 1 || !toDelete) {
        notify('You need to have at least one language defined!');
        return;
      }

      dataProvider.delete(
        'languageTranslators',
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: toDelete!.id,
          previousData: toDelete
        },
        {
          onSuccess: ({ data }: { data: any }) => {
            setLanguagesValue((selLanguages: any) => {
              const indexToDelete = R.findIndex(R.propEq('id', diff[0].id))(selLanguages);
              return R.remove(indexToDelete, 1, selLanguages);
            });
            setSelectedLanguages((selLanguages: any) => {
              const indexToDelete = R.findIndex(R.propEq('id', toDelete.id))(selectedLanguages);
              return R.remove(indexToDelete, 1, selLanguages);
            });
          },
          onFailure: (error: any) => {
            console.error('error', error);
            notify('error removing the language', error);
          }
        }
      );
    }
  };

  const dataProvider = useDataProvider();
  useEffect(() => {
    (async () => {
      const selLangParams: GetListParams = {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: { userId: props.id }
      };

      const langParams: GetListParams = {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      };

      const selLangP = dataProvider.getList('languageTranslators', selLangParams);
      const langP = dataProvider.getList('languages', langParams);
      const userP: Promise<GetOneResult<IUser>> = dataProvider.getOne('userAccounts', {
        id: recordId!
      });

      try {
        const [selectedLangsIds, langs, user] = await Promise.all([selLangP, langP, userP]);
        if (user?.data?.role === 'PAG_TRANSLATOR') {
          setTranslatorSelected(true);
        }
        setSelectedLanguages(selectedLangsIds.data);
        const selLangIds = selectedLangsIds.data.map((lang: any) => lang.languageId) as string[];
        const loadedLanguages = langs.data;
        setLanguages(loadedLanguages);
        const selectedLangs = loadedLanguages.filter((lang) =>
          selLangIds.includes(lang.id as string)
        );
        setLanguagesValue(selectedLangs);
        setLoaded(true);
      } catch (error) {
        setLoadError(error);
      }
    })();
  }, []);
  const validateEmail = [required(), email()];
  const validateRole = choices([
    'PAG_SUPERADMIN',
    'PAG_ADMIN',
    'PAG_EDITOR_PUBLISHER',
    'PAG_TRANSLATOR'
  ]);

  if (loadError) {
    return <Error error={loadError} />;
  }
  if (!loaded) {
    return <Loading />;
  }
  return (
    <Edit title="Edit User" undoable={false} {...props}>
      <WrappedSimpleForm toolbar={<CustomToolbar />}>
        <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="lastActive" formClassName={classes.inlineBlock} />
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="description" multiline validate={required()} />
        {permissions?.role.toLowerCase() === 'pag_superadmin' ? (
          <SelectInput
            label="Role"
            source="role"
            choices={[
              { id: 'PAG_SUPERADMIN', name: 'Superadmin' },
              { id: 'PAG_ADMIN', name: 'Admin' },
              { id: 'PAG_EDITOR_PUBLISHER', name: 'Editor_Publisher' },
              { id: 'PAG_TRANSLATOR', name: 'Translator' }
            ]}
            validate={validateRole}
            onChange={handleRoleChange}
          />
        ) : (
          <SelectInput
            label="Role"
            source="role"
            choices={[
              { id: 'PAG_ADMIN', name: 'Admin' },
              { id: 'PAG_EDITOR_PUBLISHER', name: 'Editor_Publisher' },
              { id: 'PAG_TRANSLATOR', name: 'Translator' }
            ]}
            validate={validateRole}
            onChange={handleRoleChange}
          />
        )}
        <ReferenceField source="invitedBy" reference="userAccount" label="Invited By">
          <TextField label="Invited By" source="email" />
        </ReferenceField>
        {translatorSelected && (
          <Autocomplete
            multiple
            value={languagesValue}
            options={languages}
            getOptionLabel={(option: any) => (option ? option.name : '')}
            fullWidth={true}
            onChange={onLangSelect}
            renderInput={(params: any) => <TextFieldMatUi {...params} label="Languages" />}
          />
        )}
      </WrappedSimpleForm>
    </Edit>
  );
};
