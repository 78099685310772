/**
 * **Component**
 *
 * Left menu of the application. It is needed because we require a couple of extra menu points in addition to the ones that React Admin auto generates.
 */

/** ignore this comment */

import * as React from 'react';
import { getResources, MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';

import authProvider from './AuthProvider';
import { UserPermissions, UserRole } from './models/user-permissions.model';
import { areaService } from './areaService';
import CustomMenuItemLink from './components/shared/CustomMenuItemLink';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  positionLegal: { position: 'absolute', bottom: 0, width: '9.25em' }
});

const Menu = (props: any) => {
  const classes = useStyles();
  const { onMenuClick } = props;
  const [permissions, setPermissions] = React.useState<UserPermissions | any>(null);
  React.useEffect(() => {
    authProvider.getPermissions().then((permissions) => setPermissions(permissions));
  }, []);
  let listResource: string[] = [];
  if (permissions && permissions.role)
    if (permissions.role === UserRole.SUPER_ADMIN || permissions.role === UserRole.ADMIN) {
      listResource = [
        'modellines',
        'submodels',
        'derivatives',
        'colors',
        'rims',
        'funFacts',
        'markets',
        'events',
        'userAccount',
        'languages',
        'globalPhrases',
        'newsItems'
      ];
    } else if (permissions.role === UserRole.EDITOR_PUBLISHER) {
      listResource = ['events'];
    } else {
      listResource = ['languages'];
    }

  const itemClicked = (ev: any) => {
    if (ev.target.dataset.area === 'market') {
      areaService.changeArea('market');
    } else if (ev.target.dataset.area === 'event') {
      areaService.changeArea('event');
    } else {
      areaService.changeArea('other');
    }
    onMenuClick(ev);
  };
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText="Home"
        onClick={itemClicked}
        sidebarIsOpen={open}
        exact={true}
      />
      {resources.map((resource) => {
        if (listResource.find((x) => x === resource.name)) {
          if (resource.name === 'events') {
            return (
              <>
                <CustomMenuItemLink
                  key={'market'}
                  to={`/events?mode=market`}
                  primaryText="Markets"
                  onClick={itemClicked}
                  data-area="market"
                  sidebarIsOpen={open}
                  exact={false}
                />
                <CustomMenuItemLink
                  key={'event'}
                  to={`/events?mode=event`}
                  primaryText="Events"
                  data-area="event"
                  onClick={itemClicked}
                  sidebarIsOpen={open}
                  exact={false}
                />
              </>
            );
          } else {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={(resource.options && resource.options.label) || resource.name}
                onClick={itemClicked}
                sidebarIsOpen={open}
              />
            );
          }
        } else {
          return null;
        }
      })}
      <MenuItemLink
        to="/support"
        primaryText="Support"
        onClick={itemClicked}
        sidebarIsOpen={open}
        exact={true}
      />
      <MenuItemLink
        className={classes.positionLegal}
        to="/legal"
        primaryText="Legal"
        onClick={itemClicked}
        sidebarIsOpen={open}
        exact={true}
      />
    </div>
  );
};

export default Menu;
