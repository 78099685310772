/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to manage the generic attributes of markets and events.
 * The use of this component is reserved to super admins.
 *
 */

/** ignore this comment */

import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as R from 'ramda';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  Error,
  FileField,
  FileInput,
  FormTab,
  GetListParams,
  GetOneResult,
  Loading,
  Record,
  ReferenceField,
  required,
  TabbedForm,
  TextInput,
  useDataProvider,
  useNotify
} from 'react-admin';

import { useFileUpload } from '../../hooks/useFileUpload';
import { JSONField } from '../shared/JSONField';
import { ReferenceFileField } from '../shared/ReferenceFileField';
import { UploadToolbar } from '../shared/UploadToolbar';
import { ManageEditorPublisher } from './manage_editor_publisher/ManageEditorPublisher';
import { checkRolePermission } from '../../utils/permissionHelper';
import { UserRole } from '../../models/user-permissions.model';
import { IEvent } from '../../models/event.model';
import { areaService } from '../../areaService';
import { ILanguage, IEventLanguage } from '../../models/languages.model';
import { ICountry, IEventCountryCode } from '../../models/country.model';

export const EventEdit = (props: any): JSX.Element => {
  const recordId = (props as any).location?.pathname.split('/').pop();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<any>();
  const [areaValue, setArea] = useState<string>('event');

  const [languagesValue, setLanguagesValue] = useState<ILanguage[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<IEventLanguage[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  const [countriesValues, setCountriesValues] = useState<ICountry[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<IEventCountryCode[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);

  const notify = useNotify();

  const {
    uploadError: vuforiaDbXmlFileError,
    fileId: vuforiaDbXmlFileId,
    handleFileUpload: vuforiaDbXmlFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaDbDatFileError,
    fileId: vuforiaDbDatFileId,
    handleFileUpload: vuforiaDbDatFileUpload
  } = useFileUpload();

  const derFileParams: { key: string; value: string | undefined }[] = [
    { key: 'vuforiaDbXmlFileId', value: vuforiaDbXmlFileId },
    { key: 'vuforiaDbDatFileId', value: vuforiaDbDatFileId }
  ];

  const comp = (x: Record, y: Record) => x.id === y.id;

  const onLangSelect = (options: any, state: any, reason: string): void => {
    (async () => {
      const diff = R.symmetricDifferenceWith(comp, state, languagesValue);

      if (reason === 'select-option') {
        try {
          const { data } = await dataProvider.create<IEventLanguage>('eventLanguages', {
            data: {
              eventId: props.id,
              languageId: diff[0].id
            }
          });
          setLanguagesValue((selLanguages: any) => {
            const newLanguage = R.find(R.propEq('id', diff[0].id))(languages) as any;
            const d = selLanguages.slice();
            d.push(newLanguage);
            return d;
          });
          setSelectedLanguages(() => {
            const d = selectedLanguages.slice();
            d.push(data);
            return d;
          });
        } catch (e) {
          console.error('error', e);
          notify('error adding the language', 'error');
        }
      } else {
        try {
          const toDelete = R.find(R.propEq('languageId', diff[0].id))(selectedLanguages) as any;
          if (!toDelete) {
            notify('You need to have at least one language defined!');
          }

          await dataProvider.delete('eventLanguages', {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: toDelete!.id,
            previousData: toDelete
          });

          setLanguagesValue((selLanguages: any) => {
            const indexToDelete = R.findIndex(R.propEq('id', diff[0].id))(selLanguages);
            return R.remove(indexToDelete, 1, selLanguages);
          });
          setSelectedLanguages(() => {
            const indexToDelete = R.findIndex(R.propEq('id', toDelete.id))(selectedLanguages);
            return R.remove(indexToDelete, 1, selectedLanguages);
          });
        } catch (e) {
          console.error('error', e);
          notify('error removing the language', 'error');
        }
      }
    })();
  };

  const onCountrySelect = (options: any, state: any, reason: string): void => {
    (async () => {
      const diff = R.symmetricDifferenceWith(comp, state, countriesValues);
      if (reason === 'select-option') {
        try {
          const { data } = await dataProvider.create<IEventCountryCode>('eventCountryCodes', {
            data: {
              eventId: props.id,
              countryCodeId: diff[0].id
            }
          });

          setCountriesValues((selCountries: any) => {
            const newCountry = R.find(R.propEq('id', diff[0].id))(countries) as any;
            const d = selCountries.slice();
            d.push(newCountry);
            return d;
          });
          setSelectedCountries(() => {
            const d = selectedCountries.slice();
            d.push(data);
            return d;
          });
        } catch (e) {
          console.error('error', e);
          notify('error adding the country, it already exists in another market', 'error');
        }
      } else {
        try {
          const toDelete = R.find(R.propEq('countryCodeId', diff[0].id))(selectedCountries) as any;
          if (!toDelete) {
            notify('You need to have at least one country defined!', 'error');
          }

          await dataProvider.delete('eventCountryCodes', {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: toDelete!.id,
            previousData: toDelete
          });
          setCountriesValues((selCountries: any) => {
            const indexToDelete = R.findIndex(R.propEq('id', diff[0].id))(selCountries);
            return R.remove(indexToDelete, 1, selCountries);
          });
          setSelectedCountries(() => {
            const indexToDelete = R.findIndex(R.propEq('id', toDelete.id))(selectedCountries);
            return R.remove(indexToDelete, 1, selectedCountries);
          });
        } catch (e) {
          console.error('error', e);
          notify('error removing the country', 'error');
        }
      }
    })();
  };

  const features = [
    { id: 'placeCar', name: 'Place Car' },
    { id: 'scanCar', name: 'Scan Sar' },
    { id: 'imageRecognition', name: 'Image Recognition' },
    { id: 'porscheCode', name: 'Porsche Code' }
  ];

  const dataProvider = useDataProvider();

  useEffect(() => {
    (async () => {
      try {
        const selLangParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { eventId: props.id }
        };

        const langParams: GetListParams = {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {}
        };

        const selCountryParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { eventId: props.id }
        };

        const countryParams: GetListParams = {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name_eng', order: 'ASC' },
          filter: {}
        };

        const selLangP: Promise<GetOneResult<IEventLanguage[]>> = dataProvider.getList(
          'eventLanguages',
          selLangParams
        );
        const langP: Promise<GetOneResult<ILanguage[]>> = dataProvider.getList(
          'languages',
          langParams
        );
        const selCountryP: Promise<GetOneResult<IEventCountryCode[]>> = dataProvider.getList(
          'eventCountryCodes',
          selCountryParams
        );
        const countryP: Promise<GetOneResult<ICountry[]>> = dataProvider.getList(
          'countryCodes',
          countryParams
        );
        const eventP: Promise<GetOneResult<IEvent>> = dataProvider.getOne('events', {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: recordId!
        });

        const [
          selectedLangsIds,
          langs,
          selectedCountriesIds,
          countries,
          event
        ] = await Promise.all([selLangP, langP, selCountryP, countryP, eventP]);

        setSelectedLanguages(selectedLangsIds.data);
        const selLangIds = selectedLangsIds.data.map((lang: any) => lang.languageId) as string[];
        const loadedLanguages = langs.data;
        setLanguages(loadedLanguages);
        const selectedLangs = loadedLanguages.filter((lang) =>
          selLangIds.includes(lang.id as string)
        );
        setLanguagesValue(selectedLangs);

        setSelectedCountries(selectedCountriesIds.data);
        const selCountriesIds = selectedCountriesIds.data.map(
          (country: any) => country.countryCodeId
        ) as string[];
        const loadedCountries = countries.data;
        setCountries(loadedCountries);
        const selectedCountries: ICountry[] = loadedCountries.filter((country) =>
          selCountriesIds.includes(country.id as string)
        );
        setCountriesValues(selectedCountries);

        setArea(event.data.isMarket ? 'market' : 'event');

        areaService.changeArea(event.data.isMarket ? 'market' : 'event');
        setLoaded(true);
      } catch (e) {
        console.log(e);
        setLoadError(e);
      }
    })();
  }, []);

  if (loadError) {
    return <Error error={loadError} />;
  }
  if (!loaded) {
    return <Loading />;
  }

  const onFailure = (error: any) => {
    if (error?.message?.indexOf('event_name_unique')) {
      notify(`Event name have to be unique!`);
      return;
    }

    notify(error.message, 'error');
  };

  return (
    <Edit title="Admin Edit" {...props} undoable={false} onFailure={onFailure}>
      <TabbedForm
        toolbar={<UploadToolbar {...props} fileparams={derFileParams} />}
        redirect={false}>
        <FormTab label="General Information">
          <DateInput disabled source="createdDate" />
          <TextInput
            source="name"
            validate={required()}
            label={areaValue === 'market' ? 'Market name' : 'Event name'}
          />
          <TextInput source="tutorialUrl" label="Tutorial URL" />
          {features.map(({ id, name }) => (
            <BooleanInput key={id} label={name} source={id} />
          ))}
          {languagesValue && languages && (
            <Autocomplete
              multiple
              value={languagesValue}
              options={languages}
              fullWidth={true}
              getOptionLabel={(option: any) => (option ? option.name : '')}
              onChange={onLangSelect}
              renderInput={(params: any) => (
                <TextField {...params} variant="standard" label="Languages" />
              )}
            />
          )}
          {areaValue === 'market' && countriesValues && countries && (
            <Autocomplete
              multiple
              value={countriesValues}
              options={countries}
              fullWidth={true}
              getOptionLabel={(option: any) =>
                option && option.nameEng ? option.nameEng : option.name ? option.name : ''
              }
              onChange={onCountrySelect}
              renderInput={(params: any) => (
                <TextField {...params} variant="standard" label="Country" />
              )}
            />
          )}
        </FormTab>
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
          <FormTab label="Advanced Configuration">
            <TextInput source="iccLink" label="Back to ICC link" />
            <JSONField source="appSettings" label="App Settings" />
            <JSONField source="regions" label="Regions" />
            <JSONField source="porscheXccSettings" label="Porsche XCC Settings" height={60} />
            <JSONField source="twinModels" label="Twin Models" height={100} />
          </FormTab>
        )}
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
          <FormTab label="Image Recognition Vuforia">
            <ReferenceField source="vuforiaDbXmlFileId" reference="files" link={false}>
              <ReferenceFileField source="file" />
            </ReferenceField>

            {vuforiaDbXmlFileError && <Alert severity="error">{vuforiaDbXmlFileError}</Alert>}
            <FileInput
              onChange={vuforiaDbXmlFileUpload}
              source="vuforiaDbXmlFileIdNotImportant"
              label="Vuforia db xml file Upload"
              accept=".xml">
              <FileField source="src" title="title" />
            </FileInput>

            <ReferenceField source="vuforiaDbDatFileId" reference="files" link={false}>
              <ReferenceFileField source="file" />
            </ReferenceField>

            {vuforiaDbDatFileError && <Alert severity="error">{vuforiaDbDatFileError}</Alert>}
            <FileInput
              onChange={vuforiaDbDatFileUpload}
              source="vuforiaDbDatFileIdNotImporant"
              label="Vuforia db dat file Upload"
              accept=".dat">
              <FileField source="src" title="title" />
            </FileInput>
          </FormTab>
        )}
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) && (
          <FormTab label="Publishers & Editors">
            <ManageEditorPublisher {...props} />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};
