/**
 * **Component**
 *
 * Utility component used to show file names. Used together with the upload mechanism
 *
 */

/** ignore this comment */
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';

import { ReferenceFileFieldProps } from '../../models/reference-file-field.model';

export const ReferenceFileField = (props: ReferenceFileFieldProps): JSX.Element => {
  try {
    const file = JSON.parse(props.record!.file);
    const filename = file.filename;
    return <p>{filename}</p>;
  } catch (error) {
    return <Alert>Error parsing: {JSON.stringify(props.record)}</Alert>;
  }
};
