/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to edit existing News Items
 *
 * The news items are shows in the **Home** page of the CMS
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { DateField, DateInput, Edit, required, SimpleForm } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const NewsEdit = (props: any): JSX.Element => {
  const classes = useStyles();
  return (
    <Edit title="Edit What’s New" {...props} redirect={false}>
      <SimpleForm>
        <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
        <DateInput source="releaseDate" validate={required()} />
        <RichTextInput source="description" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
