/**
 * **Component**
 *
 * Component based on React Admin **List** show existing Users
 * and as an entry point for deleting and creating them.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Fragment } from 'react';
import { Datagrid, EditButton, List, TextField, BulkDeleteButton, Filter } from 'react-admin';
import { checkRolePermission } from '../../utils/permissionHelper';
import { UserRole } from '../../models/user-permissions.model';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

const UsersFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="q" alwaysOn={true} />
  </Filter>
);

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const UserAccountList = (props: any) => {
  return (
    <List
      {...props}
      exporter={false}
      title="Users"
      filters={<UsersFilter />}
      bulkActionButtons={
        checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
          <ListBulkActionButtons {...props} />
        ) : (
          false
        )
      }>
      <Datagrid>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="role" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};
