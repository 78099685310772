/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new phrase id's.
 *
 * Phrase ID's are used in the **Translations**. This area provides the possibility
 * of managing the keys as well as an example text. The actual content shown on the
 * app will be taken from the translation files.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

export const PhraseCreate = (props: any): JSX.Element => {
  return (
    <Create title="Create a phrase" {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="englishText" validate={required()} multiline fullWidth />
      </SimpleForm>
    </Create>
  );
};
