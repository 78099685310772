/**
 * **Service**
 *
 * This service takes care of handling the many to many relationships for the derivatives.
 * It will add an remove relationships for colors and rims.
 *
 * To be used, this service needs to be intialized calling its
 * ```init(params: IEditDerServiceParams)```
 * method.
 *
 */

/** ignore this comment */
import { Dispatch, SetStateAction } from 'react';
import { DataProviderProxy } from 'react-admin';

import {
  IColorChoice,
  IDerColor,
  IDerRim,
  IEditDerServiceParams,
  IModellineRimChoice
} from '../../models/derivatives.model';

let _derivativeId: string;
let _dataProvider: DataProviderProxy;
let _derivativeColors: IDerColor[];
let _setDerivateColors: Dispatch<SetStateAction<IDerColor[]>>;
let _derivativeRims: IDerRim[];
let _setDerivativeRims: Dispatch<SetStateAction<IDerRim[]>>;
let _notify: (
  message: string,
  type?: 'info' | 'warning' | 'error' | undefined,
  messageArgs?: any,
  undoable?: boolean | undefined,
  autoHideDuration?: number | undefined
) => void;

function init(params: IEditDerServiceParams): void {
  _derivativeId = params.derivativeId;
  _dataProvider = params.dataProvider;
  _derivativeColors = params.derivativeColors;
  _setDerivateColors = params.setDerivativeColors;
  _derivativeRims = params.derivativeRims;
  _setDerivativeRims = params.setDerivativeRims;
  _notify = params.notify;
}

const handleColorChange = (choice: IColorChoice, checked: boolean): void => {
  if (checked) {
    _dataProvider.create(
      'derColors',
      {
        data: {
          derivativeId: _derivativeId,
          colorId: choice.id
        }
      },
      {
        onSuccess: ({ data }: { data: any }) => {
          _setDerivateColors((derColors) => {
            const d = derColors.slice();
            d.push(data);
            return d;
          });
          _notify('Derivative Colors updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  } else {
    const toDelete = _derivativeColors.find((derColor) => {
      return derColor.colorId === choice.id;
    });
    _dataProvider.delete(
      'derColors',
      {
        id: toDelete!.id,
        previousData: toDelete!
      },
      {
        onSuccess: ({ data }: { data: any }) => {
          const indexToDelete = _derivativeColors.findIndex((derColor) => {
            return derColor.id === data.id;
          });

          //const d = _derivativeColors.slice();
          //d.splice(indexToDelete, 1);
          _setDerivateColors((derColors) => {
            const d = derColors.slice();
            d.splice(indexToDelete, 1);
            return d;
          });
          _notify('Derivative Colors updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  }
};

const handleRimChange = (choice: IModellineRimChoice, checked: boolean): void => {
  if (checked) {
    _dataProvider.create(
      'derRims',
      {
        data: {
          derivativeId: _derivativeId,
          modellineRimId: choice.id
        }
      },
      {
        onSuccess: ({ data }: { data: any }) => {
          _setDerivativeRims((derRims) => {
            const d = derRims.slice();
            d.push(data);
            return d;
          });
          _notify('Derivative Rims updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  } else {
    const toDelete = _derivativeRims.find((derRim) => {
      return derRim.modellineRimId === choice.id;
    });
    _dataProvider.delete(
      'derRims',
      {
        id: toDelete!.id,
        previousData: toDelete!
      },
      {
        onSuccess: ({ data }: { data: any }) => {
          const indexToDelete = _derivativeRims.findIndex((derRim) => {
            return derRim.id === data.id;
          });
          _setDerivativeRims((derRims) => {
            const d = derRims.slice();
            d.splice(indexToDelete, 1);
            return d;
          });
          _notify('Derivative Rims updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  }
};

export { init, handleColorChange, handleRimChange };
