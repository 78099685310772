/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add image descriptions for the
 * image recognition system.
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  minValue,
  maxValue
} from 'react-admin';

import { useFileUpload } from '../../../../hooks/useFileUpload';
import { JSONField } from '../../../shared/JSONField';
import { UploadToolbar } from '../../../shared/UploadToolbar';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex' }
});

const isInteger = (message = 'The value must be an integer.') => (value: string) =>
  !isNaN(Number(value)) && Number.isInteger(Number(value)) ? null : message;

const validateScale = [minValue(1), maxValue(100), isInteger()];

export const CreateImageRecognition = (props: any) => {
  const classes = useStyles();
  const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();

  const fileParams = [{ key: 'thumbnailFileId', value: thumbnailFileId }];
  const { toggleDrawer, eventId } = props;
  return (
    <Create {...props} onSuccess={toggleDrawer} title=" ">
      <SimpleForm toolbar={<UploadToolbar {...props} fileparams={fileParams} />}>
        <TextInput disabled source="eventId" initialValue={eventId} />
        <TextInput source="name" validate={required()} />
        <TextInput source="porscheCode" validate={required()} />
        <SelectInput
          source="action"
          choices={[
            { id: 'trigger', name: 'Trigger' },
            { id: 'tracker', name: 'Tracker' }
          ]}
          validate={required()}
        />
        <NumberInput
          label="Scale (%)"
          source="scale"
          max="100"
          min="1"
          step={1}
          validate={validateScale}
        />
        <SelectInput
          label="Rotation"
          source="rotY"
          choices={[
            { id: 0, name: '0°' },
            { id: 90, name: '90°' },
            { id: 180, name: '180°' },
            { id: 270, name: '270°' }
          ]}
        />
        <SelectInput
          label="Tech part"
          source="techpart"
          choices={[
            { id: 'Hotspots', name: 'Hotspots' },
            { id: 'Drivetrain', name: 'Drivetrain' },
            { id: 'DesignDNA', name: 'Design DNA' },
            { id: 'Charging', name: 'Charging' },
            { id: 'ElectricDrive', name: 'Electric Drive' }
          ]}
        />
        {uploadError && <Alert severity="error">{uploadError}</Alert>}
        <ImageInput
          onChange={handleFileUpload}
          source="modellineCreatethumbnail"
          label="Thumbnail File Upload"
          accept="image/*"
          validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateImageRecognition;
