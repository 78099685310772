// backend sends the enums in uppercase, please keep it like that
export enum UnitSystemModel {
  Metric = 'METRIC',
  Imperial = 'IMPERIAL'
}

export const unitSystems = [UnitSystemModel.Metric, UnitSystemModel.Imperial];

export const unitSystemChoices = unitSystems.map((us: UnitSystemModel) => ({
  id: us,
  name: us.toLowerCase()
}));
