export enum FuelTypeModel {
  Unknown = 'UNKNOWN',
  Custom = 'CUSTOM',
  Gasoline = 'GASOLINE',
  Hybrid = 'HYBRID',
  Electric = 'ELECTRIC'
}

export const fuelTypes = [
  FuelTypeModel.Unknown,
  FuelTypeModel.Custom,
  FuelTypeModel.Gasoline,
  FuelTypeModel.Hybrid,
  FuelTypeModel.Electric
];

export const fuelTypeChoices = fuelTypes.map((ft: FuelTypeModel) => ({
  id: ft,
  name: ft.toLowerCase()
}));
