/**
 * **Component**
 *
 * Component based on React Admin **Create** component to manage existing Rims
 *
 * Please note that not all rims are available for all Model Lines. Here is where
 * we handle the association.
 *
 */

/** ignore this comment */
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import {
  DateField,
  Edit,
  Error,
  FormTab,
  GetListParams,
  ImageField,
  ImageInput,
  Loading,
  ReferenceField,
  required,
  ResourceComponentPropsWithId,
  TabbedForm,
  TextInput,
  useDataProvider,
  useNotify
} from 'react-admin';

import { useFileUpload } from '../../hooks/useFileUpload';
import { IEditRimServiceParams, IModellineChoice, IModelRim, IRims } from '../../models/rims.model';
import { ReferenceImageField } from '../shared/ReferenceImageField';
import { UploadToolbar } from '../shared/UploadToolbar';
import * as EditService from './EditRimService';

const SanitizedFormControlLabel = ({ basePath, ...props }: any): JSX.Element => {
  return <FormControlLabel {...props} />;
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const RimEdit: React.FC<ResourceComponentPropsWithId> = (props: any): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | any>();
  const [rimData, setRimData] = React.useState<IRims | null>(null);

  const [modelChoices, setModelChoices] = React.useState<IModellineChoice[]>([]);
  const [modelRims, setModelRims] = React.useState<IModelRim[]>([]);

  const dataProvider = useDataProvider();

  const rimId = props.id!;

  const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();

  const fileParams = [{ key: 'thumbnailFileId', value: thumbnailFileId }];
  const notify = useNotify();
  const serviceParams: IEditRimServiceParams = {
    rimId,
    dataProvider,
    modelRims,
    setModelRims: setModelRims,
    notify
  };
  EditService.init(serviceParams);

  React.useEffect(() => {
    dataProvider
      .getOne('rims', { id: rimId })
      .then(({ data }) => {
        const rim = data as IRims;
        setRimData(rim);
        const modellineParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
          filter: {}
        };

        const modellineRimParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'rimId', order: 'ASC' },
          filter: { rimId: props.id }
        };
        const modellines = dataProvider.getList('modellines', modellineParams);
        const modellineRims = dataProvider.getList('modellineRims', modellineRimParams);

        Promise.all([modellines, modellineRims])
          .then(([_modellineChoices, _modellineRims]) => {
            setModelChoices(_modellineChoices.data as IModellineChoice[]);
            setModelRims(_modellineRims.data as IModelRim[]);
            setLoading(false);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, props.id, rimId]);
  const classes = useStyles();
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  const onFailure = (error: any) => {
    if (error?.message?.indexOf('unique_rim_option_code')) {
      notify(`Option code of rim have to be unique!`);
      return;
    }
    notify(error.message, 'error');
  };
  return (
    <Edit title="Edit Rim" undoable={false} onFailure={onFailure} {...props}>
      <TabbedForm toolbar={<UploadToolbar {...props} fileparams={fileParams} />} redirect={false}>
        <FormTab label="summary">
          <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
          <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
          <TextInput source="name" validate={required()} multiline />
          <TextInput source="optionCode" validate={required()} />

          <ReferenceField source="thumbnailFileId" reference="files" link={false}>
            <ReferenceImageField source="file" maxWidth="250px" />
          </ReferenceField>
          {uploadError && <Alert severity="error">{uploadError}</Alert>}
          <ImageInput
            onChange={handleFileUpload}
            source="editRim"
            label="Thumbnail File Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label="Link to model Lines">
          {modelChoices.map((choice) => (
            <SanitizedFormControlLabel
              control={
                <Checkbox
                  checked={modelRims?.map((mr: any) => mr.modellineId).includes(choice.id) ?? false}
                  onChange={(e) => EditService.handleModelChange(choice, e.target.checked)}
                  color="primary"
                />
              }
              key={choice.id}
              label={choice.name}
            />
          ))}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
