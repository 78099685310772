/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to manage existing Derivatives.
 * Because of the many to many relationships included, adding a derivative is a two step proces,
 * first in the create component the user add the base attributes of the derivative and the on the
 * edit component it add those attributes related to additional entities.
 *
 * This component delagates most of the interaction with the backend to the
 * {@link EditDerivativeService}.
 *
 */

/** ignore this comment */

import { Checkbox } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import {
  DateField,
  Edit,
  Error,
  FileField,
  FileInput,
  FormTab,
  GetListParams,
  ImageField,
  ImageInput,
  Loading,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  required,
  ResourceComponentPropsWithId,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useDataProvider,
  useNotify
} from 'react-admin';

import { useFileUpload } from '../../hooks/useFileUpload';
import {
  IColorChoice,
  IDerColor,
  IDerivative,
  IDerRim,
  IEditDerServiceParams,
  IModellineRimChoice
} from '../../models/derivatives.model';
import { ColorCircle } from '../colors/ColorCircle';
import { JSONField } from '../shared/JSONField';
import { ReferenceFileField } from '../shared/ReferenceFileField';
import { ReferenceImageField } from '../shared/ReferenceImageField';
import { SanitizedFormControlLabel } from '../shared/SanitizedFormControlLabel';
import { UploadToolbar } from '../shared/UploadToolbar';
import * as EditService from './EditDerivativeService';
import { fuelTypeChoices, FuelTypeModel } from '../../models/fuel-type.models';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const DerivativeEdit: React.FC<ResourceComponentPropsWithId> = (props): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | any>();
  const [derivativeData, setDerivativeData] = React.useState<IDerivative | null>(null);
  const [colorChoices, setColorChoices] = React.useState<IColorChoice[]>([]);
  const [derivativeColors, setDerColors] = React.useState<IDerColor[]>([]);

  const [rimChoices, setRimChoices] = React.useState<IModellineRimChoice[]>([]);
  const [derivativeRims, setDerRims] = React.useState<IDerRim[]>([]);

  const dataProvider = useDataProvider();

  const {
    uploadError: thumbnailUploadError,
    fileId: thumbnailFileId,
    handleFileUpload: thumbnailHandleFileUpload
  } = useFileUpload();

  const {
    uploadError: funFactUploadError,
    fileId: funFactFileId,
    handleFileUpload: funFactHandleFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaDbXmlFileError,
    fileId: vuforiaDbXmlFileId,
    handleFileUpload: vuforiaDbXmlFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaDbDatFileError,
    fileId: vuforiaDbDatFileId,
    handleFileUpload: vuforiaDbDatFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaStencilError,
    fileId: vuforiaStencilFileId,
    handleFileUpload: vuforiaStencilFileUpload
  } = useFileUpload();

  const {
    uploadError: silhouetteFileError,
    fileId: silhouetteFileId,
    handleFileUpload: silhouetteFileUpload
  } = useFileUpload();

  const derFileParams: { key: string; value: string | undefined }[] = [
    { key: 'thumbnailFileId', value: thumbnailFileId },
    { key: 'funfactImageFileId', value: funFactFileId },
    { key: 'vuforiaDbXmlFileId', value: vuforiaDbXmlFileId },
    { key: 'vuforiaDbDatFileId', value: vuforiaDbDatFileId },
    { key: 'vuforiaStencilFileId', value: vuforiaStencilFileId },
    { key: 'silhouetteFileId', value: silhouetteFileId }
  ];

  const notify = useNotify();
  //TODO check if id is actually set
  const derivativeId = props.id!;

  const serviceParams: IEditDerServiceParams = {
    derivativeId,
    dataProvider,
    derivativeColors,
    setDerivativeColors: setDerColors,
    derivativeRims,
    setDerivativeRims: setDerRims,
    notify
  };

  EditService.init(serviceParams);

  let isCancelled = false;

  React.useEffect(() => {
    dataProvider
      .getOne('derivatives', { id: derivativeId })
      .then((result) => {
        if (!result) {
          return;
        }
        console.log('!result', result);
        const derivativeD = result.data as IDerivative;
        setDerivativeData(derivativeD);
        const colorParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
          filter: {}
        };

        const derColorParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'derivativeId', order: 'ASC' },
          filter: { derivativeId: props.id }
        };

        const modellineRimParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { modellineId: derivativeD.modellineId }
        };

        const derModellineRimParams: GetListParams = {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'derivativeId', order: 'ASC' },
          filter: { derivativeId: props.id }
        };
        const colorP = dataProvider.getList('colors', colorParams);
        const colorDP = dataProvider.getList('derColors', derColorParams);
        const rimsP = dataProvider.getList('modellineRims', modellineRimParams);
        const rimsDP = dataProvider.getList('derRims', derModellineRimParams);

        Promise.all([colorP, colorDP, rimsP, rimsDP])
          .then(([_colorChoices, _derColors, _rimChoices, _derRims]) => {
            if (!isCancelled) {
              setColorChoices(_colorChoices.data as IColorChoice[]);
              setDerColors(_derColors.data as IDerColor[]);
              setRimChoices(_rimChoices?.data as IModellineRimChoice[]);
              setDerRims(_derRims.data as IDerRim[]);
              setLoading(false);
            }
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        if (!isCancelled) {
          setError(error);
          setLoading(false);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, [dataProvider, derivativeId, props.id]);
  const classes = useStyles();
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Edit title="Edit Derivative" {...props}>
      <TabbedForm
        toolbar={<UploadToolbar {...props} fileparams={derFileParams} />}
        redirect={false}>
        <FormTab label="summary">
          <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
          <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
          <ReferenceField
            label="Model Line"
            source="modellineId"
            reference="modellines"
            sortBy="modellineId"
            link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceInput
            label="Sub Model"
            source="submodelId"
            reference="submodels"
            filter={{ modellineId: derivativeData!.modellineId }}
            sort={{ field: 'name', order: 'ASC' }}
            validate={required()}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" validate={required()} />
          <RadioButtonGroupInput
            source="fuelType"
            defaultValue={FuelTypeModel.Unknown}
            choices={fuelTypeChoices}
            style={{ textTransform: 'capitalize' }}
          />
          <TextInput source="orderType" validate={required()} />
          <TextInput source="assetBundleName" validate={required()} />
          <NumberInput source="bundleVersionNo" validate={required()} />
          <TextInput source="resourceId" label="Resource Id" />
          <TextInput source="scene" validate={required()} />
          <TextInput source="engineId" label="Engine ID" />
          <JSONField source="techParts" label="Tech parts" height={150} />
          <TextInput source="techPartsPreFix" />

          {derivativeData?.thumbnailFileId && (
            <ReferenceField
              label="Derivative Thumbnail"
              source="thumbnailFileId"
              reference="files"
              link={false}>
              <ReferenceImageField source="file" maxWidth="250px" />
            </ReferenceField>
          )}
          {thumbnailUploadError && <Alert severity="error">{thumbnailUploadError}</Alert>}
          <ImageInput
            onChange={thumbnailHandleFileUpload}
            source="editDerThumbnail"
            label="Derivative Thumbnail Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          {derivativeData?.funfactImageFileId && (
            <ReferenceField
              source="funfactImageFileId"
              label="Fun Fact Image Upload"
              reference="files"
              link={false}>
              <ReferenceImageField source="file" maxWidth="250px" />
            </ReferenceField>
          )}
          {funFactUploadError && <Alert severity="error">{funFactUploadError}</Alert>}
          <ImageInput
            onChange={funFactHandleFileUpload}
            source="editDerFF"
            label="Fun Fact Image Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="thumbnailFunfactDescription" />
        </FormTab>
        <FormTab label="Colors">
          {colorChoices.map((choice) => (
            <SanitizedFormControlLabel
              control={
                <>
                  <ColorCircle colorString={choice.hexadecimalValue} size={30} />
                  <Checkbox
                    checked={
                      derivativeColors?.map((mc: any) => mc.colorId).includes(choice.id) ?? false
                    }
                    onChange={(e) => EditService.handleColorChange(choice, e.target.checked)}
                    color="primary"
                  />
                </>
              }
              key={choice.id}
              label={choice.name}
              fullWidth="true"
            />
          ))}
        </FormTab>
        <FormTab label="Rims">
          {rimChoices.map((choice) => (
            <SanitizedFormControlLabel
              control={
                <Checkbox
                  checked={
                    derivativeRims?.map((dr: any) => dr.modellineRimId).includes(choice.id) ?? false
                  }
                  onChange={(e) => EditService.handleRimChange(choice, e.target.checked)}
                  color="primary"
                />
              }
              key={choice.id}
              label={`${choice.rimName} : ${choice.rimOptionCode}`}
            />
          ))}
        </FormTab>
        <FormTab label="Vuforia">
          {derivativeData?.vuforiaDbXmlFileId && (
            <ReferenceField source="vuforiaDbXmlFileId" reference="files" link={false}>
              <ReferenceFileField source="file" />
            </ReferenceField>
          )}

          {vuforiaDbXmlFileError && <Alert severity="error">{vuforiaDbXmlFileError}</Alert>}
          <FileInput
            onChange={vuforiaDbXmlFileUpload}
            source="editDervuforiaDbXmlFile"
            label="Vuforia db xml file Upload"
            accept=".xml">
            <FileField source="src" title="title" />
          </FileInput>

          {derivativeData?.vuforiaDbDatFileId && (
            <ReferenceField source="vuforiaDbDatFileId" reference="files" link={false}>
              <ReferenceFileField source="file" />
            </ReferenceField>
          )}
          {vuforiaDbDatFileError && <Alert severity="error">{vuforiaDbDatFileError}</Alert>}
          <FileInput
            onChange={vuforiaDbDatFileUpload}
            source="editDerVuforiaDbDatFile"
            label="Vuforia db dat file Upload"
            accept=".dat">
            <FileField source="src" title="title" />
          </FileInput>

          {derivativeData?.vuforiaStencilFileId && (
            <ReferenceField source="vuforiaStencilFileId" reference="files" link={false}>
              <ReferenceImageField source="file" maxWidth="250px" />
            </ReferenceField>
          )}
          {vuforiaStencilError && <Alert severity="error">{vuforiaStencilError}</Alert>}
          <ImageInput
            onChange={vuforiaStencilFileUpload}
            source="editDerVuforiaStencilFile"
            label="Vuforia stencil file Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          {derivativeData?.silhouetteFileId && (
            <ReferenceField source="silhouetteFileId" reference="files" link={false}>
              <ReferenceImageField source="file" maxWidth="250px" />
            </ReferenceField>
          )}
          {silhouetteFileError && <Alert severity="error">{silhouetteFileError}</Alert>}
          <ImageInput
            onChange={silhouetteFileUpload}
            source="editDerVuforiaSilhouetteFile"
            label="Silhouette file Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
