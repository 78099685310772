/**
 * **Component**
 *
 * Utility component used to handle Tabs in a tabs pannel
 *
 */

/** ignore this comment */
import { Box } from '@material-ui/core';
import React from 'react';

import { TabPanelProps } from '../../models/tab-panel.model';

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, padding, ...other } = props;
  const _padding = padding ?? '24px';
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && (
        <Box p={3} style={{ padding: _padding }}>
          {children}
        </Box>
      )}
    </div>
  );
};
