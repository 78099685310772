/**
 * **Component**
 *
 * Component based on React Admin **List** component to manage  derivatives
 *
 */

/** ignore this comment */
import * as React from 'react';
import { ChangeEvent, Fragment, useState } from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField
} from 'react-admin';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

const DerivativeFilter = (props: any) => {
  const [submodelFilter, setSubmodelFilter] = useState<any>();
  const handleModellineChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value && event.target.value != '') {
      setSubmodelFilter({ modellineId: event.target.value });

      props.setFilters({
        ...props.filterValues, // keep search field value
        modellineId: event.target.value,
        submodelId: ''
      });
    } else {
      setSubmodelFilter({});
    }
  };

  return (
    <Filter {...props}>
      <SearchInputCaseInsensitive source="name" alwaysOn={true} />
      <ReferenceInput
        onChange={handleModellineChange}
        label="Model Line"
        source="modellineId"
        reference="modellines"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn>
        <SelectInput emptyText="All" />
      </ReferenceInput>
      <ReferenceInput
        label="Sub Model"
        source="submodelId"
        reference="submodels"
        filter={submodelFilter}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn>
        <SelectInput emptyText="All" />
      </ReferenceInput>
    </Filter>
  );
};

export const DerivativeList = (props: any) => (
  <List
    {...props}
    filters={<DerivativeFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    exporter={false}
    bulkActionButtons={
      checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
        <ListBulkActionButtons {...props} />
      ) : (
        false
      )
    }>
    <Datagrid>
      <ReferenceField reference="modellines" source="modellineId" label="Model Line">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField reference="submodels" source="submodelId" label="Sub Model">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Derivative" />
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
        <EditButton label="Edit" />
      )}
    </Datagrid>
  </List>
);
