/**
 * **Component**
 *
 * Static component with links to support and documentation
 *
 */

/** ignore this comment */
import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import Download from '@material-ui/icons/GetApp';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '0.5rem' }
});
export const SupportComponent = () => {
  const classes = useStyles();
  return (
    <Typography component="div">
      <div>
        <p className={classes.inlineBlock}>
          <strong>Click to download the instruction manual</strong>
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="./instruction_manual.pdf"
          download="instruction_manual.pdf">
          <Button>
            <Download />
          </Button>
        </a>
        <br />
        <p className={classes.inlineBlock}>
          <strong>Need help? </strong> &nbsp; Contact
        </p>
        <a href="mailto:support@parv-cms.com">
          support@parv-cms.com
        </a>
      </div>
    </Typography>
  );
};
