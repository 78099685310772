/**
 * **Component**
 *
 * Utility component used to style the MenuItems and to handle the distinction
 * between Markets and Events
 *
 */

/** ignore this comment */
import React, {
  forwardRef,
  useCallback,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';
import classnames from 'classnames';
import { StaticContext } from 'react-router';
import { NavLink, NavLinkProps } from 'react-router-dom';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { areaService } from '../../areaService';

// eslint-disable-next-line react/display-name
const NavLinkRef = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary
    },
    active: {
      color: theme.palette.text.primary
    },
    icon: { minWidth: theme.spacing(5) }
  }),
  { name: 'RaMenuItemLink' }
);

interface CustomMenuItemLink {
  classes: any;
  className: string;
  leftIcon: ReactElement;
  onClick: () => void;
  primaryText: string;
  staticContext: any;
  to: any;
  sidebarIsOpen: boolean;
}

// eslint-disable-next-line react/display-name
const CustomMenuItemLink: FC<MenuItemLinkProps> = forwardRef<HTMLLIElement>((props, ref) => {
  const {
    classes: classesOverride,
    className,
    primaryText,
    leftIcon,
    onClick,
    sidebarIsOpen,
    selected,
    ...rest
  } = props as any;
  const classes = useStyles(props);

  const handleMenuTap = useCallback(
    (e) => {
      onClick && onClick(e);
    },
    [onClick]
  );

  const [myClassName, setClassName] = useState<string>('');

  useEffect(() => {
    const subscription = areaService.areaChanged().subscribe((_area) => {
      if (
        (_area === 'event' && rest['data-area'] === 'event') ||
        (_area === 'market' && rest['data-area'] === 'market')
      ) {
        setClassName(classnames(classes.active));
      } else {
        setClassName(classnames(classes.root));
      }
    });
    return () => subscription.unsubscribe();
  }, [props]);
  const renderMenuItem = () => {
    return (
      <MenuItem
        className={myClassName}
        component={NavLinkRef}
        ref={ref}
        selected={false}
        {...rest}
        onClick={handleMenuTap}>
        {primaryText}
      </MenuItem>
    );
  };

  return renderMenuItem();
});

interface Props {
  leftIcon?: ReactElement;
  primaryText?: ReactNode;
  staticContext?: StaticContext;
  sidebarIsOpen: boolean;
}

export type MenuItemLinkProps = Props & NavLinkProps & MenuItemProps<'li', { button?: true }>; // HACK: https://github.com/mui-org/material-ui/issues/16245

export default CustomMenuItemLink;
