/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new Market or Event.
 * Because of the many to many relationships included, adding an event is a two step proces,
 * first in the create component the user adds the base attributes of the market or event and then on the
 * edit component they add those attributes related to additional entities.
 *
 * This is reserved to super admins. The prefered method of creating a market or event is by
 * duplicating an existing one.
 *
 */

/** ignore this comment */
import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  CreateResult,
  Error,
  FormTab,
  GetListParams,
  required,
  SaveButton,
  TabbedForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect
} from 'react-admin';

import { Area, areaService } from '../../areaService';
import { useLanguages } from '../../hooks/useLanguages';
import { ILanguage } from '../../models/languages.model';
import { JSONField } from '../shared/JSONField';

import { checkRolePermission } from '../../utils/permissionHelper';
import { UserRole } from '../../models/user-permissions.model';
import { useCountry } from '../../hooks/useCountries';
import { ICountry } from '../../models/country.model';
import { IModelline, ISubmodel } from '../../models/derivatives.model';

const EventCreateToolbar = (props: any): JSX.Element => {
  const {
    dataProvider,
    modellines,
    submodels,
    eventLangs,
    eventCountries,
    basePath,
    redirectTo,
    notify
  } = props;
  const onSuccess = async ({ data }: any) => {
    const { id: eventId } = data;
    const createEventLangPromises: Promise<CreateResult>[] = [];
    const createEventCountryPromises: Promise<CreateResult>[] = [];
    const createModellineEventsPromises: Promise<CreateResult>[] = [];
    const createSubModelEventsPromises: Promise<CreateResult>[] = [];

    let rowModelline = 1;
    let rowSubmodel = 1;

    // TODO do we have any other option
    eventLangs.forEach((languageId: string) => {
      createEventLangPromises.push(
        dataProvider.create('eventLanguage', { data: { eventId, languageId } })
      );
    });

    eventCountries.forEach((id: string) => {
      createEventCountryPromises.push(
        dataProvider.create('eventCountryCode', { data: { eventId, countryCodeId: id } })
      );
    });

    modellines.forEach((modelline: IModelline) => {
      createModellineEventsPromises.push(
        dataProvider.create('modelInfoEvents', {
          data: { eventId, modelId: modelline.id, priority: rowModelline }
        })
      );
      rowModelline = rowModelline + 1;
    });

    submodels.forEach((submodel: ISubmodel) => {
      createSubModelEventsPromises.push(
        dataProvider.create('submodelInfoEvents', {
          data: { eventId, submodelId: submodel.id, priority: rowSubmodel }
        })
      );
      rowSubmodel = rowSubmodel + 1;
    });
    redirectTo('edit', basePath, eventId, data);

    try {
      await Promise.all(createEventLangPromises);
    } catch (e) {
      console.error(e);
      notify('Could not add languages. Please add them on the Edit page.', 'error');
    }

    try {
      await Promise.all(createEventCountryPromises);
    } catch (e) {
      console.error(e);
      notify(
        'Could not add createEventCountryPromises. Please add them on the Edit page.',
        'error'
      );
    }

    try {
      await Promise.all([createModellineEventsPromises, createSubModelEventsPromises]);
    } catch (e) {
      console.error(e);
      notify('Could not add modelline events. Please add them on the Edit page.', 'error');
    }
  };

  return (
    <Toolbar {...props}>
      <SaveButton onSuccess={onSuccess} />
    </Toolbar>
  );
};

export const EventCreate = (props: any): JSX.Element => {
  const dataProvider = useDataProvider();
  const [eventLangs, setEventLangs] = useState<string[]>([]);
  const { languages, langError } = useLanguages();
  const [eventCountries, setEventCountry] = useState<string[]>([]);
  const { countries, countryError } = useCountry();
  const [title, setTitle] = useState<string>('');
  const [area, setArea] = useState<Area>('other');
  const [submodelsData, setSubmodelsData] = useState<ISubmodel[] | null>(null);
  const [modellinesData, setModellinesData] = useState<IModelline[] | null>(null);

  const redirectTo = useRedirect();
  const notify = useNotify();

  const features = [
    { id: 'placeCar', name: 'Place Car' },
    { id: 'scanCar', name: 'Scan Sar' },
    { id: 'imageRecognition', name: 'Image Recognition' },
    { id: 'porscheCode', name: 'Porsche Code' }
  ];

  const onLangSelect = ({ id }: ILanguage): void => {
    const eLangs = [...eventLangs, id];
    setEventLangs(eLangs);
  };

  const onCountrySelect = ({ id }: ICountry): void => {
    const eCountry = [...eventCountries, id];
    setEventCountry(eCountry);
  };

  useEffect(() => {
    const subscription = areaService.areaChanged().subscribe((_area) => {
      setArea(_area);
      if (_area === 'market') {
        setTitle('Markets');
      } else {
        setTitle('Events');
      }
    });

    const modellineParams: GetListParams = {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    };

    const submodelsParams: GetListParams = {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    };

    const modellinesP = dataProvider.getList('modellines', modellineParams);
    const submodelsP = dataProvider.getList('submodels', submodelsParams);

    Promise.all([modellinesP, submodelsP]).then(([modellines, submodels]) => {
      setModellinesData(modellines.data as IModelline[]);
      setSubmodelsData(submodels.data as ISubmodel[]);
    });

    return () => subscription.unsubscribe();
  }, [props]);

  if (langError) {
    return <Error error={langError} />;
  }

  if (countryError) {
    return <Error error={countryError} />;
  }

  return (
    <Create title={title} {...props}>
      <TabbedForm
        initialValues={() => ({ isMarket: area === 'market' })}
        toolbar={
          <EventCreateToolbar
            {...props}
            modellines={modellinesData}
            submodels={submodelsData}
            eventLangs={eventLangs}
            eventCountries={eventCountries}
            dataProvider={dataProvider}
            redirectTo={redirectTo}
            notify={notify}
          />
        }>
        <FormTab label="General Information">
          <TextInput
            source="name"
            validate={required()}
            label={area === 'market' ? 'Market name' : 'Event name'}
          />
          <TextInput source="tutorialUrl" label="Tutorial URL" />
          {features.map(({ id, name }) => (
            <BooleanInput key={id} label={name} source={id} />
          ))}
          <AutocompleteArrayInput source="languages" choices={languages} onSelect={onLangSelect} />
          {area === 'market' ? (
            <AutocompleteArrayInput
              source="countryCodes"
              choices={countries}
              optionText="nameEng"
              onSelect={onCountrySelect}
            />
          ) : null}
        </FormTab>
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
          <FormTab label="Advanced Configuration">
            <TextInput source="iccLink" label="Back to ICC link" />
            <JSONField source="appSettings" label="App Settings" />
            <JSONField source="regions" label="Regions" />
            <JSONField source="porscheXccSettings" label="Porsche XCC Settings" height={60} />
            <JSONField source="twinModels" label="Twin Models" height={100} />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
};
