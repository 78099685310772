/**
 * **Component**
 *
 * This component is used to style the CMS in a Porsche CI conform fashion.
 *
 * It contains a custom implementation of the Breadcrum navigation.
 *
 */

/** ignore this comment */

import { AppLocationContext, Breadcrumb, BreadcrumbItem } from '@react-admin/ra-navigation';
import React, { useState, useEffect } from 'react';
import { Layout, linkToRecord } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { CMSAppBar } from './CMSAppBar';
import { areaService } from '../../areaService';
import { curry, map, when, propEq, assoc } from 'ramda';

const useStyles = makeStyles({
  pAppFrame: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: '100px'
  }
});

type ResourceDescriptor = {
  resource: string;
  label: string;
};

const initResources = [
  { resource: 'modellines', label: 'Model Lines' },
  { resource: 'submodels', label: ' Sub Models' },
  { resource: 'derivatives', label: 'Derivatives' },
  { resource: 'colors', label: 'Ext. Colors' },
  { resource: 'rims', label: 'Rims' },
  { resource: 'funFacts', label: 'Fun Facts' },
  { resource: 'events', label: 'Events' },
  { resource: 'userAccount', label: 'Users' },
  { resource: 'languages', label: 'Translations' },
  { resource: 'globalPhrases', label: 'Phrase IDs' },
  { resource: 'newsItems', label: 'What’s New' }
];

const alter = curry((area, resource, initResources) =>
  map(when(propEq('resource', resource), assoc('label', area)), initResources)
);

export const CMSLayout = ({ children, ...props }: any) => {
  const classes = useStyles();

  const [resources, setResources] = useState<ResourceDescriptor[]>(initResources);

  useEffect(() => {
    const subscription = areaService.areaChanged().subscribe((_area) => {
      let newResources = [...initResources];
      if (_area === 'market') {
        newResources = alter('Markets', 'events', initResources);
      }
      setResources(newResources);
      return () => subscription.unsubscribe();
    });
  }, []);

  return (
    <AppLocationContext>
      <Layout
        {...props}
        appBar={CMSAppBar}
        classes={{
          appFrame: classes.pAppFrame
        }}>
        <Breadcrumb {...props}>
          {resources.map((resource) => (
            <BreadcrumbItem
              key={resource.resource}
              name={resource.resource}
              label={resource.label}
              to={`/${resource.resource}`}>
              <BreadcrumbItem key="list" name="list" label="List" />
              <BreadcrumbItem key="create" name="create" label="Create" />
              <BreadcrumbItem
                key="edit"
                name="edit"
                label={({ record }: any) => {
                  if (!record) {
                    return 'Edit';
                  }
                  if (resource.resource === 'funFacts') {
                    return `Edit : ${record.title}`;
                  } else if (resource.resource === 'userAccount') {
                    return `Edit : ${record.firstName}` + ' ' + `${record.lastName}`;
                  } else {
                    return `Edit : ${record.name}`;
                  }
                }}
                to={({ record }: any) => {
                  const uri = `/${resource.resource}`;
                  return record && `${linkToRecord(uri, record.id)}/edit`;
                }}
              />
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        {children}
      </Layout>
    </AppLocationContext>
  );
};
