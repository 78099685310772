/**
 * **Component**
 * This is the content of the home screen with news
 *
 */

/** ignore this comment */

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Error, GetListParams, Title, useDataProvider } from 'react-admin';
import { useEffect, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#fff',
    marginTop: '15px'
  },
  richTextContainer: {
    '& > *': {
      marginBottom: 0 // override <ul> default margin
    }
  }
});

interface INews {
  id: string;
  description: string;
  releaseDate: string;
}

const newsParams: GetListParams = {
  pagination: { page: 1, perPage: 10 },
  sort: { field: 'releaseDate', order: 'DESC' },
  filter: {}
};

export const Dashboard = (): JSX.Element => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [news, setNews] = useState<INews[]>([]);
  const [error, setError] = useState<any>();

  useEffect(() => {
    (async function getNews() {
      try {
        const { data } = await dataProvider.getList<INews>('newsItem', newsParams);
        setNews(data);
      } catch (e) {
        setError(e);
      }
    })();
  }, []);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div>
      <Title title="Home" />
      <Typography gutterBottom variant="h3" component="h1">
        What&apos;s New
      </Typography>
      {news.map((n) => (
        <Card key={n.id} className={classes.card} elevation={3}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" color="secondary">
              {new Date(n.releaseDate).toLocaleDateString('en-UK', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </Typography>
            <Typography component="div">
              <div
                className={classes.richTextContainer}
                dangerouslySetInnerHTML={{ __html: n.description }}
              />
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
