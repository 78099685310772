/**
 * **Service**
 *
 * The edit rims component delegates to this service the interaction with the GraphQL backend.
 *
 */

/** ignore this comment */
import { Dispatch, SetStateAction } from 'react';
import { DataProviderProxy } from 'react-admin';

import { IEditRimServiceParams, IModellineChoice, IModelRim } from '../../models/rims.model';

let _rimId: string;
let _dataProvider: DataProviderProxy;
let _modelRims: IModelRim[];
let _setModelRims: Dispatch<SetStateAction<IModelRim[]>>;
let _notify: (
  message: string,
  type?: 'info' | 'warning' | 'error' | undefined,
  messageArgs?: any,
  undoable?: boolean | undefined,
  autoHideDuration?: number | undefined
) => void;

function init(params: IEditRimServiceParams): void {
  _rimId = params.rimId;
  _dataProvider = params.dataProvider;
  _modelRims = params.modelRims;
  _setModelRims = params.setModelRims;
  _notify = params.notify;
}

const handleModelChange = (choice: IModellineChoice, checked: boolean): void => {
  if (checked) {
    _dataProvider.create(
      'modellineRims',
      {
        data: {
          rimId: _rimId,
          modellineId: choice.id
        }
      },
      {
        onSuccess: ({ data }: { data: any; }) => {
          const d = _modelRims.slice();
          d.push(data);
          _setModelRims(d);
          _notify('Model Rims updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  } else {
    const toDelete = _modelRims.find((modelRim) => {
      return modelRim.modellineId === choice.id;
    });
    _dataProvider.delete(
      'modellineRims',
      {
        id: toDelete!.id,
        previousData: toDelete!
      },
      {
        onSuccess: ({ data }: { data: any; }) => {
          console.log('data', data);
          const indexToDelete = _modelRims.findIndex((modelRim) => {
            return modelRim.id === data.id;
          });
          const d = _modelRims.slice();
          d.splice(indexToDelete, 1);
          _setModelRims(d);
          _notify('Model Rims updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  }
};

export { init, handleModelChange };
