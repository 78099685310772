/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new colors
 * We are using the _react-admin-color-input_ package to handle the colors.
 *
 */

/** ignore this comment */

import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

export const ColorCreate = (props: any): JSX.Element => (
  <Create title="Create a color" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <ColorInput source="hexadecimalValue" validate={required()} />
      <TextInput source="optionCode" validate={required()} />
    </SimpleForm>
  </Create>
);
