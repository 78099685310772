export enum UserRole {
  SUPER_ADMIN = 'PAG_SUPERADMIN',
  ADMIN = 'PAG_ADMIN',
  EDITOR_PUBLISHER = 'PAG_EDITOR_PUBLISHER',
  TRANSLATOR = 'PAG_TRANSLATOR',
  GUEST = 'GUEST'
}

export interface UserPermissions {
  role: UserRole;
}

export interface EventUser {
  id: string;
  eventId: string;
  resourceRole: string;
  userId: string;
}
