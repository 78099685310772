/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add News Items
 *
 * The news items are shows in the **Home** page of the CMS
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Create, DateInput, required, SimpleForm } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const NewsCreate = (props: any): JSX.Element => (
  <Create title="Create What’s New" {...props}>
    <SimpleForm>
      <DateInput source="releaseDate" validate={required()} />
      <RichTextInput source="description" validate={required()} />
    </SimpleForm>
  </Create>
);
