/**
 * **Hook**
 *
 * React hook used to abstract the retrieval of countries
 *
 */

/** ignore this comment */
import { useEffect, useState } from 'react';
import { GetListParams, useDataProvider } from 'react-admin';
import { ICountry, IUseCountry } from '../models/country.model';

const countriesParams: GetListParams = {
  pagination: { page: 1, perPage: 200 },
  sort: { field: 'name_eng', order: 'ASC' },
  filter: {}
};

export const useCountry = (): IUseCountry => {
  const dataProvider = useDataProvider();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [error, setError] = useState<string | any>(null);

  useEffect(() => {
    (async function fetchCountries() {
      try {
        const { data }: { data: ICountry[] } = await dataProvider.getList<ICountry>(
          'countryCodes',
          countriesParams
        );
        setCountries(data);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, []);

  return { countries, countryError: error };
};
