/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new Model Lines
 *
 */

/** ignore this comment */
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { Create, ImageField, ImageInput, required, SimpleForm, TextInput } from 'react-admin';

import { useFileUpload } from '../../hooks/useFileUpload';
import { UploadToolbar } from '../shared/UploadToolbar';

export const ModellineCreate = (props: any): JSX.Element => {
  const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();

  const fileParams = [{ key: 'thumbnailFileId', value: thumbnailFileId }];

  return (
    <Create title="Create a modelline" {...props}>
      <SimpleForm toolbar={<UploadToolbar {...props} fileparams={fileParams} />}>
        <TextInput source="name" validate={required()} />

        <TextInput source="co2" label="CO2 (g/km)" />

        <TextInput source="electric" label="Electric (kWh/100km)" />

        <TextInput source="fuel" label="Fuel (l/100km)" />

        {uploadError && <Alert severity="error">{uploadError}</Alert>}
        <ImageInput
          onChange={handleFileUpload}
          source="modellineCreatethumbnail"
          label="Thumbnail File Upload"
          accept="image/*"
          validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
