/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add a new Derivatives.
 * Because of the many to many relationships included, adding a derivative is a two step proces,
 * first in the create component the user adds the base attributes of the derivative and then on the
 * edit component they add those attributes related to additional entities.
 *
 */

/** ignore this comment */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Create,
  Error,
  FileField,
  FileInput,
  FormTab,
  GetListParams,
  ImageField,
  ImageInput,
  Loading,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  ResourceComponentPropsWithId,
  SelectInput,
  TabbedForm,
  TextInput,
  useDataProvider
} from 'react-admin';

import { useFileUpload } from '../../hooks/useFileUpload';
import { IModelline } from '../../models/derivatives.model';
import { JSONField } from '../shared/JSONField';
import { UploadToolbar } from '../shared/UploadToolbar';
import { fuelTypeChoices, FuelTypeModel } from '../../models/fuel-type.models';

const SanitizedFormControl = ({ basePath, ...props }: any) => {
  return <FormControl {...props} required />;
};

const modelLinesParams: GetListParams = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: 'name', order: 'ASC' },
  filter: {}
};

export const DerivativeCreate: FC<ResourceComponentPropsWithId> = (props): JSX.Element => {
  const [modellineId, setModellineId] = useState<string | undefined>('');
  const [modellineList, setModellineList] = useState<IModelline[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | any>();
  const dataProvider = useDataProvider();

  const {
    uploadError: thumbnailUploadError,
    fileId: thumbnailFileId,
    handleFileUpload: thumbnailHandleFileUpload
  } = useFileUpload();

  const {
    uploadError: funFactUploadError,
    fileId: funFactFileId,
    handleFileUpload: funFactHandleFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaDbXmlFileError,
    fileId: vuforiaDbXmlFileId,
    handleFileUpload: vuforiaDbXmlFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaDbDatFileError,
    fileId: vuforiaDbDatFileId,
    handleFileUpload: vuforiaDbDatFileUpload
  } = useFileUpload();

  const {
    uploadError: vuforiaStencilError,
    fileId: vuforiaStencilFileId,
    handleFileUpload: vuforiaStencilFileUpload
  } = useFileUpload();

  const {
    uploadError: silhouetteFileError,
    fileId: silhouetteFileId,
    handleFileUpload: silhouetteFileUpload
  } = useFileUpload();

  const derFileParams: { key: string; value: string | undefined }[] = [
    { key: 'thumbnailFileId', value: thumbnailFileId },
    { key: 'funfactImageFileId', value: funFactFileId },
    { key: 'vuforiaDbXmlFileId', value: vuforiaDbXmlFileId },
    { key: 'vuforiaDbDatFileId', value: vuforiaDbDatFileId },
    { key: 'vuforiaStencilFileId', value: vuforiaStencilFileId },
    { key: 'silhouetteFileId', value: silhouetteFileId }
  ];

  const handleModellineChange = (event: ChangeEvent<{ value: unknown }>) => {
    setModellineId(event.target.value as string);
  };
  let isCancelled = false;

  useEffect(() => {
    dataProvider
      .getList('modellines', modelLinesParams)
      .then((result) => {
        if (isCancelled) {
          return;
        }
        console.log('multiline: result', result);
        const ml = result.data.map((r: any) => {
          return {
            id: r.id,
            name: r.name
          };
        });

        setModellineList(ml);
        setLoading(false);
      })
      .catch((error) => {
        if (!isCancelled) {
          setError(error);
          setLoading(false);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, [dataProvider]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Create title="Create Derivative" {...props}>
      <TabbedForm redirect="edit" toolbar={<UploadToolbar {...props} fileparams={derFileParams} />}>
        <FormTab label="summary">
          <SanitizedFormControl>
            <InputLabel>Model Line</InputLabel>
            <Select onChange={handleModellineChange}>
              {modellineList.map((ml: IModelline) => (
                <MenuItem value={ml.id} key={ml.id}>
                  {ml.name}
                </MenuItem>
              ))}
            </Select>
          </SanitizedFormControl>
          {modellineId === '' ? (
            <SanitizedFormControl>
              <InputLabel>Sub Model</InputLabel>
              <Select disabled></Select>
            </SanitizedFormControl>
          ) : (
            <ReferenceInput
              label="Sub Model"
              source="submodelId"
              reference="submodels"
              filter={{ modellineId }}
              disabled={modellineId === ''}
              validate={required()}
              sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}

          <TextInput source="name" disabled={modellineId === ''} validate={required()} />
          <RadioButtonGroupInput
            source="fuelType"
            defaultValue={FuelTypeModel.Unknown}
            choices={fuelTypeChoices}
            style={{ textTransform: 'capitalize' }}
          />
          <TextInput source="orderType" disabled={modellineId === ''} validate={required()} />
          <TextInput source="assetBundleName" disabled={modellineId === ''} validate={required()} />
          <TextInput source="scene" disabled={modellineId === ''} validate={required()} />
          <NumberInput
            source="bundleVersionNo"
            disabled={modellineId === ''}
            validate={required()}
          />
          <TextInput source="engineId" disabled={modellineId === ''} label="Engine ID" />
          <JSONField source="techParts" label="Tech parts" height={150} />
          <TextInput source="techPartsPreFix" disabled={modellineId === ''} />

          {thumbnailUploadError && <Alert severity="error">{thumbnailUploadError}</Alert>}
          <ImageInput
            onChange={thumbnailHandleFileUpload}
            source="createDerThumbnail"
            label="Thumbnail file"
            accept="image/*"
            validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>

          {funFactUploadError && <Alert severity="error">{funFactUploadError}</Alert>}
          <ImageInput
            onChange={funFactHandleFileUpload}
            source="createDerFF"
            label="Fun fact image"
            accept="image/*"
            validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="thumbnailFunfactDescription" />
        </FormTab>

        <FormTab label="Vuforia">
          {vuforiaDbXmlFileError && <Alert severity="error">{vuforiaDbXmlFileError}</Alert>}
          <FileInput
            onChange={vuforiaDbXmlFileUpload}
            source="createDervuforiaDbXmlFile"
            label="Vuforia db xml file Upload"
            accept=".xml"
            validate={required()}>
            <FileField source="src" title="title" />
          </FileInput>

          {vuforiaDbDatFileError && <Alert severity="error">{vuforiaDbDatFileError}</Alert>}
          <FileInput
            onChange={vuforiaDbDatFileUpload}
            source="createDerVuforiaDbDatFile"
            label="Vuforia db dat file Upload"
            accept=".dat"
            validate={required()}>
            <FileField source="src" title="title" />
          </FileInput>

          {vuforiaStencilError && <Alert severity="error">{vuforiaStencilError}</Alert>}
          <ImageInput
            onChange={vuforiaStencilFileUpload}
            source="createDerVuforiaStencilFile"
            label="Vuforia stencil file Upload"
            accept="image/*"
            validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>

          {silhouetteFileError && <Alert severity="error">{silhouetteFileError}</Alert>}
          <ImageInput
            onChange={silhouetteFileUpload}
            source="createDerVuforiaSilhouetteFile"
            label="Silhouette file Upload"
            accept="image/*"
            validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
