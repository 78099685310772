/**
 * **Component**
 *
 * This component presents the user filed to edit some general properties of a market of event
 * as well as to manage all the language translations of the corresponding legal texts.
 */

/** ignore this comment */
import ContentSave from '@material-ui/icons/Save';
import React, { ChangeEvent, useState, useEffect } from 'react';
import Download from '@material-ui/icons/GetApp';
import Upload from '@material-ui/icons/Publish';
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { Button, TextInput, useNotify, RadioButtonGroupInput } from 'react-admin';
import { Form } from 'react-final-form';

import ExcelJS, { WorksheetProtection } from 'exceljs';

import { IEvent } from '../../../models/event.model';
import { Area, areaService } from '../../../areaService';
import { unitSystemChoices, UnitSystemModel } from '../../../models/unit-system.model';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

interface TranslationRow {
  name: string;
  english_text: string;
  translation: string;
}

const DOWNLOAD_EXCEL = gql`
  mutation dwlexc($language: String!, $event_id: String!) {
    requestDownloadLegalTextExcel(input: { language: $language, event_id: $event_id }) {
      status
      data {
        english_text
        name
        translation
      }
    }
  }
`;

const UPLOAD_EXCEL = gql`
  mutation uplexc($language: String!, $event_id: String!, $dataRows: [TranslationRowInput]) {
    requestUploadLegalTextExcel(
      input: { language: $language, event_id: $event_id, dataRows: $dataRows }
    ) {
      status
    }
  }
`;

const useStyles = makeStyles({
  langTable: {
    marginLeft: '0px'
  }
});

export const MetadataForm = (props: any): JSX.Element => {
  const { record, languages, onSubmit } = props;
  const [downloadExcelMutation] = useMutation(DOWNLOAD_EXCEL);
  const [uploadExcelMutation] = useMutation(UPLOAD_EXCEL);
  const [recordNew, setRecord] = useState<IEvent | null>(record);
  const [area, setArea] = useState<Area>('other');
  const classes = useStyles();

  const defaultIcon = <ContentSave />;

  const handleOnChange = (event: ChangeEvent<{ value: string; id: string }>) => {
    if (event.target.id === 'manualUrl') record.manualUrl = event.target.value;
    else if (event.target.id === 'contactUrl') record.contactUrl = event.target.value;
    setRecord(record);
  };

  useEffect(() => {
    const subscription = areaService.areaChanged().subscribe((_area) => {
      setArea(_area);
    });
  }, [props]);

  const handleOnChangeRadio = (e: React.FormEvent<HTMLDivElement>) => {
    record.unitSystem = e;
    setRecord(record);
  };
  const notify = useNotify();

  const downloadExcel = async (
    event: any,
    language: { languageId: string; code: string; name: string }
  ) => {
    try {
      const lockStr = 'kjghrtergr47!1d53e';
      const downloadDoc = await downloadExcelMutation({
        variables: { language: language.languageId, event_id: event.id }
      });
      const rows = downloadDoc?.data?.requestDownloadLegalTextExcel?.data;
      const nbRows: number = rows.length;
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet(event.code);
      const optionsProtections: Partial<WorksheetProtection> = {
        formatColumns: true,
        insertColumns: false,
        insertRows: false,
        deleteColumns: false,
        deleteRows: false
      };
      sheet.properties.defaultColWidth = 80;
      sheet.getRow(1).font = { bold: true, size: 12 };
      sheet.getColumn(1).font = { bold: true, size: 12 };
      sheet.getColumn(1).header = 'Phrase ID';
      sheet.getColumn(1).width = 50;
      sheet.getColumn(2).header = 'English text (fallback)';
      sheet.getColumn(2).width = 50;
      sheet.getColumn(2).alignment = { wrapText: true };
      sheet.getColumn(3).header = 'Translation-' + event.name + ' - ' + language.name;
      sheet.getColumn(3).width = 50;
      sheet.getColumn(3).alignment = { wrapText: true };
      sheet.addConditionalFormatting({
        ref: 'C1:C' + (nbRows + 1),
        rules: [
          {
            type: 'expression',
            priority: 1,
            formulae: ['=ISBLANK(C1)'],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00000000' },
                bgColor: { argb: 'FFFF0000' }
              }
            }
          }
        ]
      });
      rows?.map((x: TranslationRow) => sheet.addRow([x.name, x.english_text, x.translation]));
      await sheet.protect(lockStr, optionsProtections);
      sheet.getColumn(2).protection = { locked: false };
      sheet.getColumn(3).protection = { locked: false };
      const buf = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const newDate = new Date();
      saveAs(
        blob,
        newDate.toLocaleDateString() +
          '_porsche_cms_translation-' +
          event.name +
          '_' +
          language.code +
          '.xlsx'
      );
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  function readFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (res: any) => {
        resolve(res.target.result);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(file);
    });
  }

  const uploadExcel = async (domEvent: any, event: any, language: string) => {
    try {
      const selectedFile = domEvent.target.files[0];
      const workbook = new ExcelJS.Workbook();

      const buffer = await readFile(selectedFile);
      await workbook.xlsx.load(buffer as Buffer);

      const resultExcel: TranslationRow[] = [];

      workbook.eachSheet((sheet) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex > 1) {
            const transRow: TranslationRow = {
              name: row.getCell(1).value?.toString() ?? '',
              english_text: row.getCell(2).value?.toString() ?? '',
              translation: row.getCell(3).value?.toString() ?? ''
            };
            resultExcel.push(transRow);
          }
        });
      });

      const result = await uploadExcelMutation({
        variables: { language, event_id: event.id, dataRows: resultExcel }
      });
      const fileInput = document.getElementById('file_' + language) as HTMLInputElement;
      fileInput.value = '';
      if (result.data?.requestUploadLegalTextExcel.status === false) {
        notify(
          'Unable to upload the translations please contact the system administrator',
          'error'
        );
      } else {
        notify('Document uploaded for ' + event.name);
      }
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  const hiddenStyle: CSSProperties = {
    visibility: 'hidden'
  };

  const openFileDialog = (langId: string) => {
    const fileInput = document.getElementById('file_' + langId);
    fileInput?.click();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={record}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="stretch">
            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '50%' }}>
              <Table className={classes.langTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" gutterBottom>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" gutterBottom>
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" gutterBottom>
                        Download
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" gutterBottom>
                        Upload
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {languages?.map((lang: any) => (
                    <TableRow key={lang.id}>
                      <TableCell>{lang.name}</TableCell>
                      <TableCell>{lang.code}</TableCell>
                      <TableCell>
                        <Button color="primary" onClick={() => downloadExcel(record, lang)}>
                          <Download />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button color="primary" onClick={() => openFileDialog(lang.languageId)}>
                          <Upload />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <input
                          hidden
                          style={hiddenStyle}
                          type="file"
                          id={`file_${lang?.languageId}`}
                          onChange={(e) => uploadExcel(e, record, lang.languageId)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '50%' }}>
              <Box style={{ width: '80%' }}>
                <Typography variant="h6" gutterBottom>
                  {area === 'event' ? 'Manual URL' : 'Dealer Search Url'}
                </Typography>
                <TextInput
                  source="manualUrl"
                  fullWidth
                  onChange={handleOnChange}
                  label=""
                  hiddenLabel
                />
              </Box>
              <Box style={{ width: '80%' }}>
                <Typography variant="h6" gutterBottom>
                  {area === 'event' ? 'Contact URL' : 'Dealer Contact Url'}
                </Typography>
                <TextInput
                  source="contactUrl"
                  fullWidth
                  onChange={handleOnChange}
                  label=""
                  hiddenLabel
                />
              </Box>
              <Box style={{ width: '80%' }}>
                <Typography variant="h6" gutterBottom>
                  Unit System
                </Typography>
                <RadioButtonGroupInput
                  onChange={handleOnChangeRadio}
                  source="unitSystem"
                  defaultValue={UnitSystemModel.Metric}
                  choices={unitSystemChoices}
                  style={{ textTransform: 'capitalize' }}
                  label=""
                  hiddenLabel
                />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button
                disabled={submitting || pristine}
                type="submit"
                label="save"
                variant="contained"
                color={submitting ? 'default' : 'primary'}>
                {defaultIcon}
              </Button>
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};
