/**
 * **Component**
 *
 * Component based on React Admin **Create** component to manage existing Model Lines
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import {
  DateField,
  Edit,
  ImageField,
  ImageInput,
  ReferenceField,
  required,
  SimpleForm,
  TextInput
} from 'react-admin';

import { useFileUpload } from '../../hooks/useFileUpload';
import { ReferenceImageField } from '../shared/ReferenceImageField';
import { UploadToolbar } from '../shared/UploadToolbar';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const ModellineEdit = (props: any) => {
  const classes = useStyles();
  const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();

  const fileParams = [{ key: 'thumbnailFileId', value: thumbnailFileId }];

  return (
    <Edit title="Edit Modelline" {...props}>
      <SimpleForm toolbar={<UploadToolbar {...props} fileparams={fileParams} />} redirect={false}>
        <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
        <TextInput source="name" validate={required()} />

        <h6>NEFZ</h6>
        <TextInput source="co2" label="CO2 (g/km)" />
        <TextInput source="electric" label="Electric (kWh/100km)" />
        <TextInput source="fuel" label="Fuel (l/100km)" />

        <h6>WLTP</h6>
        <TextInput source="wltpCo2" label="CO2 (g/km)" />
        <TextInput source="wltpElectric" label="Electric (kWh/100km)" />
        <TextInput source="wltpFuel" label="Fuel (l/100km)" />
        <TextInput source="wltpDistance" label="Distance (km)" />

        <ReferenceField source="thumbnailFileId" reference="files" link={false}>
          <ReferenceImageField source="file" maxWidth="250px" />
        </ReferenceField>
        {uploadError && <Alert severity="error">{uploadError}</Alert>}
        <ImageInput
          onChange={handleFileUpload}
          source="editModel"
          label="Thumbnail File Upload"
          accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
