/**
 * **Component**
 *
 * Component based on React Admin **List** component to show all existing News Items
 * and to offer the possibiltiy to create new ones.
 *
 * The news items are shows in the **Home** page of the CMS
 *
 */

/** ignore this comment */
import { Fragment } from 'react';
import { checkRolePermission } from '../../utils/permissionHelper';
import { UserRole } from '../../models/user-permissions.model';
import { BulkDeleteButton, Datagrid, DateField, EditButton, List } from 'react-admin';
import * as React from 'react';

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const NewsList = (props: any) => (
  <List
    {...props}
    title="What’s New"
    exporter={false}
    sort={{ field: 'releaseDate', order: 'DESC' }}
    bulkActionButtons={
      checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) ? (
        <ListBulkActionButtons {...props} />
      ) : (
        false
      )
    }>
    <Datagrid>
      <DateField
        locales="en-UK"
        source="releaseDate"
        options={{ year: 'numeric', month: 'long', day: 'numeric' }}
      />
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) && (
        <EditButton label="Edit" />
      )}
    </Datagrid>
  </List>
);
