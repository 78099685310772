/**
 * **Component**
 *
 * This module handle Authetification
 * see https://marmelab.com/react-admin/Authentication.html
 *
 * We are using a JWT to handle the authentication process
 * with both an accessToken and a refreshToken
 *
 */

/** ignore this comment */

import { print } from 'graphql';
import gql from 'graphql-tag';

import { UserPermissions, UserRole } from './models/user-permissions.model';
import { convertStrToUserRole } from './utils/permissionHelper';

interface LoginParams {
  username: string;
  password: string;
}

const mutationAuthenticate = gql`
  mutation login($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      authResult {
        userId
        accessToken
        refreshToken
      }
    }
  }
`;
const mutationRefreshAccesstoken = gql`
  mutation refreshAccessToken($userId: UUID!, $refreshToken: String!) {
    refreshAccessToken(input: { userId: $userId, refreshToken: $refreshToken }) {
      authResult {
        userId
        accessToken
        refreshToken
      }
    }
  }
`;

export default {
  // authentication
  login: async (params: LoginParams): Promise<void> => {
    const authResult = await graphqlLogin(params.username, params.password);
    const jwt = parseJwt(authResult.accessToken);
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('refreshToken', authResult.refreshToken);
    localStorage.setItem('userId', authResult.userId);
    localStorage.setItem('accessTokenExp', jwt.exp);
    localStorage.setItem('role', jwt?.role);
  },
  checkError: async (error: any) => {
    console.log('ERROR');
    console.log('error', JSON.stringify(error, null, 2));
    await refreshTokenIfNeeded();
  },
  refreshTokenIfNeeded,
  checkAuth: function () {
    if (localStorage.getItem('accessToken')) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: (): Promise<void> => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExp');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    return Promise.resolve();
  },
  // getIdentity: async (error: any) => {
  //   console.log('error', JSON.stringify(error, null, 2));
  //   return {
  //     id: '1',
  //     fullName: 'not real',
  //     avatar:
  //       'https://lh3.googleusercontent.com/proxy/Yz1m9x2FlVY2VcHzqlq4C6MkpbxbkxiOJtf93r6Zwxg-cNTkvCeQKKcnhaGzq0YUTYDAFuoBtVVfuafd61QKD5Skdo0t_F1EInY6FHQvCHwgIlm3zWTk6w'
  //   };
  // },
  // authorization TODO wait for
  // https://github.com/marmelab/react-admin/pull/5381/files
  //
  getPermissions: async (): Promise<UserPermissions> => {
    const roleStr = localStorage.getItem('role')?.toUpperCase();
    if (!roleStr) {
      return { role: UserRole.GUEST };
    }
    const role = convertStrToUserRole(roleStr);
    if (!role) {
      return { role: UserRole.GUEST };
    }
    return { role };
  },
  getCurrentUserId: (): string => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      throw new Error('The user is not logged in');
    }
    return userId;
  }
};
async function refreshTokenIfNeeded(): Promise<void> {
  try {
    const exp = parseInt(localStorage.getItem('accessTokenExp') || '0');
    const accessToken = localStorage.getItem('accessToken');
    const role = localStorage.getItem('role');
    if (accessToken && exp >= Math.floor(new Date().getTime() / 1000) && role) {
      return;
    }
    const refreshToken = localStorage.getItem('refreshToken');
    const userId = localStorage.getItem('userId');
    if (refreshToken && userId && role) {
      const authResult = await graphqlRefreshAccessToken(userId, refreshToken);
      const jwt = parseJwt(authResult.accessToken);
      localStorage.setItem('accessToken', authResult.accessToken);
      localStorage.setItem('refreshToken', authResult.refreshToken);
      localStorage.setItem('userId', authResult.userId);
      localStorage.setItem('accessTokenExp', jwt.exp);
      localStorage.setItem('role', jwt.role);
      return;
    }
    throw Error('no auth credetials set!');
  } catch (e) {
    console.error(e);
    throw Error('Session expired! Please login!');
  }
}

async function graphqlLogin(email: string, password: string) {
  const operations = {
    operationName: 'login',
    variables: { email, password },
    query: print(mutationAuthenticate) // get formatted query string from gql
  };

  const response = await fetch(process.env.REACT_APP_GQL_HOST!, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(operations)
  });

  const data = await response.json();
  const authResult = data?.data?.authenticate?.authResult;
  if (!authResult) {
    throw Error('Invalid email/password!');
  }
  return authResult;
}

async function graphqlRefreshAccessToken(userId: string, refreshToken: string) {
  const operations = {
    operationName: 'refreshAccessToken',
    variables: { userId, refreshToken },
    query: print(mutationRefreshAccesstoken) // get formatted query string from gql
  };

  // try {
  const response = await fetch(process.env.REACT_APP_GQL_HOST!, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(operations)
  });

  const data = await response.json();
  const authResult = data?.data?.refreshAccessToken?.authResult;
  if (!authResult) {
    throw Error('Invalid email/password!');
  }
  return authResult;
}
function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
