/**
 * **Component**
 *
 * This is the top component of the application.
 * It instantiates the DataProvider, sets up all the ReactAdmin resources.
 *
 */

/** ignore this comment */
import {
  ApolloClient,
  ApolloProvider,
  concat,
  createHttpLink,
  InMemoryCache,
  useApolloClient
} from '@apollo/client';
import { Admin } from '@react-admin/ra-enterprise';
import pgDataProvider from 'ra-postgraphile';
import React, { useEffect, useState } from 'react';
import { Logout, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './AuthProvider';
import { CMSLayout } from './components/main/CMSLayout';
import LoginPage from './components/auth/LoginPage';
import { ColorCreate } from './components/colors/Create';
import { ColorEdit } from './components/colors/Edit';
import { ColorList } from './components/colors/List';
import { DerivativeCreate } from './components/derivatives/Create';
import { DerivativeEdit } from './components/derivatives/Edit';
import { DerivativeList } from './components/derivatives/List';
import { EventCreate } from './components/events/Create';
import { EventEdit } from './components/events/Edit';
import { EventList } from './components/events/List';
import { FunFactCreate } from './components/funfacts/Create';
import { FunFactEdit } from './components/funfacts/Edit';
import { FunFactList } from './components/funfacts/List';
import { LanguageCreate } from './components/languages/Create';
import { LanguageEdit } from './components/languages/Edit';
import { LanguageList } from './components/languages/List';
import { ModellineCreate } from './components/modellines/Create';
import { ModellineEdit } from './components/modellines/Edit';
import { ModellineList } from './components/modellines/List';
import { PhraseCreate } from './components/phraseIds/Create';
import { PhraseEdit } from './components/phraseIds/Edit';
import { PhraseList } from './components/phraseIds/List';
import { RimCreate } from './components/rims/Create';
import { RimEdit } from './components/rims/Edit';
import { RimList } from './components/rims/List';
import { SubmodelCreate } from './components/submodels/Create';
import { SubmodelEdit } from './components/submodels/Edit';
import { SubmodelList } from './components/submodels/List';
import { UserAccountCreate } from './components/userAccount/Create';
import { UserAccountEdit } from './components/userAccount/Edit';
import { UserAccountList } from './components/userAccount/List';
import { NewsList } from './components/news/List';
import { NewsEdit } from './components/news/Edit';
import { NewsCreate } from './components/news/Create';
import { routes } from './customRoutes';
import { Dashboard } from './dashboard';
import Menu from './Menu';
import { UserRole } from './models/user-permissions.model';
import { checkRolePermission } from './utils/permissionHelper';
import { setContext } from '@apollo/client/link/context';
import { SVGIcon } from './components/shared/SVGIcon';
import englishMessages from 'ra-language-english';

const englishCustomMessages = englishMessages;
englishCustomMessages.ra.message.invalid_form = `The page can't be saved. Please fill out all required fields.`;

const i18nProvider = polyglotI18nProvider(
  () => englishMessages,
  'en' // Default locale
);
const authMiddleware = setContext(async () => {
  try {
    await authProvider.refreshTokenIfNeeded();
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      };
    }
  } catch (e) {
    console.log('APOLLO ERROR:', e);
  }
});
import { CMSLightTheme } from './theme';

const logoutIcon = <SVGIcon name="logout" width={28} />;
const PLogoutButton = (props: any) => <Logout {...props} icon={logoutIcon} />;

const ReactAdminWrapper = (): JSX.Element | null => {
  const [dataProvider, setDataProvider] = useState(null);
  const client = useApolloClient() as any;

  useEffect(() => {
    (async () => {
      const dataProvider = await pgDataProvider(client);
      setDataProvider(() => dataProvider as any);
    })();
  }, [client]);

  return (
    dataProvider && (
      <Admin
        locale="en"
        title="Porsche AR Visualizer CMS"
        i18nProvider={i18nProvider}
        menu={Menu}
        dashboard={Dashboard}
        customRoutes={routes}
        dataProvider={dataProvider as any}
        authProvider={authProvider as any}
        layout={CMSLayout}
        lightTheme={CMSLightTheme}
        logoutButton={PLogoutButton}
        loginPage={LoginPage}>
        {(permissions) => [
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="modellines"
              list={ModellineList}
              edit={ModellineEdit}
              create={ModellineCreate}
              options={{ label: 'Model Lines' }}
              key="modellines"
            />
          ) : checkRolePermission(permissions, [UserRole.ADMIN]) ? (
            <Resource
              name="modellines"
              list={ModellineList}
              options={{ label: 'Model Lines' }}
              key="modellines"
            />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="submodels"
              list={SubmodelList}
              edit={SubmodelEdit}
              create={SubmodelCreate}
              options={{ label: 'Sub Models' }}
              key="submodels"
            />
          ) : checkRolePermission(permissions, [UserRole.ADMIN]) ? (
            <Resource
              name="submodels"
              list={SubmodelList}
              options={{ label: 'Sub Models' }}
              key="submodels"
            />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="derivatives"
              list={DerivativeList}
              edit={DerivativeEdit}
              create={DerivativeCreate}
              options={{ label: 'Derivatives' }}
              key="derivatives"
            />
          ) : checkRolePermission(permissions, [UserRole.ADMIN]) ? (
            <Resource
              name="derivatives"
              list={DerivativeList}
              options={{ label: 'Derivatives' }}
              key="derivatives"
            />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="colors"
              list={ColorList}
              edit={ColorEdit}
              create={ColorCreate}
              options={{ label: 'Ext. Colors' }}
              key="colors1"
            />
          ) : checkRolePermission(permissions, [UserRole.ADMIN]) ? (
            <Resource
              name="colors"
              list={ColorList}
              options={{ label: 'Ext. Colors' }}
              key="colors2"
            />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="rims"
              list={RimList}
              edit={RimEdit}
              create={RimCreate}
              key="rims"
              options={{ label: 'Rims' }}
            />
          ) : checkRolePermission(permissions, [UserRole.ADMIN]) ? (
            <Resource name="rims" list={RimList} key="rims" options={{ label: 'Rims' }} />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="funFacts"
              list={FunFactList}
              edit={FunFactEdit}
              create={FunFactCreate}
              options={{ label: 'Fun Facts' }}
              key="funFacts"
            />
          ) : checkRolePermission(permissions, [UserRole.ADMIN]) ? (
            <Resource
              name="funFacts"
              list={FunFactList}
              options={{ label: 'Fun Facts' }}
              key="funFacts"
            />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) ? (
            <Resource
              name="events"
              create={EventCreate}
              list={EventList}
              edit={EventEdit}
              options={{ label: 'Events' }}
              key="events"
            />
          ) : checkRolePermission(permissions, [UserRole.EDITOR_PUBLISHER]) ? (
            <Resource name="events" list={EventList} options={{ label: 'Events' }} key="events" />
          ) : null,
          checkRolePermission(permissions, [
            UserRole.SUPER_ADMIN,
            UserRole.ADMIN,
            UserRole.TRANSLATOR
          ]) ? (
            <Resource
              name="languages"
              list={LanguageList}
              edit={LanguageEdit}
              create={LanguageCreate}
              key="languages"
              options={{ label: 'Translations' }}
            />
          ) : null,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN]) ? (
            <Resource
              name="globalPhrases"
              list={PhraseList}
              edit={PhraseEdit}
              create={PhraseCreate}
              key="globalPhrases"
              options={{ label: 'Phrase IDs' }}
            />
          ) : null,
          <Resource name="translations" key="translations" />,
          <Resource name="derColors" key="derColors" />,
          <Resource name="derRims" key="derRims" />,
          <Resource name="modellineRims" key="modellineRims" />,
          <Resource name="files" key="files" />,
          <Resource name="derInfoEvents" key="derInfoEvents" />,
          <Resource name="modelInfoEvents" key="modelInfoEvents" />,
          <Resource name="submodelInfoEvents" key="submodelInfoEvents" />,
          <Resource name="derColorEvents" key="derColorEvents" />,
          <Resource name="derRimEvents" key="derRimEvents" />,
          <Resource name="eventLanguage" key="eventLanguage" />,
          <Resource name="eventImageRecognitions" key="eventImageRecognitions" />,
          <Resource name="eventUser" key="eventUser" />,
          <Resource name="countryCodes" key="countryCodes" />,
          <Resource name="eventCountryCodes" key="eventCountryCodes" />,
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) ? (
            <Resource
              name="newsItems"
              list={NewsList}
              edit={NewsEdit}
              create={NewsCreate}
              key="newsItems"
              options={{ label: 'What’s New' }}
            />
          ) : (
            <Resource name="news" key="news" />
          ),
          checkRolePermission(permissions, [UserRole.SUPER_ADMIN, UserRole.ADMIN]) ? (
            <Resource
              name="userAccount"
              create={UserAccountCreate}
              edit={UserAccountEdit}
              list={UserAccountList}
              options={{ label: 'Users' }}
              key="userAccount"
            />
          ) : null
        ]}
      </Admin>
    )
  );
};

const apolloClient = new ApolloClient({
  link: concat(
    authMiddleware,
    createHttpLink({
      uri: process.env.REACT_APP_GQL_HOST
    })
  ),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const App = (): JSX.Element => {
  console.log('host', process.env.REACT_APP_GQL_HOST);
  return (
    <ApolloProvider client={apolloClient}>
      <ReactAdminWrapper />
    </ApolloProvider>
  );
};

export default App;
