/**
 * **Helper Methods**
 *
 * Collection of helper methods use to abstract reusable functionality related to roles and permissions
 *
 */

/** ignore this comment */

import { UserPermissions, UserRole } from '../models/user-permissions.model';

export const checkRolePermission = function (
  permissions: UserPermissions | null,
  roles: Array<UserRole>
): boolean {
  if (!permissions) {
    return false;
  }
  return roles.includes(permissions.role);
};

export const convertStrToUserRole = function (roleStr: string): UserRole | null {
  return roleStr === UserRole.SUPER_ADMIN ||
    roleStr === UserRole.ADMIN ||
    roleStr === UserRole.EDITOR_PUBLISHER ||
    roleStr === UserRole.TRANSLATOR
    ? roleStr
    : null;
};
