/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to manage Languages
 *
 * Please note that in the CMS this components are found under the menu point **Translations**
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';

export const LanguageEdit = (props: any) => {
  return (
    <Edit title="Edit Language" {...props}>
      <SimpleForm redirect={false}>
        <TextInput source="name" validate={required()} />
        <TextInput source="code" validate={required()} />
        <TextInput source="internationalCode" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
