/**
 * **Service**
 *
 * The **EditorEventDerivative** component delegate the communication with the GraphQL backend
 * to this servive.
 *
 * It is very important to first call the **init(params: IEditEventDerServiceParams)**  method
 * before making any other call.
 */

/** ignore this comment */

import { Dispatch, SetStateAction } from 'react';
import { DataProviderProxy } from 'react-admin';

import {
  IDerColor,
  IDerColorEvent,
  IDerFunFactEvent,
  IDerInfoEvent,
  IDerRim,
  IDerRimEvent,
  IEditEventDerServiceParams,
  IFunFact
} from '../../../models/derivatives.model';

let _derivativeId: string;
let _eventId: string;
let _dataProvider: DataProviderProxy;
let _derColorEvents: IDerColorEvent[];
let _setDerColorEvents: Dispatch<SetStateAction<IDerColorEvent[]>>;
let _derRimEvents: IDerRimEvent[];
let _setDerRimEvents: Dispatch<SetStateAction<IDerRimEvent[]>>;
let _setDerInfoEvent: Dispatch<SetStateAction<IDerInfoEvent | null>>;
let _derFunFactEvents: IDerFunFactEvent[];
let _setDerFunFactEvents: Dispatch<SetStateAction<IDerFunFactEvent[]>>;
let _notify: (
  message: string,
  type?: 'info' | 'warning' | 'error' | undefined,
  messageArgs?: any,
  undoable?: boolean | undefined,
  autoHideDuration?: number | undefined
) => void;

function init(params: IEditEventDerServiceParams) {
  _derivativeId = params.derivativeId;
  _eventId = params.eventId;
  _dataProvider = params.dataProvider;
  _derColorEvents = params.derColorEvents;
  _setDerColorEvents = params.setDerColorEvents;
  _derRimEvents = params.derRimEvents;
  _setDerRimEvents = params.setDerRimEvents;
  _setDerInfoEvent = params.setDerInfoEvent;
  _derFunFactEvents = params.derFunFactEvents;
  _setDerFunFactEvents = params.setDerFunFactEvents;
  _notify = params.notify;
}

const handleColorChange = (choice: IDerColor, checked: boolean) => {
  if (!checked) {
    _dataProvider.create(
      'derColorEvents',
      {
        data: {
          eventId: _eventId,
          derColorId: choice.id
        }
      },
      {
        onSuccess: (result: { data: IDerColorEvent }) => {
          console.log('onsuccess', result);

          _setDerColorEvents((derColorEvents) => {
            const d = derColorEvents.slice();
            d.push(result.data);
            return d;
          });
          _notify('Event Derivative Colors updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  } else {
    const toDelete = _derColorEvents.find((derColor) => {
      return derColor.derColorId === choice.id;
    });
    _dataProvider.delete(
      'derColorEvents',
      {
        id: toDelete!.id,
        previousData: toDelete!
      },
      {
        onSuccess: ({ data }: { data: IDerColorEvent }) => {
          const indextToDelete = _derColorEvents.findIndex((derColor) => {
            return derColor.id === data.id;
          });

          _setDerColorEvents((derColorEvents) => {
            const d = derColorEvents.slice();
            d.splice(indextToDelete, 1);
            return d;
          });
          _notify('Event Derivative Colors updated correctly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  }
};

const handleRimChange = (choice: IDerRim, checked: boolean) => {
  if (!checked) {
    _dataProvider.create(
      'derRimEvents',
      {
        data: {
          eventId: _eventId,
          derRimId: choice.id
        }
      },
      {
        onSuccess: (result: { data: IDerRimEvent }) => {
          _setDerRimEvents((derRimEvents) => {
            const d = derRimEvents.slice();
            d.push(result.data);
            return d;
          });
          _notify('Evnet Derivative Rims updated currectly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  } else {
    const toDelete = _derRimEvents.find((derRim) => {
      return derRim.derRimId === choice.id;
    });
    _dataProvider.delete(
      'derRimEvents',
      {
        id: toDelete!.id,
        previousData: toDelete!
      },
      {
        onSuccess: ({ data }: { data: IDerRimEvent }) => {
          const indextToDelete = _derRimEvents.findIndex((derRim) => {
            return derRim.id === data.id;
          });

          _setDerRimEvents((derRimEvents) => {
            const d = derRimEvents.slice();
            d.splice(indextToDelete, 1);
            return d;
          });
          _notify('Event Derivative Rims updated currectly');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  }
};

const handleUpdateDerInfoEvent = (record: IDerInfoEvent, previousRecord: IDerInfoEvent | null) => {
  _dataProvider.update(
    'derInfoEvents',
    {
      id: record.id,
      data: {
        co2: record.co2,
        electric: record.electric,
        fuel: record.fuel,
        thumbnailFileId: record.thumbnailFileId,
        funFactFileId: record.funFactFileId,
        wltpFuel: record.wltpFuel,
        wltpElectric: record.wltpElectric,
        wltpCo2: record.wltpCo2,
        wltpDistance: record.wltpDistance
      },
      previousData: previousRecord!
    },
    {
      onSuccess: ({ data }: { data: IDerInfoEvent }) => {
        _setDerInfoEvent(data);
        _notify('Event Derivative Info updated currectly');
      },
      onFailure: (error: any) => {
        console.error('error', error);
        _notify(error, 'error');
      }
    }
  );
};

const handleUpdateDerFunFactEvent = (choice: IFunFact, checked: boolean) => {
  if (!checked) {
    _dataProvider.create(
      'derFunFactEvents',
      {
        data: {
          derivativeId: _derivativeId,
          eventId: _eventId,
          funFactId: choice.id
        }
      },
      {
        onSuccess: ({ data }: { data: IDerFunFactEvent }) => {
          _setDerFunFactEvents((derFunFactEvents) => {
            const d = derFunFactEvents.slice();
            d.push(data);
            return d;
          });
          _notify('Event Derivative Fun Fact updated');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  } else {
    const toDelete = _derFunFactEvents.find((derFF) => {
      return derFF.funFactId === choice.id;
    });
    _dataProvider.delete(
      'derFunFactEvents',
      {
        id: toDelete!.id,
        previousData: toDelete!
      },
      {
        onSuccess: ({ data }: { data: IDerFunFactEvent }) => {
          const indexToDelte = _derFunFactEvents.findIndex((derFF) => {
            return derFF.id === data.id;
          });

          _setDerFunFactEvents((derFunFactEvents) => {
            const d = derFunFactEvents.slice();
            d.splice(indexToDelte, 1);
            return d;
          });
          _notify('Event Derivative Fun Fact updated');
        },
        onFailure: (error: any) => {
          console.error('error', error);
          _notify(error, 'error');
        }
      }
    );
  }
};

export {
  init,
  handleColorChange,
  handleRimChange,
  handleUpdateDerInfoEvent,
  handleUpdateDerFunFactEvent
};
