/**
 * **Component**
 *
 * Static component with impring and other legal information.
 *
 */

/** ignore this comment */
import React from 'react';
import Typography from '@material-ui/core/Typography';

export const LegalComponent = () => (
  <Typography component="div">
    <div>
      <h2>Legal</h2>
      <p>
        <strong>Dr. Ing. h.c. F. Porsche AG </strong>
        <br /> Porsche platz 1
        <br /> D-70435 Stuttgart
      </p>

      <p>
        E-Mail: <a href="mailto:info@porsche.de">info@porsche.de</a>
        <br />
        Tel: <a href="tel:(+49)07119110">(+49) 0711 911-0</a>
      </p>

      <p>
        Represented by the Executive Board:
        <br />
        Oliver Blume, Chairperson
        <br />
        Lutz Meschke, Deputy Chairperson <br />
        Andreas Haffner
        <br />
        Detlev von Platen
        <br />
        Albrecht Reimold
        <br />
        Uwe-Karsten Städter
        <br />
        Michael Steiner
      </p>

      <p>
        Registry court: District Court Stuttgart
        <br />
        HRB no. 730623
        <br />
        VAT ID No. DE 147 799 625
      </p>
      <p>
        <strong>Consumer information in accordance with Regulation EU No. 524/2013 </strong>
      </p>
      <p>
        The European Commission provides an Online Dispute Resolution (ODR) platform. The platform
        can be found at{' '}
        <a
          href="/international/exit/?exit=yjbL0QxNdOEz5RxNII908LCGuhWKIuDaxofNS79j1CJQaauCUlLOoQ%3d%3d"
          target="_blank">
          http://ec.europa.eu/consumers/odr/
        </a>
      </p>
      <p>
        Our e-mail address is: <a href="mailto:info@porsche.de">info@porsche.de</a>
      </p>
      <p>
        <strong>
          Consumer information in accordance with the German Consumer Dispute Resolution Act{' '}
        </strong>
      </p>
      <p>
        The Dr. Ing. H.c. F. Porsche AG is not willing and not obliged to participate in a dispute
        settlement procedure before a consumer arbitration board.
      </p>
      <p>
        <strong>Legal notice</strong>
      </p>
      <p>
        The texts, images, graphics, animations, video and audio files as well as all other contents
        on this website are subject to the legal provisions of copyright law and, where applicable,
        other intellectual property rights. The proprietary content of this website may not be
        duplicated, distributed, reproduced, made publicly accessible or otherwise used without the
        prior consent of the right holder.
      </p>
    </div>
  </Typography>
);
