/**
 * **Component**
 *
 * Component based on React Admin **List** component to display a list of all
 * images used for image recognition.
 */

/** ignore this comment */
import { Box, Drawer, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { Fragment } from 'react';
import {
  BulkDeleteButton,
  Button,
  Datagrid,
  Error,
  FunctionField,
  List,
  Loading,
  ReferenceField,
  sanitizeEmptyValues,
  TextField,
  TopToolbar,
  useDataProvider,
  useRefresh
} from 'react-admin';

import { ReferenceImageField } from '../../../shared/ReferenceImageField';
import CreateImageRecognition from './Create';
import { EditForm } from './Edit';

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkDeleteButton {...props} undoable={false} />
  </Fragment>
);

const Empty: React.FC<{ toggleDrawer: any }> = ({ toggleDrawer }): JSX.Element => {
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No event Image Recognition available
      </Typography>
      <Button label="create" color="primary" onClick={toggleDrawer}>
        <CreateIcon />
      </Button>
    </Box>
  );
};

export const ImageRecognitionList = (props: any): JSX.Element => {
  const { eventId, ...iprops } = props;
  const fprops: Record<string, unknown> = Object.assign({}, iprops);
  const editProps: Record<string, unknown> = Object.assign({}, iprops);
  const refresh = useRefresh();

  fprops.basePath = props.basePath;
  fprops.resource = 'eventImageRecognitions';
  fprops.hasCreate = true;
  fprops.hasEdit = true;
  fprops.hasList = true;
  fprops.hasShow = false;

  editProps.resource = 'eventImageRecognitions';
  editProps.hasEdit = true;
  editProps.hasList = false;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [record, setRecord] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenEdit, setIsOpenEdit] = React.useState(false);

  const toggleDrawer = (): void => {
    setIsOpen(!isOpen);
    refresh();
  };

  const toggleDrawerEdit = (props: any): void => {
    setIsOpenEdit(!isOpenEdit);
    setRecord(props);
  };

  const dataProvider = useDataProvider();
  const onSubmitImageRecognition = (values: any): void => {
    // React-final-form removes empty values from the form state.
    // To allow users to *delete* values, this must be taken into account
    const sanitizedValues = sanitizeEmptyValues(record, values);
    const recordId = values.id;
    dataProvider
      .update('eventImageRecognition', {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: recordId!,
        data: sanitizedValues,
        previousData: record
      })
      .then(({ data }) => {
        console.log('updated data', data);
        setRecord(data);
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    toggleDrawerEdit(values);
  };

  if (loading) return <Loading />;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (error) return <Error error={error!} />;
  return (
    <React.Fragment>
      <List
        perPage={5}
        {...fprops}
        filter={{ eventId: eventId }}
        empty={<Empty {...fprops} toggleDrawer={toggleDrawer} />}
        title=" "
        actions={
          <TopToolbar>
            <Button label="create" color="primary" onClick={toggleDrawer}>
              <CreateIcon />
            </Button>
          </TopToolbar>
        }
        bulkActionButtons={<ListBulkActionButtons {...props} />}>
        <Datagrid>
          <TextField source="name" />
          <TextField source="porscheCode" label="Porsche Code" />
          <ReferenceField source="thumbnailFileId" reference="files">
            <ReferenceImageField source="file" maxWidth="100px" />
          </ReferenceField>
          <FunctionField
            label="Edit"
            render={(record: any) => (
              <Button color="primary" onClick={() => toggleDrawerEdit(record)} label="Edit">
                <EditIcon />
              </Button>
            )}
          />
        </Datagrid>
      </List>
      <Drawer open={isOpen} anchor="right" onClose={toggleDrawer}>
        <CreateImageRecognition {...fprops} eventId={eventId} toggleDrawer={toggleDrawer} />
      </Drawer>
      <Drawer open={isOpenEdit} anchor="right" onClose={toggleDrawerEdit}>
        <EditForm
          record={record}
          onSubmit={onSubmitImageRecognition}
          toggleonCancel={toggleDrawerEdit}
          {...editProps}
        />
      </Drawer>
    </React.Fragment>
  );
};
