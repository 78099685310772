/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new fun facts
 *
 * Please note that the actual content of the fun facts is handled in the translations. The text
 * here is used as reference only.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

export const FunFactCreate = (props: any): JSX.Element => (
  <Create title="Create a funfact" {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <TextInput multiline source="funFactText" validate={required()} />
    </SimpleForm>
  </Create>
);
