/**
 * **Component**
 *
 * Component based on React Admin **Create** component to add new User
 *
 * It takes care of calling the backend endpoint that sends an email to the user
 * where they need to verify their email address and set a password.
 *
 */

/** ignore this comment */
import { gql, useMutation } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import { useLanguages } from '../../hooks/useLanguages';
import { ILanguage } from '../../models/languages.model';
import React, { useState } from 'react';
import {
  AutocompleteArrayInput,
  choices,
  Create,
  email,
  ImageField,
  ImageInput,
  Error,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  usePermissions
} from 'react-admin';

const INVITE_USER = gql`
  mutation inviteUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $description: String!
    $role: UserRole!
    $languages: [String]!
  ) {
    innviteUserAccount(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        description: $description
        languages: $languages
      }
    ) {
      userAccount {
        id
      }
    }
  }
`;

const WrappedSimpleForm = ({ save, ...rest }: any) => {
  console.log('rest.userLangs', JSON.stringify(rest.userLangs, null, 2));
  const [inviteUser] = useMutation(INVITE_USER);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  // const mySave = (data: any) => {
  //   console.log(data);
  // };
  const mySave = async (data: any) => {
    try {
      data.languages = rest.userLangs;
      await inviteUser({ variables: data });
      notify(`Invited "${data.email}" to the system!`);
      redirect('/userAccount');
      refresh();
    } catch (e) {
      notify(e.message, 'warning');
    }
  };
  return <SimpleForm save={mySave} {...rest} />;
};

const validateEmail = [required(), email()];
const validateRole = choices([
  'PAG_SUPERADMIN',
  'PAG_ADMIN',
  'PAG_EDITOR_PUBLISHER',
  'PAG_TRANSLATOR'
]);
export const UserAccountCreate = (props: any) => {
  const { languages, langError } = useLanguages();
  const [userLangs, setUserLangs] = useState<string[]>([]);
  const [translatorSelected, setTranslatorSelected] = useState<boolean>(false);

  const onLangSelect = ({ id }: ILanguage): void => {
    const uLangs = [...userLangs, id];
    setUserLangs(uLangs);
  };

  const { permissions } = usePermissions();
  if (langError) {
    return <Error error={langError} />;
  }

  const handleRoleChange = (event: any) => {
    if (event.target.value === 'PAG_TRANSLATOR') {
      setTranslatorSelected(true);
    } else {
      setTranslatorSelected(false);
    }
  };

  return (
    <Create title="Create a user" {...props}>
      <WrappedSimpleForm userLangs={userLangs}>
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="description" multiline validate={required()} />
        {permissions?.role.toLowerCase() === 'pag_superadmin' ? (
          <SelectInput
            label="Role"
            source="role"
            choices={[
              { id: 'PAG_SUPERADMIN', name: 'Superadmin' },
              { id: 'PAG_ADMIN', name: 'Admin' },
              { id: 'PAG_EDITOR_PUBLISHER', name: 'Editor_Publisher' },
              { id: 'PAG_TRANSLATOR', name: 'Translator' }
            ]}
            validate={validateRole}
            onChange={handleRoleChange}
          />
        ) : (
          <SelectInput
            label="Role"
            source="role"
            choices={[
              { id: 'PAG_ADMIN', name: 'Admin' },
              { id: 'PAG_EDITOR_PUBLISHER', name: 'Editor_Publisher' },
              { id: 'PAG_TRANSLATOR', name: 'Translator' }
            ]}
            validate={validateRole}
            onChange={handleRoleChange}
          />
        )}
        {translatorSelected && (
          <AutocompleteArrayInput source="languages" choices={languages} onSelect={onLangSelect} />
        )}
      </WrappedSimpleForm>
    </Create>
  );
};
