/**
 * **Component**
 *
 * This component presents an overview of all derivatives organized by Mode Lines and Sub Models.
 *
 * Here is where the user will select which derivatives will be shown in a market or event. As well
 * as providing an access to override the particular derivative configuration.
 */

/** ignore this comment */
import { Checkbox, FormControlLabel } from '@material-ui/core/';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import EditAttributes from '@material-ui/icons/EditAttributes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Error, GetListParams, Loading, useDataProvider, useNotify } from 'react-admin';
import { Link } from 'react-router-dom';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import ButtonMaterial from '@material-ui/core/Button';
import { gql, useMutation } from '@apollo/client';

import { IDerivative } from '../../../models/derivatives.model';
import {
  EventCarsFormProps,
  IEvent,
  IEventDerivative,
  IEventModel,
  IEventSubModel,
  IModellineSelectorProps,
  ISubmodelSelectorProps
} from '../../../models/event.model';

const useStyles = makeStyles({
  childAccordionContainer: {
    flexDirection: 'column'
  },
  modellineName: {
    '& span': { fontSize: '1.2em', fontWeight: 'bold' }
  },
  submodelName: {
    '& span': {
      fontSize: '1.2em'
    }
  },
  derivativeItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonUpAndDown: {
    boxShadow: 'none',
    color: 'black',
    backgroundColor: 'transparent',
    minWidth: '0px',
    maxWidth: '30px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  }
});

const mutationUpdatePriority1 = gql`
  mutation changepriority(
    $object1Id: UUID!
    $priority1: Int!
    $object2Id: UUID!
    $priority2: Int!
    $typeobject: String!
    $eventid: UUID!
  ) {
    changepriority(
      input: {
        object1Id: $object1Id
        priority1: $priority1
        object2Id: $object2Id
        priority2: $priority2
        typeobject: $typeobject
        eventid: $eventid
      }
    ) {
      boolean
    }
  }
`;

const ModelLinesSelector = (props: IModellineSelectorProps): JSX.Element => {
  const classes = useStyles();
  const submodelInfoEventFiltered = props.submodelInfoEvents.filter(
    (submodelInfoEvent) => submodelInfoEvent.modellineId === props.modellineInfoEvent.modelId
  );
  return (
    <Accordion>
      <AccordionSummary className={classes.modellineName} expandIcon={<ExpandMoreIcon />}>
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <div>
              <ButtonMaterial
                aria-label="down"
                onFocus={(event) => event.stopPropagation()}
                variant="contained"
                onClick={() =>
                  props.changeObjectPriority(
                    props.modellineInfoEvent,
                    props.modellineInfoEvent.priority,
                    'modelline',
                    'down'
                  )
                }
                className={classes.buttonUpAndDown}>
                <ArrowDownward />
              </ButtonMaterial>
              <ButtonMaterial
                aria-label="up"
                onFocus={(event) => event.stopPropagation()}
                variant="contained"
                onClick={() =>
                  props.changeObjectPriority(
                    props.modellineInfoEvent,
                    props.modellineInfoEvent.priority,
                    'modelline',
                    'up'
                  )
                }
                className={classes.buttonUpAndDown}>
                <ArrowUpward />
              </ButtonMaterial>
            </div>
          }
          label={props.modellineInfoEvent.modelName}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.childAccordionContainer}>
        {submodelInfoEventFiltered.map((submodelInfoEvent) => (
          <SubmodelSelector
            key={submodelInfoEvent.id}
            showArrows={submodelInfoEventFiltered.length > 1}
            eventDerivatives={props.eventDerivatives}
            handleDerivativeChange={props.handleDerivativeChange}
            submodel={submodelInfoEvent}
            derivatives={props.derivatives}
            changeObjectPriority={props.changeObjectPriority}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
const SubmodelSelector = (props: ISubmodelSelectorProps) => {
  const classes = useStyles();
  const derivativesFiltered = props.derivatives.filter(
    (derivative) => derivative.submodelId === props.submodel.submodelId
  );
  const eventId = props.eventDerivatives.length > 0 ? props.eventDerivatives[0].eventId : 0;
  return (
    <Accordion>
      <AccordionSummary className={classes.submodelName} expandIcon={<ExpandMoreIcon />}>
        {props.showArrows ? (
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <div>
                <ButtonMaterial
                  aria-label="up"
                  onFocus={(event) => event.stopPropagation()}
                  variant="contained"
                  onClick={() =>
                    props.changeObjectPriority(
                      props.submodel,
                      props.submodel.priority,
                      'submodel',
                      'down'
                    )
                  }
                  className={classes.buttonUpAndDown}>
                  <ArrowDownward />
                </ButtonMaterial>
                <ButtonMaterial
                  aria-label="up"
                  onFocus={(event) => event.stopPropagation()}
                  variant="contained"
                  onClick={() =>
                    props.changeObjectPriority(
                      props.submodel,
                      props.submodel.priority,
                      'submodel',
                      'up'
                    )
                  }
                  className={classes.buttonUpAndDown}>
                  <ArrowUpward />
                </ButtonMaterial>
              </div>
            }
            label={props.submodel.submodelName}
          />
        ) : (
          props.submodel.submodelName
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.childAccordionContainer}>
        {derivativesFiltered.map((derivative) => (
          <div className={classes.derivativeItemContainer} key={derivative.id}>
            <FormControlLabel
              label={derivative.name}
              control={
                derivativesFiltered.length > 1 ? (
                  <div>
                    <ButtonMaterial
                      aria-label="up"
                      onFocus={(event) => event.stopPropagation()}
                      variant="contained"
                      onClick={() => {
                        if (
                          props.eventDerivatives
                            .map((der: IEventDerivative) => der.derivativeId)
                            .includes(derivative.id)
                        )
                          props.changeObjectPriority(
                            derivative,
                            props.eventDerivatives.find(
                              (der: IEventDerivative) => der.derivativeId === derivative.id
                            )?.priority ?? 1,
                            'derivative',
                            'down'
                          );
                      }}
                      className={classes.buttonUpAndDown}>
                      <ArrowDownward />
                    </ButtonMaterial>
                    <ButtonMaterial
                      aria-label="up"
                      onFocus={(event) => event.stopPropagation()}
                      variant="contained"
                      onClick={() => {
                        if (
                          props.eventDerivatives
                            .map((der: IEventDerivative) => der.derivativeId)
                            .includes(derivative.id)
                        )
                          props.changeObjectPriority(
                            derivative,
                            props.eventDerivatives.find(
                              (der: IEventDerivative) => der.derivativeId === derivative.id
                            )?.priority ?? 1,
                            'derivative',
                            'up'
                          );
                      }}
                      className={classes.buttonUpAndDown}>
                      <ArrowUpward />
                    </ButtonMaterial>
                    <Checkbox
                      checked={
                        props.eventDerivatives.filter(
                          (der: IEventDerivative) => der.derivativeId === derivative.id
                        )[0]?.active || false
                      }
                      onChange={(e) => props.handleDerivativeChange(derivative, e.target.checked)}
                    />
                  </div>
                ) : (
                  <Checkbox
                    checked={
                      props.eventDerivatives.filter(
                        (der: IEventDerivative) => der.derivativeId === derivative.id
                      )[0]?.active || false
                    }
                    onChange={(e) => props.handleDerivativeChange(derivative, e.target.checked)}
                  />
                )
              }
            />
            <Button
              disabled={
                !props.eventDerivatives
                  .map((der: IEventDerivative) => der.derivativeId)
                  .includes(derivative.id) ?? false
              }
              component={Link}
              to={{
                pathname: '/editor-event-derivative',
                search: `?derivative_id=${derivative.id}&event_id=${eventId}`
              }}
              label="Configure">
              <EditAttributes />
            </Button>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const EventCarsForm: FC<EventCarsFormProps> = (props: EventCarsFormProps): ReactElement => {
  const record: IEvent = props.record as IEvent;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | any>();
  const [derivativesData, setDerivativeData] = useState<IDerivative[] | null>(null);
  const [submodelInfoEventsData, setSubmodelsData] = useState<IEventSubModel[] | null>(null);
  const [modellineInfoEventsData, setModellinesData] = useState<IEventModel[] | null>(null);
  const [eventDerivativesData, setEventDerivativesData] = useState<IEventDerivative[] | null>(null);
  const [updatePriorityMutation] = useMutation(mutationUpdatePriority1);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  async function createNewEventDerivative(derivative: IDerivative) {
    const lengthDer =
      eventDerivativesData?.filter((d) => d.submodelId === (derivative as IDerivative).submodelId)
        .length ?? 0;
    try {
      const { data } = await dataProvider.create<IEventDerivative>('derInfoEvents', {
        data: {
          derivativeId: derivative.id,
          eventId: record.id,
          priority: lengthDer + 1
        }
      });

      const d = eventDerivativesData!.slice();
      d.push(data);

      d.sort((a: IEventDerivative, b: IEventDerivative) => {
        if (!a?.priority) return 1;
        if (!b?.priority) return -1;
        return a?.priority - b?.priority;
      });

      setEventDerivativesData(d);

      //order the list of the derivatives
      if (derivativesData) updateOrderOfDerivatives(d, derivativesData);
      notify('Derivative added to event');
    } catch (error) {
      notify(error, 'error');
    }
  }

  async function updateEventDerivative(
    toUpdate: IEventDerivative,
    derivative: IDerivative,
    active: boolean
  ) {
    try {
      await dataProvider.update<IEventDerivative>('derInfoEvents', {
        id: toUpdate.id,
        data: {
          active
        },
        previousData: toUpdate
      });

      const d = eventDerivativesData!.slice().map((ed) => {
        const newEd = { ...ed };
        if (ed.id === toUpdate.id) {
          newEd.active = active;
        }
        return newEd;
      });

      d.sort((a: IEventDerivative, b: IEventDerivative) => {
        if (!a?.priority) return 1;
        if (!b?.priority) return -1;
        return a?.priority - b?.priority;
      });

      setEventDerivativesData(d);

      //order the list of the derivatives
      if (derivativesData) updateOrderOfDerivatives(d, derivativesData);

      notify('derivative removed from event');
    } catch (error) {
      notify(error, 'error');
    }
  }

  const handleDerivativeChange = async (derivative: IDerivative, checked: boolean) => {
    const toUpdate = eventDerivativesData!.find((eventDers) => {
      return eventDers.derivativeId === derivative.id && eventDers.eventId === record.id;
    });
    if (!toUpdate || !toUpdate?.id) {
      await createNewEventDerivative(derivative);
    } else {
      await updateEventDerivative(toUpdate, derivative, checked);
    }
  };

  const updateStateModelline = (
    object1Id: string,
    priority1: number,
    object2Id: string,
    priority2: number
  ) => {
    const modellineToUpdate: IEventModel[] = JSON.parse(JSON.stringify(modellineInfoEventsData));

    if (modellineToUpdate) {
      const newList = modellineToUpdate
        ?.map((x) => {
          if (x.modelId === object1Id) {
            x.priority = priority1;
            return x;
          } else if (x.modelId === object2Id) {
            x.priority = priority2;
            return x;
          } else return x;
        })
        .sort((a: IEventModel, b: IEventModel) => {
          if (!a?.priority) return 1;
          if (!b?.priority) return -1;
          return a?.priority - b?.priority;
        });
      setModellinesData(newList);
    }
    return true;
  };

  const updateStateSubmodel = (
    object1Id: string,
    priority1: number,
    object2Id: string,
    priority2: number
  ) => {
    const submodelToUpdate: IEventSubModel[] = JSON.parse(JSON.stringify(submodelInfoEventsData));

    if (submodelToUpdate) {
      const newList = submodelToUpdate
        ?.map((x) => {
          if (x.submodelId === object1Id) {
            x.priority = priority1;
            return x;
          } else if (x.submodelId === object2Id) {
            x.priority = priority2;
            return x;
          } else return x;
        })
        .sort((a: IEventSubModel, b: IEventSubModel) => {
          if (!a?.priority) return 1;
          if (!b?.priority) return -1;
          return a?.priority - b?.priority;
        });

      setSubmodelsData(newList);
    }
    return true;
  };

  const updateStateDerivative = (
    object1Id: string,
    priority1: number,
    object2Id: string,
    priority2: number
  ) => {
    const evDerToUpdate: IEventDerivative[] = JSON.parse(JSON.stringify(eventDerivativesData));

    if (evDerToUpdate) {
      const newList = evDerToUpdate
        ?.map((x) => {
          if (x.derivativeId === object1Id) {
            x.priority = priority1;
            return x;
          } else if (x.derivativeId === object2Id) {
            x.priority = priority2;
            return x;
          } else return x;
        })
        .sort((a: IEventDerivative, b: IEventDerivative) => {
          if (!a?.priority) return 1;
          if (!b?.priority) return -1;
          return a?.priority - b?.priority;
        });
      setEventDerivativesData(newList);

      if (derivativesData) updateOrderOfDerivatives(newList, derivativesData);
    }
    return true;
  };

  const updateOrderOfDerivatives = (
    eventDerivativeList: IEventDerivative[],
    derivativeList: IDerivative[]
  ) => {
    const oldDerivative = JSON.parse(JSON.stringify(derivativeList));

    const newListDerivatives = oldDerivative
      .map((derivative: IDerivative) => {
        derivative.priority = eventDerivativeList.find(
          (e) => e.derivativeId === derivative.id
        )?.priority;
        return derivative;
      })
      .sort((a: IDerivative, b: IDerivative) => {
        if (!a?.priority) return 1;
        if (!b?.priority) return -1;
        return a?.priority - b?.priority;
      });

    setDerivativeData(newListDerivatives);
  };

  const changeObjectPriority = async (
    object: IEventModel | IEventSubModel | IDerivative,
    objectInitialPriority: number,
    typeObject: string,
    typeChange: string
  ) => {
    const data =
      typeObject === 'modelline'
        ? modellineInfoEventsData
        : typeObject === 'submodel'
        ? submodelInfoEventsData?.filter(
            (s) => s.modellineId === (object as IEventSubModel).modellineId
          )
        : eventDerivativesData?.filter((d) => d.submodelId === (object as IDerivative).submodelId);

    if (!data) return true;

    if (typeChange === 'up') {
      if (
        typeObject === 'modelline' &&
        modellineInfoEventsData &&
        modellineInfoEventsData[0]?.id === object.id
      )
        return true;
      else if (
        typeObject === 'submodel' &&
        submodelInfoEventsData &&
        submodelInfoEventsData.filter(
          (s) => s.modellineId === (object as IEventSubModel).modellineId
        )[0]?.id === object.id
      )
        return true;
      else if (typeObject === 'derivative') {
        //check if is checked
        const eventDerivative = eventDerivativesData?.find(
          (ed) => ed.derivativeId === (object as IDerivative).id
        );
        if (
          eventDerivative &&
          eventDerivativesData?.filter(
            (d) => d.submodelId === (object as IDerivative).submodelId
          )[0]?.id === object.id
        )
          return true;
      }
    }

    //if typeChange is down, check if it is not the last of the list
    if (typeChange === 'down') {
      if (
        typeObject === 'modelline' &&
        modellineInfoEventsData &&
        modellineInfoEventsData[modellineInfoEventsData.length - 1]?.id === object.id
      )
        return true;
      else if (
        typeObject === 'submodel' &&
        submodelInfoEventsData &&
        submodelInfoEventsData?.filter(
          (s) => s.modellineId === (object as IEventSubModel).modellineId
        )[
          submodelInfoEventsData?.filter(
            (s) => s.modellineId === (object as IEventSubModel).modellineId
          ).length - 1
        ]?.id === object.id
      )
        return true;
      else if (typeObject === 'derivative') {
        //check if is checked
        const eventDerivative = eventDerivativesData?.find(
          (ed) => ed.derivativeId === (object as IDerivative).id
        );
        if (
          eventDerivative &&
          eventDerivativesData &&
          eventDerivativesData?.filter((d) => d.submodelId === (object as IDerivative).submodelId)[
            eventDerivativesData?.filter((d) => d.submodelId === (object as IDerivative).submodelId)
              .length - 1
          ]?.derivativeId === object.id
        ) {
          return true;
        }
      }
    }

    //get the objectId
    let objectId;
    if (typeObject === 'modelline') objectId = (object as IEventModel).modelId;
    else if (typeObject === 'submodel') objectId = (object as IEventSubModel).submodelId;
    else if (typeObject === 'derivative') objectId = (object as IDerivative).id;

    if (!objectId) return true;

    //get the new priority for the object depending of if it up or down
    let updatedIndex: number;
    if (typeChange === 'down') updatedIndex = 1;
    else updatedIndex = -1;

    let object2;
    if (typeObject !== 'derivative') {
      const indexObject: number = data.findIndex((x: any) => x.id === object.id);
      object2 = data[indexObject + updatedIndex];
    } else {
      const indexObject: number =
        derivativesData
          ?.filter((d) => d.submodelId === (object as IDerivative).submodelId)
          .findIndex((x: any) => x.id === object.id) ?? 1;

      const nextDer = derivativesData?.filter(
        (d) => d.submodelId === (object as IDerivative).submodelId
      )[indexObject + updatedIndex];

      if (nextDer) object2 = eventDerivativesData?.find((x) => x.derivativeId === nextDer.id);
      else object2 = null;
    }

    if (object2) {
      const object2Id =
        typeObject === 'modelline'
          ? (object2 as IEventModel).modelId
          : typeObject === 'submodel'
          ? (object2 as IEventSubModel).submodelId
          : (object2 as IEventDerivative).derivativeId;

      const test = await updatePriorityMutation({
        variables: {
          object1Id: objectId,
          priority1: object2.priority,
          object2Id: object2Id,
          priority2: objectInitialPriority,
          typeobject: typeObject,
          eventid: record.id
        }
      });
      if (!test.data?.changepriority?.boolean) return false;
      //update state
      if (typeObject === 'modelline')
        updateStateModelline(objectId, object2.priority, object2Id, objectInitialPriority);
      else if (typeObject === 'submodel')
        updateStateSubmodel(objectId, object2.priority, object2Id, objectInitialPriority);
      else if (typeObject === 'derivative')
        updateStateDerivative(objectId, object2.priority, object2Id, objectInitialPriority);
    }
    return true;
  };

  let isCancelled = false;
  useEffect(() => {
    const modelInfoEventsParams: GetListParams = {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'priority', order: 'ASC' },
      filter: { eventId: record.id }
    };

    const submodelInfoEventsParams: GetListParams = {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'priority', order: 'ASC' },
      filter: { eventId: record.id }
    };

    const derivativesParams: GetListParams = {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    };

    const derInfoEventsParams: GetListParams = {
      pagination: { page: 1, perPage: 10000000 },
      sort: { field: 'priority', order: 'ASC' },
      filter: { eventId: record.id }
    };

    const modellinesP = dataProvider.getList('modelInfoEvents', modelInfoEventsParams);
    const submodelsP = dataProvider.getList('submodelInfoEvents', submodelInfoEventsParams);
    const derivativesP = dataProvider.getList('derivatives', derivativesParams);
    const eventDerP = dataProvider.getList('derInfoEvents', derInfoEventsParams);

    Promise.all([modellinesP, submodelsP, derivativesP, eventDerP])
      .then(([modellines, submodels, derivatives, eventDer]) => {
        if (isCancelled) {
          return;
        }
        if (
          eventDer.data !== null &&
          derivatives.data !== null &&
          derivatives.data.length !== 0 &&
          eventDer.data?.length !== 0
        ) {
          updateOrderOfDerivatives(
            eventDer.data as IEventDerivative[],
            derivatives.data as IDerivative[]
          );
        } else setDerivativeData(derivatives.data as IDerivative[]);

        setModellinesData(modellines.data as IEventModel[]);
        setSubmodelsData(submodels.data as IEventSubModel[]);
        setEventDerivativesData(eventDer.data as IEventDerivative[]);
        setLoading(false);
      })
      .catch((error) => {
        if (isCancelled) {
          return;
        }
        setError(error);
        setLoading(false);
      });
    return () => {
      isCancelled = true;
    };
  }, [dataProvider, record]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <div>
      {modellineInfoEventsData?.map((modellineInfoEvent) => (
        <ModelLinesSelector
          key={modellineInfoEvent.id}
          modellineInfoEvent={modellineInfoEvent}
          submodelInfoEvents={submodelInfoEventsData!}
          derivatives={derivativesData!}
          eventDerivatives={eventDerivativesData!}
          handleDerivativeChange={handleDerivativeChange}
          changeObjectPriority={changeObjectPriority}
        />
      ))}
    </div>
  );
};
