/**
 * **Component**
 *
 * This component is used to style the CMS in a Porsche CI conform fashion
 *
 */

/** ignore this comment */
import React, { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

import { AppBarProps } from '@react-admin/ra-enterprise/esm/src/layout/AppBar';
import { LoadingIndicator, RefreshIconButton, UserMenu, Link } from 'react-admin';
import { color } from '@porsche-design-system/utilities';
import { Typography } from '@material-ui/core';
import { SVGIcon } from '../shared/SVGIcon';

const iconSize = 28;

const UserIcon = <SVGIcon name="user" width={iconSize} />;
const RefreshIcon = <SVGIcon name="refresh" width={iconSize} />;

const useStyles = makeStyles({
  grow: {
    flexGrow: 1
  },
  logoContainer: {
    left: '10px',
    top: 0,
    backgroundColor: 'white',
    position: 'absolute',
    border: '0.0625rem solid #c9cacb',
    padding: '0.375rem 0.3125rem 0.4375rem 0.3125rem'
  },
  topBar: {
    backgroundColor: color.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 10px'
  },
  headline: {
    color: '#000000',
    marginLeft: '160px'
  },
  pAppBar: {
    backgroundColor: '#fff',
    boxShadow: '0 0 #000',
    borderBottom: '0.0625rem solid #c9cacb',
    height: '80px'
  }
});

export const CMSAppBar: React.FC<AppBarProps> = (props: AppBarProps) => {
  const { logout, userMenu, ...rest } = props;
  const classes = useStyles();
  return (
    <AppBar {...props} className={classes.pAppBar}>
      <div className={classes.topBar}>
        <div className={classes.logoContainer}>
          <Link to="/">
            <img
              src="./porsche-marque-trademark.medium.min.af8bb58f56a36a40768c43e1b6040d1f@1x.png"
              alt="Porsche"
            />
          </Link>
        </div>
        <Typography id="react-admin-title" variant="h5" className={classes.headline} />

        <div className={classes.grow} />

        {/* <LoadingIndicator /> */}
        <RefreshIconButton icon={RefreshIcon} />
        {cloneElement(<UserMenu icon={UserIcon} />, { logout })}
      </div>
    </AppBar>
  );
};
