/**
 * **Hook**
 * 
 * This custom hook enables uploading files to the Postgraphile backend. It returns an object
 * with the following properties:
 * uploadError: In case of error while uploading the file this will contain a message with an explanation
 * fileId: This is the id of the file entry in the database.
 * handleFileUpload: this is the function that actually executes the upload. This is what the user should call
 * when the file is added.
 *
 * An example usage would look like this:
 *   const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();
 *
 *  and then withing the JSX:
 *         <ReferenceField source="thumbnailFileId" reference="files" link={false}>
 *           <ReferenceImageField source="file" maxWidth="250px" />
 *         </ReferenceField>
 *         {uploadError && <Alert severity="error">{uploadError}</Alert>}
 *         <ImageInput
 *           onChange={handleFileUpload}
 *           source="editRim"
 *           label="Thumbnail File Upload"
 *           accept="image/*">
 *           <ImageField source="src" title="title" />
 *         </ImageInput>
 *
 */

/** ignore this comment */
import { print } from 'graphql';
import gql from 'graphql-tag';
import { useState } from 'react';

const mutationUploadFile = gql`
  mutation uploadFile($input: CreateFileInput!) {
    createFile(input: $input) {
      file {
        id
      }
    }
  }
`;


export const useFileUpload = (): {
  uploadError: string | undefined;
  fileId: string | undefined;
  handleFileUpload: any;
} => {
  const [fileId, setFileId] = useState<string | undefined>();
  const [uploadError, setUploadError] = useState<string | undefined>();

  const handleFileUpload = async (file: File) => {
    setFileId(undefined);
    setUploadError(undefined);

    if (!file) {
      return;
    }

    const formData = new FormData();
    const operations = {
      operationName: 'uploadFile',
      variables: { input: { file: { file: null } } },
      query: print(mutationUploadFile) // get formatted query string from gql
    };
    formData.append('operations', JSON.stringify(operations));

    const map = '{ "0": ["variables.input.file.file"]  }';
    formData.append('map', map);
    formData.append('0', file);

    try {
      const response = await fetch(process.env.REACT_APP_GQL_HOST!, {
        method: 'POST',
        body: formData,
        headers: new Headers({
          Authorization: 'BEARER ' + localStorage.getItem('accessToken')
        })
      });

      const data = await response.json();

      const {
        data: {
          createFile: {
            file: { id }
          }
        }
      } = data;
      setFileId(id);
    } catch (error) {
      console.log('error uploading', error);
      setUploadError('Error uploading file');
    }
  };

  return { uploadError, fileId, handleFileUpload };
};
