/**
 * **Component**
 *
 * Component based on React Admin **Create** component to amange existing Sub Models
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import {
  DateField,
  Edit,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';

import { ReferenceImageField } from '../shared/ReferenceImageField';
import { fuelTypeChoices, FuelTypeModel } from '../../models/fuel-type.models';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const SubmodelEdit = (props: any): JSX.Element => {
  const classes = useStyles();
  return (
    <Edit title="Edit Submodel" {...props}>
      <SimpleForm redirect={false}>
        <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
        <ReferenceInput
          validate={required()}
          label="Model line"
          source="modellineId"
          reference="modellines"
          sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <RadioButtonGroupInput
          source="fuelType"
          defaultValue={FuelTypeModel.Unknown}
          choices={fuelTypeChoices}
          style={{ textTransform: 'capitalize' }}
        />
        <TextInput source="submodelPorscheId" label="Submodel ID" validete={required()} />
      </SimpleForm>
    </Edit>
  );
};
