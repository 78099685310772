/**
 * **Component**
 *
 * This component is used to select which Fun Facts will be show for this derivative in this
 * particular event.
 */

/** ignore this comment */
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import ContentSave from '@material-ui/icons/Save';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { Button, ImageField, ImageInput, Loading, ReferenceField } from 'react-admin';
import { Form } from 'react-final-form';

import { useFileUpload } from '../../../hooks/useFileUpload';
import { IDerFunFactEvent, IDerInfoEvent } from '../../../models/derivatives.model';
import { DerFunFactsEventEditProps } from '../../../models/event.model';
import { ReferenceImageField } from '../../shared/ReferenceImageField';
import { SanitizedFormControlLabel } from '../../shared/SanitizedFormControlLabel';

const useStyles = makeStyles({
  root: {
    margin: '15px',
    minWidth: 275
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 275
  },
  title: {
    fontSize: 14
  }
});

export const DerFunFactsEventEdit: FC<DerFunFactsEventEditProps> = (props) => {
  const { record, funFactChoices, derFunFactEvent, onFunFactChanged } = props;
  const classes = useStyles();

  const { uploadError, fileId: funFactFileId, handleFileUpload } = useFileUpload();

  const onSubmit = (record: IDerInfoEvent) => {
    if (funFactFileId) {
      record.funFactFileId = funFactFileId;
    }
    props.onSubmit(record);
  };

  const defaultIcon = <ContentSave />;
  if (!record) {
    return <Loading />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={record}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Card variant="outlined" className={classes.root}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Fun Facts
              </Typography>
              <div className={classes.verticalContainer}>
                {funFactChoices.map((choice) => (
                  <SanitizedFormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !derFunFactEvent
                            ?.map((ff: IDerFunFactEvent) => ff.funFactId)
                            .includes(choice.id) ?? true
                        }
                        onChange={(e) => onFunFactChanged(choice, e.target.checked)}
                      />
                    }
                    key={choice.id}
                    label={choice.funFactText}
                  />
                ))}
              </div>
            </CardContent>
          </Card>
          <Card variant="outlined" className={classes.root}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Fun Facts Thumbnail
              </Typography>
              {record.funFactFileId && (
                <ReferenceField
                  record={record}
                  basePath="/derInfoEvents"
                  source="funFactFileId"
                  reference="files"
                  link={false}>
                  <ReferenceImageField source="file" maxWidth="250px" />
                </ReferenceField>
              )}

              {uploadError && <Alert severity="error">{uploadError}</Alert>}
              <ImageInput
                onChange={handleFileUpload}
                source="editModel"
                label="Fun Fact File Upload"
                accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Button
                    disabled={submitting || pristine}
                    type="submit"
                    label="save"
                    variant="contained"
                    color={submitting ? 'default' : 'primary'}>
                    {defaultIcon}
                  </Button>
                </Box>
              </Toolbar>
            </CardContent>
          </Card>
        </form>
      )}
    />
  );
};
