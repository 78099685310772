/**
 * **Top Page Component**
 *
 * This is the pages used to request a password reset. It calls a mutation on the backend that send the
 * user an email with a special link that opens a page (**LoginPage**) where they change their password.
 *
 */

/** ignore this comment */
import { gql, useMutation } from '@apollo/client';
import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import React, { useMemo, useRef } from 'react';
import {
  email,
  Notification,
  required,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { Form } from 'react-final-form';

const REQUEST_PW_RESET = gql`
  mutation reqpw($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      status
    }
  }
`;

const useStyles = makeStyles(
  (theme: any) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: 'white'
    },
    card: {
      minWidth: 300,
      marginTop: '6em'
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    },
    form: {
      padding: '0 1em 1em 1em'
    },
    input: {
      marginTop: '1em'
    },
    button: {
      width: '100%',
      backgroundColor: '#D5001C',
      textTransform: 'none',
      borderRadius: '0px',
      fontSize: '20px',
      fontFamily: 'Porsche Next'
    }
  }),
  { name: 'RaLogin' }
);

const WrappedSimpleForm = ({ save, ...rest }: any) => {
  const [requestPwReset] = useMutation(REQUEST_PW_RESET);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles(rest);

  const validateEmail = [required(), email()];

  const mySave = async (formdata: any) => {
    try {
      const { data } = await requestPwReset({
        variables: { email: formdata.email }
      });
      redirect('/login');
      refresh();
      notify(`Password reset mail sent to ${formdata.email}!`);
    } catch (e) {
      notify(e.message, 'warning');
    }
  };
  return (
    <Form
      onSubmit={mySave}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <TextInput source="email" validate={validateEmail} />
            </div>
          </div>
          <CardActions>
            <Button variant="contained" type="submit" color="primary" className={classes.button}>
              <KeyboardArrowRight />
              Send
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

export const PwResetRequest = (props: any) => {
  const { theme, className, ...rest } = props;
  const containerRef: any = useRef<HTMLDivElement>();
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img
              src="./porsche-marque-trademark.medium.min.af8bb58f56a36a40768c43e1b6040d1f@1x.png"
              alt="Porsche"
              width="100px"
            />
          </div>
          <WrappedSimpleForm {...props}></WrappedSimpleForm>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};
