/**
 * **Component**
 *
 * Utility component used to create a field for editing JSON properties.
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

interface IJSONFieldProps {
  source: string;
  label: string;
  height?: number;
  width?: number;
}

interface IJSONOnChangeEvent {
  plainTest: string;
  markupText: string;
  json: string;
  jsObject: any;
  lines: number;
  error: false | { token: string; line: number; reason: string };
}

const useStyles = makeStyles({
  fieldLabel: {
    fontSize: '1.1em',
    margin: '15px 0 5px 0'
  }
});

export const JSONField: FC<IJSONFieldProps> = (props: IJSONFieldProps): JSX.Element => {
  const classes = useStyles();
  let height = props.height;
  if (height && height < 120) {
    height = 120;
  }
  return (
    <div>
      <div className={classes.fieldLabel}>{props.label}</div>
      <Field name={props.source}>
        {(fprops) => {
          let value = {};
          if (fprops.input.value) {
            value = JSON.parse(fprops.input.value);
          }
          const onJSONChanged = (value: IJSONOnChangeEvent) => {
            if (!value.error) {
              fprops.input.onChange(value.json);
            }
          };
          return (
            <JSONInput
              id={Math.random()}
              placeholder={value}
              locale={locale}
              height={height ? height + 'px' : '550px'}
              width={props.width ? props.width + 'px' : '550px'}
              onChange={onJSONChanged}
            />
          );
        }}
      </Field>
    </div>
  );
};
