/**
 * **Component**
 *
 * Component based on React Admin **List** component to show existing Model Lines
 * and, depending on the rights, to offer an entry point to add or managa existing ones.
 *
 */

/** ignore this comment */
import React, { Fragment, useState, useEffect } from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField,
  useDataProvider
} from 'react-admin';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';

const ModellineFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="name" alwaysOn={true} />
  </Filter>
);

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const ModellineList = (props: any) => (
  <List
    {...props}
    title="Model Lines"
    filters={<ModellineFilter />}
    exporter={false}
    bulkActionButtons={
      checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
        <ListBulkActionButtons {...props} />
      ) : (
        false
      )
    }>
    <Datagrid>
      <TextField source="name" />
      {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
        <EditButton label="Edit" />
      )}
    </Datagrid>
  </List>
);
