/**
 * **Component**
 *
 * With this component the administrator can manage which users can edit or publish a market of event
 */

/** ignore this comment */
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { Error, GetListParams, Loading, useDataProvider, useNotify } from 'react-admin';
import { ChangeEvent, useEffect, useState } from 'react';
import { IUser } from '../../../models/user.model';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core';
import { IDictionary } from '../../../models/dictionary.interface';
import { UserRole } from '../../../models/user-permissions.model';
import DeleteIcon from '@material-ui/icons/Delete';

const eventsParams: GetListParams = {
  pagination: { page: 1, perPage: 20000 },
  sort: { field: 'id', order: 'ASC' },
  filter: {}
};

const usersParams: GetListParams = {
  pagination: { page: 1, perPage: 20000 },
  sort: { field: 'firstName', order: 'ASC' },
  filter: { role: UserRole.EDITOR_PUBLISHER.toUpperCase() }
};

type EventRole = 'EDITOR' | 'PUBLISHER';
interface IEventUser extends IUser {
  eventRole: EventRole;
  eventUserId: string;
}

interface IEventUserBase {
  id: string;
  userId: string;
  eventId: string;
  resourceRole: EventRole;
}

export const ManageEditorPublisher = (props: any): JSX.Element => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { id: eventId } = props;
  const [users, setUsers] = useState<IUser[]>([]);
  const [eventUsers, setEventUsers] = useState<IEventUser[]>([]);
  const [eventUser, setEventUser] = useState<IEventUserBase[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const createUserRole = (event: ChangeEvent<unknown>, user: IUser | null): void => {
    (async function updateUserRole() {
      try {
        const { data } = await dataProvider.create<IEventUserBase>('eventUser', {
          data: { eventId, userId: user?.id, resourceRole: 'EDITOR' }
        });

        if (data) {
          const user = users.find((u) => u.id === data.userId);
          if (user) {
            setEventUsers((prevState) => {
              return [
                ...prevState,
                { ...user, eventRole: data.resourceRole, eventUserId: data.id }
              ];
            });
            notify(`${user?.firstName} ${user?.lastName} added to the Event`, 'info');
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const updateUserRole = (event: ChangeEvent<HTMLInputElement>, eventUserId: string): void => {
    const resourceRole = event.target.value;
    const previousData = eventUser.find((eu) => eu.id === eventUserId);
    (async function updateUserRole() {
      try {
        const { data } = await dataProvider.update<IEventUserBase>('eventUser', {
          id: eventUserId,
          data: { resourceRole },
          previousData: previousData!
        });

        if (data) {
          const index = eventUsers.findIndex((eu) => eu.eventUserId === eventUserId);
          const user = { ...eventUsers[index], eventRole: resourceRole as EventRole };
          setEventUsers((prevState) => {
            return [...prevState.slice(0, index), user, ...prevState.slice(index + 1)];
          });
          notify(`Role updated to ${resourceRole}`, 'info');
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const deleteUserRole = (eventUserId: string): void => {
    const previousData = eventUser.find((eu) => eu.id === eventUserId);
    (async function updateUserRole() {
      try {
        const { data } = await dataProvider.delete<IEventUserBase>('eventUser', {
          id: eventUserId,
          previousData: previousData!
        });

        if (data) {
          const index = eventUsers.findIndex((eu) => eu.eventUserId === eventUserId);
          setEventUsers((prevState) => {
            return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
          });
          notify(`User deleted`, 'info');
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    (async function fetchUsers() {
      const eventUserIdRoleHashmap: IDictionary<{ eventUserId: string; role: EventRole }> = {};
      try {
        const { data: eventsFromEventUser } = await dataProvider.getManyReference<IEventUserBase>(
          'eventUser',
          {
            target: 'eventId',
            id: eventId,
            ...eventsParams
          }
        );
        setEventUser(eventsFromEventUser);

        const eventUserIds = eventsFromEventUser.map((eu) => {
          eventUserIdRoleHashmap[eu.userId] = {
            eventUserId: eu.id as string,
            role: eu.resourceRole
          };
          return eu.userId;
        });
        const { data: eUsers } = await dataProvider.getMany<IUser>('userAccount', {
          ids: eventUserIds
        });
        const eventUsers = eUsers.map((u) => ({
          ...u,
          eventRole: eventUserIdRoleHashmap[u.id].role,
          eventUserId: eventUserIdRoleHashmap[u.id].eventUserId
        }));
        const { data: users } = await dataProvider.getList<IUser>('userAccount', usersParams);
        setUsers(users);
        setEventUsers(eventUsers);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, [props]);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Autocomplete
        options={users}
        fullWidth={false}
        getOptionLabel={(option: IUser) => (option ? option.firstName + ' ' + option.lastName : '')}
        filterOptions={(options) =>
          options.filter((u) => {
            // filter out already assigned users
            const ids = eventUsers.map((eu) => eu.id);
            return !ids.includes(u.id);
          })
        }
        onChange={createUserRole}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} variant="standard" label="Search Users" />
        )}
      />

      <List>
        {eventUsers.map((u: IEventUser) => (
          <ListItem key={u.id}>
            <ListItemText primary={u.firstName + ' ' + u.lastName} />
            <FormControl>
              <RadioGroup
                value={u.eventRole ?? null}
                onChange={(event) => updateUserRole(event, u.eventUserId)}>
                <FormControlLabel
                  value="EDITOR"
                  control={<Radio color="primary" />}
                  label="Editor"
                />
                <FormControlLabel
                  value="PUBLISHER"
                  control={<Radio color="primary" />}
                  label="Publisher"
                />
              </RadioGroup>
            </FormControl>
            <IconButton onClick={() => deleteUserRole(u.eventUserId)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
