/**
 * **Component**
 *
 * Utility component used to handle case insensitive search.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { ReactElement } from 'react';
import { SearchInput } from 'react-admin';

import { filterCaseInsensitive } from '../../utils/searchInputFilterCaseInsensitive';

export const SearchInputCaseInsensitive = (props: any): ReactElement => (
  <SearchInput
    {...props}
    source={props.source}
    alwaysOn={props.alwaysOn}
    parse={filterCaseInsensitive.parse}
    format={filterCaseInsensitive.format}
  />
);
