/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to edit image descriptions for the
 * image recognition system.
 */

/** ignore this comment */
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  Button,
  CardContentInner,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  TextInput,
  useDataProvider
} from 'react-admin';
import { Form } from 'react-final-form';

import { useFileUpload } from '../../../../hooks/useFileUpload';
import { convertStrToUserRole } from '../../../../utils/permissionHelper';
import { JSONField } from '../../../shared/JSONField';
import { UploadToolbar } from '../../../shared/UploadToolbar';

const useStyles = makeStyles({
  thumbnail: {
    maxWidth: '200px',
    margin: '15px 0'
  }
});

export const EditForm = (props: any) => {
  const { uploadError, fileId: thumbnailFileId, handleFileUpload } = useFileUpload();

  const [imagePath, setImagePath] = useState<string>();
  const classes = useStyles();

  const dataProvider = useDataProvider();
  useEffect(() => {
    console.log('!!props.record', props.record);
    async function getImage() {
      try {
        const result = await dataProvider.getOne('files', { id: props.record.thumbnailFileId });
        const file = JSON.parse(result.data.file);
        setImagePath(file.filepath);
      } catch (error) {
        console.error('error retrieving image', error);
      }
    }

    getImage();
  }, []);

  const onSubmit = (record: any) => {
    if (thumbnailFileId) {
      record.thumbnailFileId = thumbnailFileId;
    }
    props.onSubmit(record);
  };

  const fileParams = [{ key: 'thumbnailFileId', value: thumbnailFileId }];

  const defaultIcon = <ContentSave />;
  const { eventId, record } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={record}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <CardContentInner>
            <TextInput disabled source="eventId" initialValue={eventId} />
          </CardContentInner>{' '}
          <CardContentInner>
            <TextInput source="name" />
          </CardContentInner>{' '}
          <CardContentInner>
            <TextInput source="porscheCode" />
          </CardContentInner>{' '}
          <CardContentInner>
            <SelectInput
              source="action"
              choices={[
                { id: 'trigger', name: 'Trigger' },
                { id: 'tracker', name: 'Tracker' }
              ]}
            />
          </CardContentInner>{' '}
          <CardContentInner>
            <NumberInput label="Scale (%)" source="scale" max="100" min="1" step={1} />
          </CardContentInner>{' '}
          <CardContentInner>
            <SelectInput
              label="Rotation"
              source="rotY"
              choices={[
                { id: 0, name: '0°' },
                { id: 90, name: '90°' },
                { id: 180, name: '180°' },
                { id: 270, name: '270°' }
              ]}
            />
          </CardContentInner>{' '}
          <CardContentInner>
            <SelectInput
              label="Tech part"
              source="techpart"
              choices={[
                { id: 'Hotspots', name: 'Hotspots' },
                { id: 'Drivetrain', name: 'Drivetrain' },
                { id: 'DesignDNA', name: 'Design DNA' },
                { id: 'Charging', name: 'Charging' },
                { id: 'ElectricDrive', name: 'Electric Drive' }
              ]}
            />
          </CardContentInner>
          <CardContentInner>
            {imagePath && <img src={imagePath} className={classes.thumbnail} />}
          </CardContentInner>
          {uploadError && <Alert severity="error">{uploadError}</Alert>}
          <ImageInput
            onChange={handleFileUpload}
            source="editModel"
            label="Thumbnail File Upload"
            accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button
                disabled={submitting || pristine}
                type="submit"
                label="save"
                variant="contained"
                color={submitting ? 'default' : 'primary'}>
                {defaultIcon}
              </Button>
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};
