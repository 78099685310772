/**
 * **Component**
 *
 * Utility component used to display SVG icons
 *
 */

/** ignore this comment */

import React, { FC } from 'react';

interface ISVGIconProps {
  name: string;
  width: number;
}

export const SVGIcon: FC<ISVGIconProps> = ({ name, width }: ISVGIconProps) => {
  const imgSrc = `icons/${name}.svg`;
  return <img src={imgSrc} alt={name} width={width} />;
};
