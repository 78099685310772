/**
 * **Component**
 *
 * Component based on React Admin **Edit** component to manage fun facts
 *
 * Please note that the actual content of the fun facts is handled in the translations. The text
 * here is used as reference only.
 *
 */

/** ignore this comment */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { DateField, Edit, required, SimpleForm, TextInput } from 'react-admin';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
});

export const FunFactEdit = (props: any): JSX.Element => {
  const classes = useStyles();
  return (
    <Edit title="Edit fun fact" {...props} redirect={false}>
      <SimpleForm>
        <DateField locales="de-DE" source="createdDate" formClassName={classes.inlineBlock} />
        <DateField locales="de-DE" source="updatedDate" formClassName={classes.inlineBlock} />
        <TextInput source="title" validate={required()} />
        <TextInput multiline source="funFactText" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
