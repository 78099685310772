/**
 * **Component**
 *
 * Component based on React Admin **List** component to manage Languages
 *
 * In addition to the list of available languages this component offers the user the possibility
 * of downloading and uploading Excel files which are used to enter the translations.
 *
 * Please note that the Excel files are only used as a transmission mechanism. In the backend the content is parsed and
 * stored in the `translation` table.
 *
 * Changes to the languages will only be published to the apps after the publisher manually starts the publishing process
 * clicking on the corresponding button in the event/market editing page.
 *
 * Please note that in the CMS this components are found under the menu point **Translations**
 *
 */

/** ignore this comment */
import * as React from 'react';
import {
  BulkDeleteButton,
  Button,
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  Filter,
  FunctionField,
  useRefresh,
  useNotify
} from 'react-admin';
import Download from '@material-ui/icons/GetApp';
import Upload from '@material-ui/icons/Publish';
import { Fragment } from 'react';

import { gql, useMutation } from '@apollo/client';
import { saveAs } from 'file-saver';
import ExcelJS, { WorksheetProtection } from 'exceljs';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';
import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';

interface TranslationRow {
  name: string;
  english_text: string;
  translation: string;
}

const DOWNLOAD_EXCEL = gql`
  mutation dwlexc($language: String!) {
    requestDownloadExcel(input: { language: $language }) {
      status
      data {
        translation
        name
        english_text
      }
    }
  }
`;

const UPLOAD_EXCEL = gql`
  mutation uplexc($language: String!, $dataRows: [TranslationRowInput]) {
    requestUploadExcel(input: { language: $language, dataRows: $dataRows }) {
      status
    }
  }
`;

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

const LanguageFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="name" alwaysOn={true} />
  </Filter>
);

export const LanguageList = (props: any) => {
  const [downloadExcelMutation] = useMutation(DOWNLOAD_EXCEL);
  const [uploadExcelMutation] = useMutation(UPLOAD_EXCEL);
  const notify = useNotify();
  const refresh = useRefresh();

  const downloadExcel = async (event: any) => {
    try {
      const lockStr = 'kjghrtergr47!1d53e';
      const downloadDoc = await downloadExcelMutation({
        variables: { language: event.id }
      });

      const rows = downloadDoc?.data?.requestDownloadExcel?.data;
      const workbook = new ExcelJS.Workbook();
      const nbRows: number = rows.length;

      const sheet = workbook.addWorksheet(event.code);

      const optionsProtections: Partial<WorksheetProtection> = {
        formatColumns: true,
        insertColumns: false,
        insertRows: false,
        deleteColumns: false,
        deleteRows: false
      };
      sheet.properties.defaultColWidth = 80;
      sheet.getRow(1).font = { bold: true, size: 12 };
      sheet.getColumn(1).font = { bold: true, size: 12 };

      sheet.getColumn(1).header = 'Phrase ID';
      sheet.getColumn(1).width = 50;

      sheet.getColumn(2).header = 'English Reference text';
      sheet.getColumn(2).width = 50;
      sheet.getColumn(2).alignment = { wrapText: true };

      sheet.getColumn(3).header = 'Translation-' + event.name;
      sheet.getColumn(3).width = 50;
      sheet.getColumn(3).alignment = { wrapText: true };
      sheet.addConditionalFormatting({
        ref: 'C1:C' + (nbRows + 1),
        rules: [
          {
            type: 'expression',
            priority: 1,
            formulae: ['=ISBLANK(C1)'],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00000000' },
                bgColor: { argb: 'FFFF0000' }
              }
            }
          }
        ]
      });
      rows?.map((x: TranslationRow) => sheet.addRow([x.name, x.english_text, x.translation]));

      await sheet.protect(lockStr, optionsProtections);
      sheet.getColumn(3).protection = { locked: false };

      const buf = await workbook.xlsx.writeBuffer();

      const blob = new Blob([buf], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const newDate = new Date();
      saveAs(
        blob,
        newDate.toLocaleDateString() + '_porsche_cms_translation-' + event.name + '.xlsx'
      );
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  function readFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (res: any) => {
        resolve(res.target.result);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(file);
    });
  }

  const uploadExcel = async (event: any, languageId: string, name: string) => {
    try {
      const selectedFile = event.target.files[0];
      const workbook = new ExcelJS.Workbook();

      const buffer = await readFile(selectedFile);
      await workbook.xlsx.load(buffer as Buffer);

      const resultExcel: TranslationRow[] = [];

      workbook.eachSheet((sheet) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex > 1) {
            const transRow: TranslationRow = {
              name: row.getCell(1).value?.toString() ?? '',
              english_text: row.getCell(2).value?.toString() ?? '',
              translation: row.getCell(3).value?.toString() ?? ''
            };
            resultExcel.push(transRow);
          }
        });
      });
      await uploadExcelMutation({
        variables: { language: languageId, dataRows: resultExcel }
      });
      refresh();
      notify('Document uploaded for ' + name);
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  return (
    <List
      {...props}
      filters={<LanguageFilter />}
      title="Translations"
      exporter={false}
      bulkActionButtons={
        checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
          <ListBulkActionButtons {...props} />
        ) : (
          false
        )
      }
      sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="code" />
        <TextField source="internationalCode" label="International Code" />
        <TextField source="missingTranslationCount" label="Missing Translations" sortable={false} />
        <FunctionField
          label="Download"
          render={(record: any) => (
            <Button color="primary" onClick={() => downloadExcel(record)}>
              <Download />
            </Button>
          )}
        />
        <FunctionField
          label="Upload"
          render={(record: any) => (
            <div>
              <label htmlFor={record?.id}>
                <Upload />
              </label>
              <input
                hidden
                type="file"
                id={record?.id}
                onChange={(e) => uploadExcel(e, record?.id, record?.name)}
              />
            </div>
          )}
        />
        <DateField
          locales="de-DE"
          source="lastTranslationUpdatedDate"
          label="Last Translation Edited"
          showTime
        />
        <EditButton label="Edit Basic Information" />
      </Datagrid>
    </List>
  );
};
