/**
 * **Component**
 *
 * Component based on React Admin **Create** component manage existing phrase id's.
 *
 * Phrase ID's are used in the **Translations**. This area provides the possibility
 * of managing the keys as well as an example text. The actual content shown on the
 * app will be taken from the translation files.
 *
 */

/** ignore this comment */
import * as React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';

export const PhraseEdit = (props: any) => {
  return (
    <Edit title="Edit Phrase" {...props}>
      <SimpleForm redirect={false}>
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="englishText" validate={required()} multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
};
