/**
 * **Routes**
 *
 * This is module contains some additional routes includding
 * - support
 * - legal
 * - editor-event : This is the route that the editor uses to edit the custom event or markte parameters
 * - editor-event-derivative: This is the route the the editor uses to edit the derivative selection and configuration
 * - activate: this route is use to activate the user
 * - requestpwreset: this route is use to request password reset
 * - pwreset: this route is use to reset the password
 *
 */

/** ignore this comment */

import * as React from 'react';
import { Route } from 'react-router-dom';

import { Activate } from './components/auth/Activate';
import { PwReset } from './components/auth/PwReset';
import { PwResetRequest } from './components/auth/PwResetRequest';
import { EditorEventDerivative } from './components/events/editor_events/EditorEventDerivative';
import EditorEvent from './components/events/EditorEdit';
import { SupportComponent } from './components/supportLegal/support';
import { LegalComponent } from './components/supportLegal/legal';

const routes = [
  <Route path="/support" component={SupportComponent} key="support" />,
  <Route path="/legal" component={LegalComponent} key="legal" />,
  <Route path="/editor-event" component={EditorEvent} key="editor-event" />,
  <Route
    path="/editor-event-derivative"
    component={EditorEventDerivative}
    key="editor-event-derivative"
  />,
  <Route
    exact
    path="/activate/:activationToken"
    component={Activate}
    key="user-activate"
    noLayout
  />,
  <Route exact path="/requestpwreset" component={PwResetRequest} key="requestpwreset" noLayout />,
  <Route exact path="/pwreset/:resetToken" component={PwReset} key="pwreset" noLayout />
];

export { routes };
