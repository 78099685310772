/**
 * **Component**
 *
 * Component based on React Admin **List** component to manage  colors
 *
 */

/** ignore this comment */
import * as React from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField
} from 'react-admin';
import { Fragment } from 'react';

import { UserRole } from '../../models/user-permissions.model';
import { checkRolePermission } from '../../utils/permissionHelper';
import { SearchInputCaseInsensitive } from '../shared/SearchInputCaseInsensitive';
import { ColorCircle } from './ColorCircle';

const ColorsFilter = (props: any) => (
  <Filter {...props}>
    <SearchInputCaseInsensitive source="q" alwaysOn={true} />
  </Filter>
);

const ListBulkActionButtons = (props: any) => (
  <Fragment>
    {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) && (
      <BulkDeleteButton {...props} undoable={false} />
    )}
  </Fragment>
);

export const ColorList = (props: any) => {
  return (
    <List
      {...props}
      title="Ext. Colors"
      filters={<ColorsFilter />}
      exporter={false}
      bulkActionButtons={
        checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
          <ListBulkActionButtons {...props} />
        ) : (
          false
        )
      }>
      <Datagrid>
        <TextField source="name" />
        <TextField source="optionCode" />
        <FunctionField
          label="Color"
          render={(record: any) => <ColorCircle colorString={record?.hexadecimalValue} size={30} />}
        />
        {checkRolePermission(props.permissions, [UserRole.SUPER_ADMIN]) ? (
          <EditButton label="Edit" />
        ) : null}
      </Datagrid>
    </List>
  );
};
